var currentStampImage, currentStampedImage, currentStampInfoObj = null, count = 0, currentStampIcon, blotterImage = null, blottedImage = null, flatPdf = false, highlightField = true, currentFreeText = null, signatureImage = null, signatureX = 0, signatureY = 0, currentScrollTop = 0, isIpad = false, isFlat = false;
var fontType = 'Times New Roman', fontStyle = 'Regular', fontSize = 10, fontColor = 'rgb(0,0,0)';
var availableParamNames = ['Case Number', 'Transaction Id', 'Date',
	'User Name', 'Aditional Info', 'FILEDATE'];
var isBoldSelected = false;
var isItalicSelected = false;
var kCssUnits = 1.0;
var stampIcon;
var evnt;
// var transactionId , countyId;

var stampsInfoarray = new Array();// Keeps currently available stamps
var reqUrl;
;
/* ===============Arrays Used in Application -Start========================= */
// Arrays for Before Stamping
var stampParamArray = new Array();// Keeps parameters for currently available
// stamps

// Arrays for After Stamping
var stampsOnCanvas = new Array();// Keeps Track of Stamped Images
var blotterOnCanvas = new Array();// Keeps Track of Blotters on page
var freeTextOnCanvas = new Array();// Keeps track of free Text Fields on pdf
var signatureOnCanvas = new Array();// Keeps track of signatures added on pdf
/* ===============Arrays Used in Application -End========================= */
var finalPreviewString = null;

var pageWidth = 610; // Width of each page at 100%
//var leftToolbar = 126;// left Toolbar width //Commented By CPNVP 08/13/2015 PR 485
var leftToolbar = 105 - 25;// left Toolbar width //CPNVP 08/13/2015 PR 485
var stampScale = 70 / 100;// Initial Stamp Scaling 70%
var scale = 1.1;// Initial Scale
var marginLeft = 0;// Vertical Toolbar Width + middle margin
//var pageMarginTop = 15; //Commented By CPNVP 08/13/2015 PR 485
var pageMarginTop = 10; //CPNVP 08/13/2015 PR 485
// var marginTop = 41+ pageMarginTop+ currentScrollTop; //Horizontal toolbar
// Height + margin Top//Commented by CPNVP PR 617 - signature was not coming on
// right position
var marginTop = 33 + pageMarginTop + currentScrollTop; // Horizontal toolbar
// Height + margin
// Top//Added by CPNVP
// PR 617 - signature
// was not coming on
// right position

var stampWidth = 0;// Stamp Width for Moving Stamp
var stampHeight = 0;// Stamp Height for Moving Stamp
var blotterHeight = 0;
var blotterWidth = 0;
var separator = '/'; // Path separator
var percentageRatio = 3;// For StampScaling
var footerTop = 0;// footer Top Value
var footerHeight = 33;// footer Height
// var viewerHeight = (792+footerHeight)*kCssUnits;//Height of page at 100% /* Commented By CPNVP 08/13/2015 PR 485*/
var viewerHeight = (792 - footerHeight) * kCssUnits;//Height of page at 100% /* CPNVP 08/13/2015 PR 485*/

var isDialogShowing = false;
var signatureHeight = 78;// signature Height at 100% Zoom & 100%
// stampScale(60/0.7/1.1)
var signatureWidth = 130;// signature Width at 100% Zoom & 100%
// stampScale(100/0.7/1.1)
var viewerUtility = this;
//CPNVP 08/13/2015 PR 485 - START
var contextPath = 'http://localhost:4200/assets';
var popUpDivLeft = 0;
var popUpDivTop = 0;
var headerHeight = 40;// header Height
var footerBottom = 15;// footer bottom
//CPNVP 08/13/2015 PR 485 - END
var updateEndpoint;
var previewEndpoint;
var repairEndpoint;
var replaceRepairedEndpoint;
var mainRequestId;
var linkToken;

var restrictBlotterOnStamp = false; //PR 994
// ================================= INITIALIZE STAMP TOOLBAR -START
function flatPdfClick() {

	if (!flatPdf) {
		flatPdf = true;
		$('#flatPdf').children('img').attr({
			'src': contextPath + '/webiinkit/images/flat-pdf.png'
		});
		document.getElementById('flatpdfdemo').innerHTML = 'Flat Pdf:';
	} else {
		flatPdf = false;
		$('#flatPdf').children('img').attr({
			'src': contextPath + '/webiinkit/images/pdf-icon.png'
		});
		document.getElementById('flatpdfdemo').innerHTML = 'Template Pdf:';
	}
}

$('#next').on('click', function (e) {
	$('#scrollDiv').scrollTop(0);
	if ($(this).attr('class') == 'disabledLink') {
		e.preventDefault();
	}
});

$('#previous').on('click', function (e) {
	$('#scrollDiv').scrollTop(0);
	if ($(this).attr('class') == 'disabledLink') {
		e.preventDefault();
	}
});
function heightLightOnClick() {
	if (!highlightField) {
		if (isFlat) {
			return false;
		}
		highlightField = true;
		setTimeout(function () {
			$('#heightLightOn').addClass('heightLightOn');
			$('.heilighted-bg a').addClass('selected');
			$('.inputHint').css({
				'background-color': '#E0E9FB'
			});
			$('.inputControl[type=\'checkbox\']').css({
				'background-color': '#E0E9FB'
			});
			$('.inputControl').css({
				'display': 'block'
			});
			$('.inputControl').css({
				'background-color': '#E0E9FB'
			}); // CPNVP 07/27/2015 PR 485
		}, 80);
	} else {
		if (isFlat) {
			return false;
		}
		highlightField = false;
		$('#heightLightOn').removeClass('heightLightOn');
		$('.heilighted-bg a').removeClass('selected');
		$('.inputHint').css({
			'background-color': 'transparent'
		});

		$('.inputControl[type=\'checkbox\']').css({
			'background-color': 'transparent'
		});
		// $('.inputControl').css({'display':'none'}); //Commented By CPNVP
		// 07/27/2015 PR 485
		$('.inputControl').css({
			'background-color': 'white'
		}); // CPNVP 07/27/2015 PR 485
	}
}
// setParameters();
function refreshStampToolbar() {
	// CPNVP 03/24/2015 PR 617 - START
	$("#stampImages").empty();
	$("#orgImages").empty();
	// CPNVP 03/24/2015 PR 617 - END
	if (stampsInfoarray.length == 0) {
		$("#stampToolBarUL").empty();
		$('.verticalToolbarContainer').css({
			'display': 'none'
		});

		$('.verticalDefaultToolbarContainer').css({
			'display': 'block'
		});

		var liArrow = $('<li>').attr({
			'class': 'left eraser-btn'
		}).css({
			'margin-bottom': '0px',
			'margin-right': '3px',
			'margin-top': '15px', //PR 957
			'float': 'left',
		});
		a = $('<a>').attr({
			'id': 'pointer',
			'title': 'No Stamp',
			'onclick': 'javascript : removeOtherHandlers();'
		}).css({
			'displaly': 'block'
		});
		stampIcon = $('<img>').attr({
			'src': contextPath + '/webiinkit/images/cursor.png',
			//Commented for PR 957 - START
			// 'width': 40,
			// 'height': 26,
			//Commented for PR 957 - END
			'alt': 'pointer'
		}).css({
			'display': 'block',
			//PR 957 - START
			'width': '55px',
			'height': '45px',
			//PR 957 - END
		});
		liArrow.prepend(a);
		a.prepend(stampIcon);
		$("#stampToolBarUL").prepend(liArrow);

		var lidelete = $('<li>').attr({
			'class': 'left eraser-btn'
		}).css({
			'margin-bottom': '0px',
			'margin-right': '3px',
			'margin-top': '15px', //PR 957
			'float': 'left'
		});
		a = $('<a>').attr({
			'id': 'deleteStamp',
			'title': 'Remove',
			'onclick': 'javascript : setDelete(event);'
		}).css({
			'displaly': 'block'
		});

		stampIcon = $('<img>').attr({
			'src': contextPath + '/webiinkit/images/close-button.png',
			//Commented for PR 957 - START
			// 'width': '40',
			// 'height': '26'
			//Commented for PR 957 - END
		}).css({
			'display': 'block',
			//PR 957 - START
			'width': '55px',
			'height': '45px',
			//PR 957 - END
		});
		lidelete.prepend(a);
		a.prepend(stampIcon);
		$("#stampToolBarUL").prepend(lidelete);

		var lieraser = $('<li>').attr({
			'class': 'left eraser-btn'
		}).css({
			'margin-bottom': '0px',
			'margin-right': '3px',
			'float': 'left'
		});
		a = $('<a>').attr({
			'title': 'BLOTTER',
			'onclick': 'javascript : setBlotter(event);',
			'class': 'blotter-icon'
		}).css({
			'display': 'block'
		});

		stampIcon = $('<img>').attr({
			'id': 'blotter-icon',
			'src': contextPath + '/webiinkit/images/eraser-icon.png',
			//Commented for PR 957 - START
			// 'width': '40',
			// 'height': '26'
			//Commented for PR 957 - END
		}).css({
			//PR 957 - START
			'width': '55px',
			'height': '45px',
			//PR 957 - END
		});
		lieraser.prepend(a);
		a.prepend(stampIcon);
		$("#stampToolBarUL").prepend(lieraser);

		var lifreetext = $('<li>').attr({
			'class': 'left eraser-btn'
		}).css({
			'margin-bottom': '0px',
			'margin-right': '3px',
			'float': 'left',
			'text-align': 'center'
		});
		a = $('<a>').attr({
			'title': 'ADD FREE TEXT',
			'onclick': 'javascript : freeTextClick();',
			'class': 'blotter-icon'
		}).css({
			'display': 'block'
		});

		stampIcon = $('<img>').attr({
			'id': 'free-text-icon-li',
			'src': contextPath + '/webiinkit/images/tt.png',
		}).css({
			//PR 957 - START
			'width': '40px',
			'height': '35px',
			//PR 957 - END
		});
		lifreetext.prepend(a);
		a.prepend(stampIcon);
		$("#stampToolBarUL").prepend(lifreetext);

		// $('.verticalDefaultToolbarContainer').prepend(stampTitle);
		$('.verticalDefaultToolbarContainer').append('<br><hr><br>');
		$('.verticalDefaultToolbarContainer').prepend($("#stampToolBarUL"));
	}

	else {
		var stampImg = null;
		$("#stampToolBarUL").empty();

		for (var i = 0; i < stampsInfoarray.length; i++) {
			var li = $('<li>').attr({
				'id': i
			}).css({
				'display': 'block'
			});

			var a = $('<a>').css({
				'displaly': 'block'
			});
			li.prepend(a);
			stampIcon = $('<img>')
				.attr(
					{
						// 'id':('IInkIt_'+stampsInfoarray[i].imageId+'_icon').replace('%20','_').replace('
						// ','_'), //Commented By CPNVP 07/27/2015 PR
						// 485
						'id': ('IInkIt_' + stampsInfoarray[i].imageId + '_icon')
							.replace('%20', '_').replace(' ', '_')
							.replace(/ /g, '_'), // CPNVP
						// 07/27/2015 PR
						// 485
						// 'src':stampsInfoarray[i].imageData.replace('%20','_').replace('
						// ','_'),//Commented By CPNVP 07/27/2015 PR 485
						'src': stampsInfoarray[i].imageData.replace(
							'%20', '_').replace(' ', '_').replace(
								/ /g, '_'), // CPNVP 07/27/2015 PR 485
						'onclick': 'javascript : setCurrentStamp(event);',
						'width': 40,
						'height': 26
					}).css({
						'display': 'block',
						'background-color': 'white'
					});
			a.prepend(stampIcon);
			$("#stampToolBarUL").prepend(li);
			stampImg = $('<img>')
				.attr(
					{
						// 'id':('IInkIt_'+stampsInfoarray[i].imageId+'_img').replace('%20','_').replace('
						// ','_'), //Commented By CPNVP 07/27/2015 PR
						// 485
						'id': ('IInkIt_' + stampsInfoarray[i].imageId + '_img')
							.replace('%20', '_').replace(' ', '_')
							.replace(/ /g, '_'), // CPNVP
						// 07/27/2015 PR
						// 485
						'class': 'stmapImage',
						// 'src':stampsInfoarray[i].imageData.replace('%20','_').replace('
						// ','_') //Commented By CPNVP 07/27/2015 PR 485
						'src': stampsInfoarray[i].imageData.replace(
							'%20', '_').replace(' ', '_').replace(
								/ /g, '_')
						// CPNVP 07/27/2015 PR 485

					}).css({
						'display': 'none'
					});
			$('#stampImages').append(stampImg);
			stampImg = $('<img>')
				.attr(
					{
						// 'id':('IInkIt_'+stampsInfoarray[i].imageId+'_img_1').replace('%20','_').replace('
						// ','_'), //Commented By CPNVP 07/27/2015 PR
						// 485
						'id': ('IInkIt_' + stampsInfoarray[i].imageId + '_img_1')
							.replace('%20', '_').replace(' ', '_')
							.replace(/ /g, '_'), // CPNVP
						// 07/27/2015 PR
						// 485
						'class': 'stmapImage',
						// 'src':stampsInfoarray[i].imageData.replace('%20','_').replace('
						// ','_') //Commented By CPNVP 07/27/2015 PR 485
						'src': stampsInfoarray[i].imageData.replace(
							'%20', '_').replace(' ', '_').replace(
								/ /g, '_')
						// CPNVP 07/27/2015 PR 485
					}).css({
						'display': 'none',
						'position': 'absolute',
						'top': '10000',
						'left': '10000'
					});
			$('#orgImages').prepend(stampImg);

			if (i == 0) {


			}// IF -END
		}
		$("#stampToolBarUL").prepend($('<li style="float: left; width: 100%">').append('<div style="border-top:2px solid #698aba;padding: 4px 5px;margin-top:10px;">Stamps:</div>'));

		var liArrow = $('<li>').attr({
			'class': 'left eraser-btn'
		}).css({
			'margin-bottom': '0px',
			'margin-right': '3px',
			'margin-top': '15px', //PR 957
			'float': 'left',
		});
		a = $('<a>').attr({
			'id': 'pointer',
			'title': 'POINTER',
			'onclick': 'javascript : removeOtherHandlers();'
		}).css({
			'displaly': 'block'
		});
		stampIcon = $('<img>').attr({
			'src': contextPath + '/webiinkit/images/cursor.png',
			//Commented for PR 957 - START
			// 'width': 40,
			// 'height': 26,
			//Commented for PR 957 - END
			'alt': 'pointer'
		}).css({
			'display': 'block',
			//PR 957 - START
			'width': '55px',
			'height': '45px',
			//PR 957 - END
		});
		liArrow.prepend(a);
		a.prepend(stampIcon);
		$("#stampToolBarUL").prepend(liArrow);
		var lidelete = $('<li>').attr({
			'class': 'left eraser-btn'
		}).css({
			'margin-bottom': '0px',
			'margin-right': '3px',
			'margin-top': '15px', //PR 957
			'float': 'left'
		});
		a = $('<a>').attr({
			'id': 'deleteStamp',
			'title': 'DELETE',
			'onclick': 'javascript : setDelete(event);'
		}).css({
			'displaly': 'block'
		});

		stampIcon = $('<img>').attr({
			'src': contextPath + '/webiinkit/images/close-button.png',
			//Commented for PR 957 - START 
			// 'width': '40',
			// 'height': '26'
			//Commented for PR 957 - END
		}).css({
			'display': 'block',
			//PR 957 - START
			'width': '55px',
			'height': '45px',
			//PR 957 - END
		});
		lidelete.prepend(a);
		a.prepend(stampIcon);
		$("#stampToolBarUL").prepend(lidelete);

		var lieraser = $('<li>').attr({
			'class': 'left eraser-btn'
		}).css({
			'margin-bottom': '0px',
			'margin-right': '3px',
			'float': 'left'
		});
		a = $('<a>').attr({
			'title': 'ERASER',
			'onclick': 'javascript : setBlotter(event);',
			'class': 'blotter-icon'
		}).css({
			'displaly': 'block'
		});

		stampIcon = $('<img>').attr({
			'id': 'blotter-icon',
			'src': contextPath + '/webiinkit/images/eraser-icon.png',
			//Commented for PR 957 - START
			// 'width': '40',
			// 'height': '26'
			//Commented for PR 957 - END
		}).css({
			//PR 957 - START
			'width': '55px',
			'height': '45px',
			//PR 957 - END
		});
		lieraser.prepend(a);
		a.prepend(stampIcon);
		$("#stampToolBarUL").prepend(lieraser);

		var lifreetext = $('<li>').attr({
			'class': 'left eraser-btn'
		}).css({
			'margin-bottom': '0px',
			'margin-right': '3px',
			'float': 'left',
			'text-align': 'center'
		});
		a = $('<a>').attr({
			'title': 'ADD FREE TEXT',
			'onclick': 'javascript : freeTextClick();',
			'class': 'blotter-icon'
		}).css({
			'display': 'block'
		});

		stampIcon = $('<img>').attr({
			'id': 'free-text-icon-li',
			'src': contextPath + '/webiinkit/images/tt.png',
		}).css({
			//PR 957 - START
			'width': '40px',
			'height': '35px',
			//PR 957 - END
		});
		lifreetext.prepend(a);
		a.prepend(stampIcon);
		$("#stampToolBarUL").prepend(lifreetext);

		$("#stampToolBarUL").prepend($('<li style="float: left; width: 100%">').append('<div style="border-top:2px solid #698aba;padding: 4px 5px; margin-top:-25px">Tools:</div>'));
	}
}
/* CPNVP 12/29/2014 PR 617 - END */
//CPNVP 08/13/2015 PR 485 - START
function setPopUpDivParams() {

	popUpDivLeft = $('#popUpDivContainer').offset().left;
	popUpDivTop = $('#popUpDivContainer').offset().top;

	$("#viewerContainer").width($('.toolbar').width() - popUpDivLeft - 40 - 16);

}
//CPNVP 08/13/2015 PR 485 - END

function addStampToolbar() {
	//CPNVP 08/13/2015 PR 485 - START
	//var sidebarHeight= ($(window).height() - $('#popUpDivContainer').offset().top - footerHeight - headerHeight - footerBottom - 19);

	var margin = $('#sidebarContent').css("margin-top");
	margin = parseInt(margin);
	var sidebarHeight;
	if (margin > 0) {
		sidebarHeight = ($(window).height() - $('#popUpDivContainer').offset().top - footerHeight - headerHeight - footerBottom - 19 - 40 - 38);
	} else {
		sidebarHeight = ($(window).height() - $('#popUpDivContainer').offset().top - footerHeight - headerHeight - footerBottom - 19 - 38);
	}
	
	$('#signatureLogo').css({
		'margin-top': sidebarHeight + margin + 3,
		width: '105px',
		bottom: '6px',
		top: 'auto'

	});


	$('#sidebarContent').css({
		// 'height': sidebarHeight

	});

	//CPNVP 08/13/2015 PR 485 - END
	if (stampsInfoarray.length == 0) {
		$('.verticalToolbarContainer').css({
			'display': 'none'
		});

		$('.verticalDefaultToolbarContainer').css({
			'display': 'block'
		});

		var stampTitle = $('<div>').attr({
			'class': 'stamps-ttl'
		}).css({
			'position': 'absolute'
		});
		var ul = $('<ul>').attr({
			'id': 'stampToolBarUL'
		}).css(
			{
				'width': '106px',
				//'margin-top' : '2px',//Commented by CPNVP 11/23/2016 PR 705
				'margin-top': '65px',//CPNVP 11/23/2016 PR 705
				'z-index': '100',
				'height': ($(window).height() - marginTop - footerHeight
					+ 4 - 200)
					+ 'px'
			}).attr('class', 'stamp-ul');

		var liArrow = $('<li>').attr({
			'class': 'left eraser-btn'
		}).css({
			'margin-bottom': '0px',
			'margin-right': '3px',
			'margin-top': '15px', //PR 957
			'float': 'left',
		});
		a = $('<a>').attr({
			'id': 'pointer',
			'title': 'No Stamp',
			'onclick': 'javascript : removeOtherHandlers();'
		}).css({
			'displaly': 'block'
		});
		stampIcon = $('<img>').attr({
			'src': contextPath + '/webiinkit/images/cursor.png',
			//Commented for PR 957 - START
			// 'width': 40,
			// 'height': 26,
			//Commented for PR 957 - END
			'alt': 'pointer'
		}).css({
			'display': 'block',
			//PR 957 - START
			'width': '55px',
			'height': '45px',
			//PR 957 - END
		});
		liArrow.prepend(a);
		a.prepend(stampIcon);
		ul.prepend(liArrow);

		var lidelete = $('<li>').attr({
			'class': 'left eraser-btn'
		}).css({
			'margin-bottom': '0px',
			'margin-right': '3px',
			'margin-top': '15px', //PR 957
			'float': 'left'
		});
		a = $('<a>').attr({
			'id': 'deleteStamp',
			'title': 'Remove',
			'onclick': 'javascript : setDelete(event);'
		}).css({
			'displaly': 'block'
		});

		stampIcon = $('<img>').attr({
			'src': contextPath + '/webiinkit/images/close-button.png',
			//Commented for PR 957 - START
			// 'width': '40',
			// 'height': '26'
			//Commented for PR 957 - END
		}).css({
			'display': 'block',
			//PR 957 - START
			'width': '55px',
			'height': '45px',
			//PR 957 - END
		});
		lidelete.prepend(a);
		a.prepend(stampIcon);
		ul.prepend(lidelete);

		var lieraser = $('<li>').attr({
			'class': 'left eraser-btn'
		}).css({
			'margin-bottom': '0px',
			'margin-right': '3px',
			'float': 'left'
		});
		a = $('<a>').attr({
			'title': 'BLOTTER',
			'onclick': 'javascript : setBlotter(event);',
			'class': 'blotter-icon'
		}).css({
			'displaly': 'block'
		});
		stampIcon = $('<img>').attr({
			'id': 'blotter-icon',
			'src': contextPath + '/webiinkit/images/eraser-icon.png',
			//Commented for PR 957 - START
			// 'width': '40',
			// 'height': '26'
			//Commented for PR 957 - END
		}).css({
			//PR 957 - START
			'width': '55px',
			'height': '45px',
			//PR 957 - END
		});
		lieraser.prepend(a);
		a.prepend(stampIcon);
		ul.prepend(lieraser);

		var lifreetext = $('<li>').attr({
			'class': 'left eraser-btn'
		}).css({
			'margin-bottom': '0px',
			'margin-right': '3px',
			'float': 'left',
			'text-align': 'center'
		});
		a = $('<a>').attr({
			'title': 'ADD FREE TEXT',
			//'onclick': 'javascript : ();', //Commented for PR 994
			'onclick': 'javascript : freeTextClick();', //PR 994
			'class': 'blotter-icon'
		}).css({
			'display': 'block'
		});

		stampIcon = $('<img>').attr({
			'id': 'free-text-icon-li',
			'src': contextPath + '/webiinkit/images/tt.png',
		}).css({
			//PR 957 - START
			'width': '40px',
			'height': '35px',
			//PR 957 - END
		});
		lifreetext.prepend(a);
		a.prepend(stampIcon);
		ul.prepend(lifreetext);

		$('.verticalDefaultToolbarContainer').prepend(stampTitle);
		$('.verticalDefaultToolbarContainer').append(ul);
		ul.prepend($('<li style="float: left; width: 100%">').append('<div style="border-top:2px solid #698aba;padding: 4px 5px;; margin-top:-23px">Tools:</div>'));
	}

	else {
		$('.verticalToolbarContainer').css({
			'display': 'block'
		});
		var stampImg = null;
		var stampTitle = $('<div>').attr({
			'class': 'stamps-ttl'
		}).css({
			'position': 'absolute'
		});
		var ul = $('<ul>').attr({
			'id': 'stampToolBarUL'
		}).css(
			{
				'width': '106px',
				//'margin-top' : '2px',//Commented by CPNVP 11/23/2016 PR 705
				'margin-top': '65px',//CPNVP 11/23/2016 PR 705
				'z-index': '100',
				'height': ($(window).height() - marginTop - footerHeight
					+ 4 - 200)
					+ 'px'
			}).attr('class', 'stamp-ul');
		// CPNVP 07/27/2015 PR 485 - START
		if (stampsInfoarray[0].stampSeqNo != null
			&& stampsInfoarray[0].stampSeqNo != undefined) {
			stampsInfoarray.sort(function (a, b) {
				return (a.stampSeqNo) - (b.stampSeqNo);
			});
		}
		// CPNVP 07/27/2015 PR 485 - END


		if (stampsInfoarray && stampsInfoarray.length > 0) {

			ul.append($('<li style="float: left; width: 100%">').append('<div style="border-top:2px solid #698aba;padding: 4px 5px;margin-top:10px;">Stamps:</div>'));
		}
		for (var i = 0; i < stampsInfoarray.length; i++) {

			// Commented By CPNVP 07/27/2015 PR 485 - END
			$('.verticalToolbarContainer').prepend(stampTitle);

			var li = $('<li>').attr({
				'id': i
			}).css({
				'display': 'block'
			});
			// ul.prepend(li); //Commented By CPNVP 07/27/2015 PR 485
			ul.append(li); // CPNVP 07/27/2015 PR 485
			var a = $('<a>').css({
				'display': 'block'
			});

			// CPNVP 07/27/2015 PR 485 - START
			if (stampsInfoarray[i].stampToolTip != null
				&& stampsInfoarray[i].stampToolTip != undefined
				&& stampsInfoarray[i].stampToolTip != '') {
				a.attr({
					'title': stampsInfoarray[i].stampToolTip
				});
			}
			// CPNVP 07/27/2015 PR 485 - END
			li.prepend(a);
			// Commented By CPNVP 07/27/2015 PR 485 - START

			// Commented By CPNVP 07/27/2015 PR 485 - END
			// CPNVP 07/27/2015 PR 485 - START

			stampIcon = $('<img>').attr(
				{
					'id': ('IInkIt_' + stampsInfoarray[i].imageId + '_icon')
						.replace('%20', '_').replace(' ', '_')
						.replace(/ /g, '_'),
					'src': stampsInfoarray[i].imageIconData
						.replace('%20', '_').replace(' ', '_')
						.replace(/ /g, '_'),
					'onclick': 'javascript : setCurrentStamp(event);',
					'width': 40,
					'height': 26
				}).css({
					'display': 'block',
					'background-color': 'white'
				});
			a.prepend(stampIcon);
			// CPNVP 07/27/2015 PR 485 - END
			//$('.verticalToolbarContainer').prepend(ul); // Commented By CPNVP
			// 07/27/2015 PR 485

			$('.verticalToolbarContainer').append(ul); // CPNVP 07/27/2015 PR

			// 485

			stampImg = $('<img>')
				.attr(
					{
						// 'id':('IInkIt_'+stampsInfoarray[i].imageId+'_img').replace('%20','_'),
						// //Commented By CPNVP 07/27/2015 PR 485
						'id': ('IInkIt_' + stampsInfoarray[i].imageId + '_img')
							.replace('%20', '_').replace(/ /g, '_'), // CPNVP
						// 07/27/2015
						// PR
						// 485
						'class': 'stmapImage',
						// 'src':stampsInfoarray[i].imageData.replace('%20','_').replace('
						// ','_') //Commented By CPNVP 07/27/2015 PR 485
						'src': stampsInfoarray[i].imageData.replace(
							'%20', '_').replace(' ', '_').replace(
								/ /g, '_')
						// CPNVP 07/27/2015 PR 485

					}).css({
						'display': 'none'
					});
			$('#stampImages').append(stampImg);
			stampImg = $('<img>')
				.attr(
					{
						// 'id':('IInkIt_'+stampsInfoarray[i].imageId+'_img_1').replace('%20','_').replace(/
						// /g, '_'),//Commented By CPNVP 07/27/2015 PR
						// 485
						'id': ('IInkIt_' + stampsInfoarray[i].imageId + '_img_1')
							.replace('%20', '_').replace(/ /g, '_'), // CPNVP
						// 07/27/2015
						// PR
						// 485
						'class': 'stmapImage',
						// 'src':stampsInfoarray[i].imageData.replace('%20','_').replace('
						// ','_').replace(/ /g, '_')//Commented By CPNVP
						// 07/27/2015 PR 485
						'src': stampsInfoarray[i].imageData.replace(
							'%20', '_').replace(' ', '_').replace(
								/ /g, '_')
						// CPNVP 07/27/2015 PR 485
					}).css({
						'display': 'none',
						'position': 'absolute',
						'top': '10000',
						'left': '10000'
					});
			$('#orgImages').prepend(stampImg);
		}// FOR LOOP -END
		// CPNVP 07/27/2015 PR 485 - START
		if (stampsInfoarray.length > 0) {

			var liArrow = $('<li>').attr({
				'class': 'left eraser-btn'
			}).css({
				'margin-bottom': '0px',
				'margin-right': '3px',
				'margin-top': '15px', //PR 957
				'float': 'left',
			});
			a = $('<a>').attr({
				'id': 'pointer',
				'title': 'No Stamp',
				'onclick': 'javascript : removeOtherHandlers();'
			}).css({
				'displaly': 'block'
			});
			stampIcon = $('<img>').attr({
				'src': contextPath + '/webiinkit/images/cursor.png',
				//Commented for PR 957 - START
				// 'width': 40,
				// 'height': 26,
				//Commented for PR 957 - END
				'alt': 'pointer'
			}).css({
				'display': 'block',
				//PR 957 - START
				'width': '55px',
				'height': '45px',
				//PR 957 - END
			});
			liArrow.prepend(a);
			a.prepend(stampIcon);
			ul.prepend(liArrow);

			var lidelete = $('<li>').attr({
				'class': 'left eraser-btn'
			}).css({
				'margin-bottom': '0px',
				'margin-right': '3px',
				'margin-top': '15px', //PR 957
				'float': 'left'
			});
			a = $('<a>').attr({
				'id': 'deleteStamp',
				'title': 'Remove',
				'onclick': 'javascript : setDelete(event);'
			}).css({
				'displaly': 'block'
			});

			stampIcon = $('<img>').attr({
				'src': contextPath + '/webiinkit/images/close-button.png',
				//Commented for PR 957 - START
				// 'width': '40',
				// 'height': '26'
				//Commented for PR 957 - END
			}).css({
				'display': 'block',
				//PR 957 - START
				'width': '55px',
				'height': '45px',
				//PR 957 - END
			});
			lidelete.prepend(a);
			a.prepend(stampIcon);
			ul.prepend(lidelete);

			var lieraser = $('<li>').attr({
				'class': 'left eraser-btn'
			}).css({
				'margin-bottom': '0px',
				'margin-right': '3px',
				'float': 'left'
			});
			a = $('<a>').attr({
				'title': 'BLOTTER',
				'onclick': 'javascript : setBlotter(event);',
				'class': 'blotter-icon'
			}).css({
				'displaly': 'block'
			});

			stampIcon = $('<img>').attr({
				'id': 'blotter-icon',
				'src': contextPath + '/webiinkit/images/eraser-icon.png',
				//Commented for PR 957 - START
				// 'width': '40',
				// 'height': '26'
				//Commented for PR 957 - END
			}).css({
				//PR 957 - START
				'width': '55px',
				'height': '45px',
				//PR 957 - END
			});
			lieraser.prepend(a);
			a.prepend(stampIcon);
			ul.prepend(lieraser);

			var lifreetext = $('<li>').attr({
				'class': 'left eraser-btn'
			}).css({
				'margin-bottom': '0px',
				'margin-right': '3px',
				'float': 'left',
				'text-align': 'center'
			});
			a = $('<a>').attr({
				'title': 'ADD FREE TEXT',
				'onclick': 'javascript : freeTextClick();',
				'class': 'blotter-icon'
			}).css({
				'display': 'block'
			});

			stampIcon = $('<img>').attr({
				'id': 'free-text-icon-li',
				'src': contextPath + '/webiinkit/images/tt.png',
			}).css({
				//PR 957 - START
				'width': '40px',
				'height': '35px',
				//PR 957 - END
			});
			lifreetext.prepend(a);
			a.prepend(stampIcon);
			ul.prepend(lifreetext);

			ul.prepend($('<li style="float: left; width: 100%">').append('<div style="border-top:2px solid #698aba;padding: 4px 5px;; margin-top:-31px">Tools:</div>'));

		}
		// CPNVP 07/27/2015 PR 485 - END
	}
	//CPNVP 08/13/2015 PR 485 - START
	webViewerInitialized();

	$('#popUpDivContainer').css({
		'display': 'block'

	});
	setPopUpDivParams();
	//CPNVP 08/13/2015 PR 485 - END
}

// image & icon name would be like "IInkIt_imgName_(icon/img)"
// get image id from icon id
// =========================================== INITIALIZE STAMP TOOLBAR -END

// =============================== SET CURRENT SCALE & RELATED PAGE HEIGHT
// -START
function setScaleInViewer(value) {
	try {
		if (value == undefined || value == null || isNaN(value)) {

			var container = PDFView.container;
			var currentPage = PDFView.pages[PDFView.page - 1];

			var pageWidthScale = (container.clientWidth - leftToolbar - 8)
				/ currentPage.width * currentPage.scale / kCssUnits;


			var pageHeightScale = (container.clientHeight + 5) / currentPage.height
				* currentPage.scale / kCssUnits;
			switch (value) {
				case 'page-actual':
					scale = 1;
					break;
				case 'page-width':
					scale = pageWidthScale;
					break;
				case 'page-height':
					scale = pageHeightScale;
					break;
				case 'page-fit':
					/*scale = ((footerTop - marginTop) * currentPage.scale)
					/ currentPage.height;*/ // Commented By CPNVP 08/13/2015 PR 485
					scale = currentPage.height - $(window).height(); //CPNVP 08/13/2015 PR 485

					break;
				case 'auto':
					scale = Math.min(1.0, pageWidthScale);
					break;
			}
			selectScaleOption(value);
			value = scale;

		}
		scaleOnZoom(value);
		scale = value;
	} catch (error) {
		error.message;
	}
}
// For Page Scaling
function scaleOnZoom(newScale) {
	marginTop = popUpDivTop;// CPNVP 08/13/2015 PR 485
	newScale = newScale || scale;

	// Commented By CPNVP 08/13/2015 PR 485 - START
	/*var scrollHeight = Number($(window).height() - marginTop - footerHeight
			+ 10);
	var heightValue = (viewerHeight * newScale) + footerHeight + pageMarginTop;
	$("#viewerContainer").height(heightValue - (footerHeight - 2));
	// Scrollbar Handling -START
	$('#scrollDiv').height($(window).height() - marginTop - footerHeight + 10);
	$("#innerScrollDiv").height(heightValue - footerHeight);*/
	// Commented By CPNVP 08/13/2015 PR 485 - END
	//$("#viewerContainer").height($(window).height() - marginTop - footerHeight ); working fine 
	//CPNVP 08/13/2015 PR 485 - START
	var numPages = PDFView.pages.length;
	for (var i = 1; i <= numPages; i++) {
		$("#pageContainerMain" + i).height(0);
	}

	var pageNo = PDFView.page;
	if (pageNo > 1) {
		$("#pageContainerMain" + (pageNo - 1)).height(0);

	}
	$("#pageContainerMain" + (pageNo + 1)).height(0);
	//CPNVP 08/13/2015 PR 485 - START
	var horiScroll = 0;
	if (document.getElementById('pageContainerMain' + pageNo) != null && document.getElementById('pageContainerMain' + pageNo) != undefined) {
		var pageDiv = document.getElementById('pageContainerMain' + pageNo);
		if (pageDiv.scrollWidth > pageDiv.clientWidth) {
			horiScroll = 4;
		}
	}
	//CPNVP 08/13/2015 PR 485 - END
	if (newScale > 0) {

		//var heightValue = ($(window).height() * newScale) - marginTop - footerHeight -50;
		//$("#pageContainerMain"+pageNo).height(heightValue);	
		$("#pageContainerMain" + pageNo).height(($(window).height() - marginTop - footerHeight - 35 - 14) - horiScroll);

	}
	else {
		$("#pageContainerMain" + pageNo).height(($(window).height() - marginTop - footerHeight - 35 - 21) - horiScroll);
	}
	//CPNVP 08/13/2015 PR 485 - END

}

// =============================== SET CURRENT SCALE & RELATED PAGE HEIGHT -END

// =====================STAMP HANDLER - START

function setCurrentStampImage(evnt) {
	if (document.getElementById('mainParamContainer').style.display == 'block') {
		alert('Please close already opened dialog.');
	}
	else {
		$('.verticalToolbarContainer img').each(
			function (index, element) {
				if (evnt.target.id == this.id) {
					var iconId = evnt.target.id;
					var imageId = '#'
						+ iconId.substring(0, iconId.lastIndexOf('_'))
						+ '_img';
					console.log('imageId :::' + imageId);
					currentStampImage = $(imageId);
					/*
					 * currentStampImage.css({ 'display':'block' });
					 */// Commented by CPNVP 12/29/2014 PR 617 - when
					// signtuer was getting picked up one bigger
					// signature was getting shown and hide on the
					// screen
					return false;
				}
			});

		$('.verticalDefaultToolbarContainer img').each(
			function (index, element) {
				if (evnt.target.id == this.id) {
					var iconId = evnt.target.id;
					var imageId = '#'
						+ iconId.substring(0, iconId.lastIndexOf('_'))
						+ '_img';
					currentStampImage = $(imageId);
					currentStampImage.css({
						'display': 'block'
					});
					return false;
				}
			});
	}
}

function addStamp2Array(stampName, pageNo, x, y, isAdded, src, height, width,
	stampParameters, stampImgName) {
	var stamp = new Stamp(stampName, pageNo, x, y, scale, isAdded, src, height,
		width, stampParameters, stampScale, stampImgName);
	
	console.log("addStamp2Array ===> x = " + x + " - y = " + y)
	if (stampsOnCanvas == null || stampsOnCanvas == undefined
		|| stampsOnCanvas.length == 0) {
		count = count + 1;
		stamp.id = 'stampDiv_'+count;
		stampsOnCanvas[0] = stamp;
	} else {
		if (stampsOnCanvas.indexOf(stamp) == -1) {
			// add new stamp in the array
			count = count + 1;
      		stamp.id = 'stampDiv_'+count;
			stampsOnCanvas.push(stamp);
		} else {
			// add repeated stamp in the array(can append "_x" if repeated the
			// same id )
			count = count + 1;
			stamp.id = 'stampDiv_'+count;
			stamp.stampName = stamp.stampName + '_' + count;
			stampsOnCanvas.push(stamp);
		}
	}
}

function setCurrentStamp(event) {
	// scale = 1.1;
	removeOtherHandlers();
	var evnt = event || window.event;
	setCurrentStampImage(evnt);
	currentStampIcon = $('#' + evnt.target.id);
	currentStampIcon.addClass('selectedStamp');
	console.log(':currentStampImage.attrid: ' + currentStampImage);
	// currentStampIcon.parent('a').addClass('selected');//Commented by CPNVP
	// 12/29/2014 PR 617 - when signtuer was getting picked up signature tool
	// was getting distorted
	if (!isIpad) {
		currentStampImage.bind('mousemove.stampEvent', moveStamp);
		$('#viewer').bind('mousemove.stampEvent', moveStamp);
	} else {
		currentStampImage.on('touchstart.stampEvent', moveStamp);
		$('#viewer').on('touchstart.stampEvent', moveStamp);

		currentStampImage.on('touchmove.stampEvent', moveStamp);
		$('#viewer').on('touchmove.stampEvent', moveStamp);
	}

	var orgImage = currentStampImage.attr('id') + '_1';// orgImages Containes
	// name = 'imageName'_1;
	orgImage = $('#' + orgImage);

	orgImage.css({
		'opacity': '0',
		'display': 'block',
		'left': '10000px',
		'top': '10000px'
	});
	setTimeout(function () {
		stampWidth = Math.round((orgImage.css('width').replace('px', ''))
			/ percentageRatio);
		stampHeight = Math.round((orgImage.css('height').replace('px', ''))
			/ percentageRatio);

		currentStampImage.css({
			'position': 'absolute',
			'left': evnt.pageX,
			'top': evnt.pageY,
			'opacity': '0.95',
			'z-index': '300',
			'width': (stampWidth * stampScale * scale) + 'px',
			'height': (stampHeight * stampScale * scale) + 'px',
			'border': 'rgba(0,0,255,1) solid 1px',
			'background-color': 'transparent'
		});
		orgImage.css({
			'display': 'none'
		});
	}, 0);

	$(document).on('click.stampEvent', '.stmapImage', function (e) {
		setStampPosition(e);
	}); //CPNVP 08/13/2015 PR 485
	//$('.stmapImage').on('click.stampEvent', setStampPosition); //Commented By CPNVP 08/13/2015 PR 485
	if (isIpad) {
		currentStampImage.on('touchend.stampEvent', setStampPosition);
		$('#viewer').on('touchend.stampEvent', setStampPosition);
	}
}

function setStampPosition(evnt) {
	event = evnt || window.event;
	if (isIpad) {
		event.preventDefault();
	}
	if (!isIpad) {
		$(document).off('click.stampEvent', '.stmapImage'); /*CPNVP 08/13/2015 PR 485*/
		currentStampImage.unbind('mousemove.stampEvent');
		$('#viewer').unbind('mousemove.stampEvent');

	} else {
		currentStampImage.off('touchstart.stampEvent');
		$('#viewer').off('touchstart.stampEvent');

		currentStampImage.off('touchmove.stampEvent');
		$('#viewer').off('touchmove.stampEvent');

		currentStampImage.off('touchend.stampEvent');
		$('#viewer').off('touchend.stampEvent');
	}
	//$('.stmapImage').off('click.stampEvent', setStampPosition); //Commented By CPNVP 08/13/2015 PR 485

	showParameter(evnt); //CPNVP 08/13/2015 PR 485
}

function showParameter(event) {
	var pnt = getTouchEndCordinatePoints(event);
	if (!checkViewArea(pnt.x, pnt.y)) {
		removeOtherHandlers();
		return false;
	}
	var stampId = currentStampImage.attr('id');
	var stampName = stampId.substring(7, stampId.lastIndexOf('_'));
	for (var j = 0; j < stampsInfoarray.length; j++) {
		if (parseInt(stampsInfoarray[j].imageId)) { // CPNVP 07/27/2015 PR 485
			if (stampsInfoarray[j].imageId == stampName) {
				currentStampInfoObj = stampsInfoarray[j];
			}
			// CPNVP 07/27/2015 PR 485 - START
		} else {
			if ((stampsInfoarray[j].imageId).replace('%20', '_').replace(' ',
				'_').replace(/ /g, '_') == stampName) {
				currentStampInfoObj = stampsInfoarray[j];
			}
		}
		// CPNVP 07/27/2015 PR 485 - END
	}

	if (currentStampInfoObj == null) {
		stampOnPdf(pnt.x, pnt.y);
		removeOtherHandlers();
		return;
	}
	if (currentStampInfoObj.stampParameters == null
		|| currentStampInfoObj.stampParameters == undefined
		|| currentStampInfoObj.stampParameters.length == 0) {
		stampOnPdf(pnt.x, pnt.y);
		removeOtherHandlers();
		return;
	}
	/*
	 * stampOnPdf(pnt.x, pnt.y); removeOtherHandlers();
	 */// Commetned By CPNVP 07/27/2015 PR 485
	// CPNVP 07/27/2015 PR 485 - START
	var paramJson = currentStampInfoObj.stampParameters;
	var arrParam = [];
	// var parsed = JSON.parse(paramJson);
	for (var x in paramJson) {
		arrParam.push(paramJson[x]);
	}
	var isShowToUser = false;
	for (var i = 0; i < paramJson.length; i++) {
		var paramObj = paramJson[i];
		if (paramObj.showToUser != null
			&& paramObj.showToUser.toLowerCase() == 'y') {
			isShowToUser = true;
			break;
		}
	}
	if (isShowToUser == true) {
		generateParameters4Dialog(paramJson);
		showDialog();
	}
	if (isShowToUser == false) {
		stampOnPdf(pnt.x, pnt.y);
		removeOtherHandlers();
		return;
	}


	// CPNVP 07/27/2015 PR 485 - END
}

function generateParameters4Dialog(paramArray) {
	var mainContainer = $('#mainParamContainer');
	// CPNVP 07/27/2015 PR 485 - START
	var stampParamErrDiv = $('<div>').attr({
		'id': 'stampParamErrDiv',
		'style': 'display:none'
	});

	var stampParamUl = $('<ul>').addClass('messageDisplayFormat').attr({
		'id': 'stampParamUl'
	});

	var stampParamLi = $('<li>').addClass('fatalMessage').attr({
		'id': 'stampParamLi'
	});

	stampParamUl.append(stampParamLi);
	stampParamErrDiv.append(stampParamUl);
	// CPNVP 07/27/2015 PR 485 - END
	for (var i = 0; i < paramArray.length; i++) {
		var paramObj = paramArray[i];
		var paramContainer = $('<div>').addClass('param');
		var required = $('<span>').html('* ').css({
			'color': 'rgb(255,0,0)'
		});

		if (paramObj.isRequired == null || paramObj.isRequired == undefined) {
			continue;
		}
		// CPNVP 07/27/2015 PR 485 - START
		if (paramObj.showToUser != null
			&& paramObj.showToUser.toLowerCase() == 'n') {
			continue;
		}
		// CPNVP 07/27/2015 PR 485 - END
		if (paramObj.isRequired.toLowerCase() == 'n') {
			required = ' ';
		}


		/*var paramName = $('<span>').addClass('paramName').attr('id',
		'inputName' + i).html(getParameterName(paramObj.paramName))
		.append(required);*/ //Commented By CPNVP 11/19/2015 PR 661
		//CPNVP 11/19/2015 PR 661 - START
		var paramLableText = '';

		if (paramObj.isLabelRequired != null && paramObj.isLabelRequired == "Y" && paramObj.paramLabel != null && paramObj.paramLabel != '') {
			paramLableText = paramObj.paramLabel;
		}
		else {
			paramLableText = getParameterName(paramObj.paramName);
		}
		var paramName = $('<span>').addClass('paramName').attr('id',
			'inputName' + i).html(paramLableText)
			.append(required);
		//CPNVP 11/19/2015 PR 661 - END

		paramName.html(paramName.html() + ':');

		if (paramObj.paramValue == null || paramObj.paramValue == undefined) {
			paramObj.paramValue = '';
		}

		//CPNVP 12/02/2015 PR 661 - START
		if (paramLableText != null && paramLableText != ' ' && paramLableText != undefined && paramLableText == 'FILEDATE') {
			if (document.getElementById('fileDateStamp').value != null && document.getElementById('fileDateStamp').value.length != 0 && document.getElementById('fileDateStamp').value != undefined) {
				paramObj.paramValue = document.getElementById('fileDateStamp').value;
			}
		}
		//CPNVP 12/02/2015 PR 661 - END

		var paramValue = $('<input>').addClass('paramValue').attr({
			'type': 'text',
			'id': 'input' + i,
			'value': paramObj.paramValue,
			'spellcheck': 'true'
		});
		if (paramObj.isReadOnly.toLowerCase() == 'y') {
			paramValue.attr({
				'disabled': 'disabled'
			});
			paramValue.css('color', '#000000');
		} else {
			/*
			 * paramValue.attr({ 'value' :'' });
			 */// Commented By CPNVP 07/27/2015 PR 485 - Stamp
			// parameter display issue
		}
		var labelmmddyyyy = "";
		// if((paramObj.paramDataType.toUpperCase() == 'JAVA.UTIL.DATE' ||
		// paramObj.paramDataType.toUpperCase() == 'JAVA.SQL.DATE' ||
		// paramObj.paramDataType.toUpperCase() == 'DATE')){ //Commented By
		// CPNVP 07/27/2015 PR 485
		if ((paramObj.paramDataType.toUpperCase() == 'JAVA.UTIL.DATE'
			|| paramObj.paramDataType.toUpperCase() == 'JAVA.SQL.DATE'
			|| paramObj.paramDataType.toUpperCase() == 'DATE' || paramObj.paramDataType
				.toUpperCase() == 'JAVA.LANG.DATE')) { // CPNVP 07/27/2015 PR
			// 485
			// var today = new Date(); //Commented By CPNVP 07/27/2015 PR 485
			// paramValue.attr('value',(today.getMonth()+1)+'/'+today.getDate()+'/'+today.getFullYear());
			// //Commented By CPNVP 07/27/2015 PR 485
			paramValue.attr('value', paramObj.paramValue); // CPNVP 07/27/2015
			// PR 485
			labelmmddyyyy = '(MM/DD/YYYY)'; // CPNVP 07/27/2015 PR 485
			// Commented By CPNVP 07/27/2015 PR 485 - START
			/*
			 * paramValue.on('focus',function(e){ if($(this).val().trim() ==
			 * 'MM/DD/YYYY'){ this.value = ''; } $(this).css('color','#000000');
			 * }); paramValue.on('blur',function(e){ if($(this).val().trim() ==
			 * ''){ this.value = 'MM/DD/YYYY'; $(this).css('color','#707070');
			 * }else{ dateBlurHandler(e); } });
			 */
			// Commented By CPNVP 07/27/2015 PR 485 - END
		}
		// Commented By CPNVP 07/27/2015 PR 485 - START
		/*
		 * if(paramObj.paramName.trim().toLowerCase() == 'case number' ||
		 * paramObj.paramName.trim().toLowerCase() == 'casenumber'){
		 * paramValue.on('blur',caseNumberBlurHandler); }
		 */
		// Commented By CPNVP 07/27/2015 PR 485 - END
		paramContainer.append(paramName);
		paramContainer.append(paramValue);
		// CPNVP 07/27/2015 PR 485 - START
		if (labelmmddyyyy != '') {
			paramContainer.append(labelmmddyyyy);
		}
		// CPNVP 07/27/2015 PR 485 - END
		mainContainer.append(paramContainer);
	}

	mainContainer.append(stampParamErrDiv); // CPNVP 07/27/2015 PR 485

	var buttonDiv = $('<div>').attr({
		'class': 'paramButton',
	});
	var save = $('<input>').addClass('param-button').attr({
		'id': 'save',
		'type': 'button',
		// 'value':'Save', //Commented By CPNVP 07/27/2015 PR 485
		'value': 'Ok',// CPNVP 07/27/2015 PR 485
		'onclick': 'javascript : saveParam(event);'
	});
	buttonDiv.append(save);

	var cancel = $('<input>').addClass('param-button').attr({
		//'id' : 'cancel',//Commented By CPNVP 08/13/2015 PR 485
		'id': 'cancelDialog', //CPNVP 08/13/2015 PR 485
		'type': 'button',
		// 'value':'Cancel',//Commented By CPNVP 07/27/2015 PR 485
		'value': 'Exit',// CPNVP 07/27/2015 PR 485
		'onclick': 'javascript : cancel(event);'
	});

	buttonDiv.append(cancel);
	mainContainer.append(buttonDiv);
	$('#mainParamContainer').css({
		'margin-top': '-' + mainContainer.height() / 2 + 'px',
		'margin-left': '-' + mainContainer.width() / 2 + 'px'
	});
	mainContainer.children('h1').prepend('Stamp Parameters');

}

/*
 * function getParameterName(paramName){ paramName =
 * paramName.toUpperCase().trim();
 */// Commented By CPNVP
// 07/27/2015 PR 485
// CPNVP 07/27/2015 PR 485 - START
function getParameterName(inParamName) {
	paramName = inParamName.toUpperCase().trim();
	// CPNVP 07/27/2015 PR 485 - END
	switch (paramName) {
		case 'CASENUMBER':
			paramName = availableParamNames[0];
			break;
		case 'CASE NUMBER':
			paramName = availableParamNames[0];
			break;
		case 'TRANSACTIONID':
			paramName = availableParamNames[1];
			break;
		case 'TRANSACTION ID':
			paramName = availableParamNames[1];
			break;
		case 'SYSTEMDATE':
			paramName = availableParamNames[2];
			break;
		case 'SYSTEM DATE':
			paramName = availableParamNames[2];
			break;
		case 'USERNAME':
			paramName = availableParamNames[3];
			break;
		case 'USER NAME':
			paramName = availableParamNames[3];
			break;
		case 'ADDITIONAL INFO':
			paramName = availableParamNames[4];
			break;
		case 'ADDITIONALINFO':
			paramName = availableParamNames[4];
			break;
		//Commented By CPNVP 11/19/2015 PR 661 - START	
		/*	case 'SYSTEMDATETIME':
				paramName = availableParamNames[5];
				break;
			case 'SYSTEM DATETIME':
				paramName = availableParamNames[5];
				break;*/
		//Commented By CPNVP 11/19/2015 PR 661 - END
		// CPNVP 07/27/2015 PR 485 - START
		default:
			paramName = inParamName;
		// CPNVP 07/27/2015 PR 485 - END
	}
	return paramName;
}
// CPNVP 07/27/2015 PR 485 - START
function showStampErrMsg(msg) {
	var errorLi = $('#stampParamLi');
	errorLi.html(msg);
	$('#stampParamErrDiv').css('display', 'block');
}
// CPNVP 07/27/2015 PR 485 - END

function saveParam(event) {

	$('#stampParamErrDiv').css('display', 'none'); // CPNVP 07/27/2015 PR 485

	var clientX = currentStampImage.css('left').replace('px', ''), clientY = currentStampImage
		.css('top').replace('px', '');
	var paramArray = currentStampInfoObj.stampParameters;
	for (var i = 0; i < paramArray.length; i++) {
		//CPNVP 11/19/2015 PR 661 - START
		if (paramArray[i].paramLabel == 'TRANS #') {
			if (paramArray[i].paramValue != ' ' && paramArray[i].paramValue != null && paramArray[i].paramValue != undefined) {
				document.getElementById('transactionID').value = paramArray[i].paramValue;
			}
		}
		//CPNVP 11/19/2015 PR 661 - END
		// CPNVP 07/27/2015 PR 485 - START
		if (paramArray[i].showToUser != null
			&& paramArray[i].showToUser.toLowerCase() == 'n') {
			continue;
		}
		// CPNVP 07/27/2015 PR 485 - END
		if (paramArray[i].isReadOnly.toUpperCase() == "Y") {

		} else if (paramArray[i].isRequired.toUpperCase() == 'Y'
			&& ($('#input' + i).val() == null
				|| $('#input' + i).val() == undefined || $('#input' + i)
					.val().trim() == '')) {
			// alert(getParameterName(paramArray[i].paramName)+' is required.');
			// //Commented By CPNVP 07/27/2015 PR 485
			showStampErrMsg('Please enter value for ' + getParameterName(paramArray[i].paramName)
				+ '.'); // CPNVP 07/27/2015 PR 485
			return;
			// }else if((paramArray[i].paramDataType.toUpperCase() ==
			// 'JAVA.UTIL.DATE' || paramArray[i].paramDataType.toUpperCase() ==
			// 'JAVA.SQL.DATE' || paramArray[i].paramDataType.toUpperCase() ==
			// 'DATE')){ //Commented By CPNVP 07/27/2015 PR 485
		} else if ((paramArray[i].paramDataType.toUpperCase() == 'JAVA.UTIL.DATE'
			|| paramArray[i].paramDataType.toUpperCase() == 'JAVA.SQL.DATE'
			|| paramArray[i].paramDataType.toUpperCase() == 'DATE' || paramArray[i].paramDataType
				.toUpperCase() == 'JAVA.LANG.DATE')) {// CPNVP 07/27/2015 PR
			// 485
			if (paramArray[i].isRequired.toUpperCase() == 'Y') {
				// if(!(validateDate($('#input'+i).val(),$('#input'+i)))){
				// //Commented By CPNVP 07/27/2015 PR 485
				if (!(validateDate($('#input' + i).val(), $('#input' + i),
					paramArray[i]))) { // CPNVP 07/27/2015 PR 485
					return;
				}
			} else {
				if ($('#input' + i).val() == ''
					|| $('#input' + i).val() == undefined
					|| $('#input' + i).val() == null) {
					// }else
					// if(!(validateDate($('#input'+i).val(),$('#input'+i)))){
					// //Commented By CPNVP 07/27/2015 PR 485
				} else if (!(validateDate($('#input' + i).val(),
					$('#input' + i), paramArray[i]))) { // CPNVP 07/27/2015
					// PR 485
					return;
				}
			}
		}

		if ($('#input' + i).val() == null || $('#input' + i).val() == undefined)
			$('#input' + i).val('');
		paramArray[i].paramValue = $('#input' + i).val();
		//CPNVP 11/19/2015 PR 661 - START
		if (paramArray[i].paramLabel == 'FILEDATE') {
			if (paramArray[i].paramValue != ' ' && paramArray[i].paramValue != null && paramArray[i].paramValue != undefined) {
				//document.getElementById('fileDate').value = paramArray[i].paramValue; //Commented By CPNVP 12/02/2015 PR 661
				document.getElementById('fileDateStamp').value = paramArray[i].paramValue;  //CPNVP 12/02/2015 PR 661
			}
		}

		//CPNVP 11/19/2015 PR 661 - END


		// CPNVP 07/27/2015 PR 485 - START
		if (paramArray[i].paramType == 'User Define') {
			var dataType = paramArray[i].paramDataType;
			if (dataType == 'Integer') {
				if ((parseInt(paramArray[i].paramValue))
					&& !isNaN(paramArray[i].paramValue)) {
				} else {
					// alert('Value Must be Integer for'+'
					// '+paramArray[i].paramName.toUpperCase()); //Commented By
					// CPNVP 07/27/2015 PR 485
					showStampErrMsg('Value Must be Integer for' + ' '
						+ getParameterName(paramArray[i].paramName) + '.'); // CPNVP
					// 07/27/2015
					// PR
					// 485
					return;
				}
			}
		}
		// CPNVP 07/27/2015 PR 485 - END
	}

	$('#mainParamContainer').html(
		'<h1 id="paramTitle"><span id= "closeDialogBox" style="color:white" title="Close" class="toolbarButton paramButtonImage"></span></h1>');/* children('div').detach(); */ /*CPNVP 08/13/2015 PR 485*/
	$('#mainParamContainer').css('display', 'none');
	$('#param-overlay').css('display', 'none');
	stampOnPdf(clientX, clientY);
	isDialogShowing = false;
	removeOtherHandlers();
	cancel(event);
}
// Commented By CPNVP 07/27/2015 PR 485 - START
/*
 * function stampOnPdf(clientX, clientY){ console.log('stampOnPdf called' +
 * clientY + ' : '+ marginTop); if(currentStampImage != null ||
 * currentStampImage != undefined){ if(!checkViewArea(clientX, clientY)){
 * //console.log('removeOtherHandlers called'); removeOtherHandlers(); return
 * false; } var divLeft = clientX - marginLeft; var divTop = clientY -
 * marginTop; for(var key in currentStampInfoObj){ console.log("key..." +key);
 * console.log("value..." +currentStampInfoObj[key]); } var src =
 * currentStampImage.attr('src'); var stampImgName =
 * currentStampInfoObj['imageId']; var divElement =
 * document.createElement('div'); console.log("ghjghjgjhg4..src."+divTop);
 * addStamp2TextLayer(divElement, divTop, divLeft, src, PDFView.page,
 * currentStampImage.height(), currentStampImage.width());
 * console.log("5..."+stampImgName);
 * if(document.getElementById("enteredPageNo").value != 0)
 * addStamp2Array(stampImgName, document.getElementById("enteredPageNo").value,	
 * divLeft, divTop, true,src, currentStampImage.height(),
 * currentStampImage.width(),currentStampInfoObj.stampParameters,stampImgName);
 * else addStamp2Array(stampImgName, PDFView.page, divLeft, divTop, true,src,
 * currentStampImage.height(),
 * currentStampImage.width(),currentStampInfoObj.stampParameters,stampImgName);
 * removeOtherHandlers(); }else{ $('.stmapImage').css('display','none');
 * removeOtherHandlers(); } }
 */
// Commented By CPNVP 07/27/2015 PR 485 - END
// CPNVP 07/27/2015 PR 485 - START
function stampOnPdf(clientX, clientY) {
	
	if (currentStampImage != null || currentStampImage != undefined) {
		if (!checkViewArea(clientX, clientY)) {
			removeOtherHandlers();
			return false;
		}
		//var divLeft = clientX - marginLeft;//Commented By CPNVP 08/13/2015 PR 485
		var divLeft = clientX - marginLeft - 35; //CPNVP 08/13/2015 PR 485
		//var divTop = clientY - marginTop; //Commented By CPNVP 08/13/2015 PR 485
		//CPNVP 08/13/2015 PR 485 - START

		var scrollValue = 0;
		var pageNo = PDFView.page;

		scrollValue = $("#pageContainerMain" + (pageNo)).scrollTop();
		//var popUpDivTop = $('#popUpDivContainer').offset().top;
		var divTop = (parseInt(scrollValue) + parseInt(clientY)) - popUpDivTop - 39;
		//CPNVP 08/13/2015 PR 485 - END
		// alert(currentStampInfoObj.stampName);
		// divTop = divTop - 1;
		// if (currentStampInfoObj != null
		// 	&& currentStampInfoObj.stampName != null) {
		// 	if (currentStampInfoObj.stampName == 'TEXT') {
		// 		divTop = divTop + 17;
		// 	} else {
		// 		divTop = divTop - 2;
		// 	}
		// }
		var src = currentStampImage.attr('src');
		// addParam2Array(currentStampInfoObj);
		var divElement = document.createElement('div');
		if (currentStampInfoObj != null
			&& currentStampInfoObj.stampParameters != null
			&& currentStampInfoObj.stampParameters != undefined
			&& currentStampInfoObj.stampParameters.length > 0) {
			addParam2Stamp(divElement, currentStampInfoObj);
		}
		
		if (currentStampInfoObj != null) {
			if (document.getElementById("enteredPageNo").value != 0) {
				addStamp2Array(currentStampInfoObj.stampName, document
					.getElementById("enteredPageNo").value, divLeft,
					divTop, true, src, currentStampImage.height(),
					currentStampImage.width(),
					currentStampInfoObj.stampParameters);
			} else {
				addStamp2Array(currentStampInfoObj.stampName, PDFView.page,
					divLeft, divTop, true, src, currentStampImage.height(),
					currentStampImage.width(),
					currentStampInfoObj.stampParameters);
			}
		}

		addStamp2TextLayer(divElement, divTop, divLeft, src, PDFView.page,
			currentStampImage.height(), currentStampImage.width());

		removeOtherHandlers();

		$("#stampDiv_" + count).draggable(); 
		$("#stampDivImg_" + count).resizable();	
		resizableListener();

	} else {
		$('.stmapImage').css('display', 'none');
		removeOtherHandlers();
	}
}
// CPNVP 07/27/2015 PR 485 - END

function addStamp2TextLayer(divElement, divTop, divLeft, src, pageNo, height,
	width) {
	var imgElement = document.createElement('img');
	divElement
		.setAttribute(
			'style',
			'top : '
			+ (divTop)
			+ 'px;left : '
			+ (divLeft)
			+"px;border: solid 1px blue;  cursor: move;float: left;background-color : transparent; z-index : 200;");// transparent
	divElement.setAttribute('class', 'stampDiv');
	divElement.setAttribute('id', 'stampDiv_'+count);
	imgElement.setAttribute('src', src);
	imgElement.setAttribute('draggable', 'true');
	//PR 994 - START
	imgElement.setAttribute('class', 'stampDivImg'); 
	imgElement.setAttribute('id', 'stampDivImg_'+count); 
	//PR 994 - END
	imgElement.setAttribute('style', 'height : ' + (height) + 'px; width : '
		+ (width) + "px;opacity : 0.85;background-color : transparent;border: solid 1px blue;  cursor: move;float: left;");// top
	// :
	// '+(divTop-5)+'px;left
	// :
	// '+(divLeft-5)+'px;
	
	divElement.setAttribute('draggable', 'true');
	divElement.appendChild(imgElement);
	
	/* $('#pageContainer' + (Number(pageNo))).children('.textLayer').append(
		divElement); */ /* Commented for PR 994 */
	
	$('#pageContainer' + (Number(pageNo))).children('.textLayer').children('.pdfpage').append(divElement); //PR 994
}

function addParam2Stamp(divElement, currentStampInfoObj) {
	// Commented By CPNVP 07/27/2015 PR 485 - START
	/*
	 * var sortCount = 1; //CPNVP 07/27/2015 PR 485 for(var i=0; i<
	 * currentStampInfoObj.stampParameters.length ; i++){ for(var j=0; j<
	 * currentStampInfoObj.stampParameters.length ; j++){
	 * if(currentStampInfoObj.stampParameters[j].paramSeqNo == sortCount){
	 * if(currentStampInfoObj.stampParameters[j].showToUser.toLowerCase() ==
	 * 'y'){ currentStampInfoObj.stampParameters[j].stampScale = stampScale;
	 * addParamWithStyle(divElement, currentStampInfoObj.stampParameters[j]);
	 * break; } } } sortCount++; }
	 */
	// Commented By CPNVP 07/27/2015 PR 485 - END
	// CPNVP 07/27/2015 PR 485 - START
	var stampParamArray = currentStampInfoObj.stampParameters;
	stampParamArray.sort(function (a, b) {
		return (a.paramSeqNo) - (b.paramSeqNo);
	});
	for (var i = 0; i < stampParamArray.length; i++) {
		// if(stampParamArray[i].showToUser.toLowerCase() == 'y'){

		stampParamArray[i].stampScale = stampScale;
		// addParamWithStyle(divElement, stampParamArray[i]);//Commented By
		// CPNVP 07/27/2015 PR 485 - To resolve the issue of zoom in and zoom
		// out of mulitple size stamps
		addParamWithStyle(divElement, stampParamArray[i], 0);// CPNVP
		// 07/27/2015 PR
		// 485
		// }
	}
	// CPNVP 07/27/2015 PR 485 - END

}
// function addParamWithStyle(divElement, stampParamObj){ //Commented By CPNVP
// 07/27/2015 PR 485
function addParamWithStyle(divElement, stampParamObj, stampScale) { // CPNVP
	// 07/27/2015
	// PR 485
	
	var paramSpan = $('<span>').addClass('paramSpan');
	if (stampParamObj.isLabelRequired.toLowerCase() == 'y') {
		paramSpan.html(stampParamObj.paramLabel + ' : '
			+ stampParamObj.paramValue);
	} else {
		paramSpan.html(stampParamObj.paramValue);
	}
	var paramTop = Math.floor(stampParamObj.paramY / percentageRatio);
	var paramLeft = Math.floor(stampParamObj.paramX / percentageRatio);
	// CPNVP 07/27/2015 PR 485 - START
	if (stampScale == 0) {
		stampScale = stampParamObj.stampScale;
	}
	// CPNVP 07/27/2015 PR 485 - END


	var new_offset = { top: 0, left: 0 }; //PR 994

	// Commented By CPNVP 07/27/2015 PR 485 - START
	/*
	 * paramSpan.css({ 'position' : 'absolute', 'font-weight' :
	 * stampParamObj.paramFontStyle, 'font-size' :
	 * Math.round(((stampParamObj.paramFontSize)
	 * *scale*(stampParamObj.stampScale)))+'px', 'font-family' :
	 * stampParamObj.paramFontType, //'top' :
	 * Math.round((paramTop*scale*stampParamObj.stampScale) )+'px', //Commented
	 * By CPNVP 07/27/2015 PR 485 'top' :
	 * Math.round((paramTop*scale*stampParamObj.stampScale) -10 )+'px', //CPNVP
	 * 07/27/2015 PR 485 'left' :
	 * Math.round((paramLeft*scale*stampParamObj.stampScale) )+'px', 'overflow' :
	 * 'hidden', 'white-space' : 'nowrap', 'text-overflow' : 'ellipsis',
	 * 'z-index' : 'inherit', 'color' : 'rgba(0,0,0,1)'//'color' :
	 * 'rgba('+stampParamObj.paramColor+',1)' }); if((stampParamObj.paramWidth !=
	 * null || stampParamObj.paramWidth != undefined || stampParamObj.paramWidth !=
	 * 0) && (stampParamObj.paramHeight != null || stampParamObj.paramHeight !=
	 * undefined || stampParamObj.paramHeight != 0)){ paramSpan.css({ 'width' :
	 * stampParamObj.paramWidth*scale*stampParamObj.stampScale, 'height' :
	 * stampParamObj.paramHeight*scale*stampParamObj.stampScale }); }
	 */
	// Commented By CPNVP 07/27/2015 PR 485 - END
	// CPNVP 07/27/2015 PR 485 - START
	var color = 'rgb(' + stampParamObj.paramColor + ')';
	paramSpan.css({
		'position': 'absolute',
		'font-weight': stampParamObj.paramFontStyle,
		'font-size': Math
			.round(((stampParamObj.paramFontSize) * scale * (stampScale)))
			+ 'px',
		'font-family': stampParamObj.paramFontType,
		'top': Math.round((paramTop * scale * stampScale) - 5) + 'px',
		'left': Math.round((paramLeft * scale * stampScale)) + 'px',
		'overflow': 'hidden',
		'white-space': 'nowrap',
		'text-overflow': 'ellipsis',
		'z-index': 'inherit',
		'color': color
	})
		//PR 994 - START
		/*.draggable({
			cancel: "text",
			start: function () {
				$('.paramSpan').focus();
			},
			stop: function () {
				$('.paramSpan').focus();
			}
		}).resizable()
		.offset(new_offset)
		.appendTo('body');
	 $('.pdfpage').droppable({
		drop: function (event, ui) {
			dropHandler4FreeText(ui.draggable[0]);
		}
	}); */
		//PR 994 - END
		;
	if ((stampParamObj.paramWidth != null
		|| stampParamObj.paramWidth != undefined || stampParamObj.paramWidth != 0)
		&& (stampParamObj.paramHeight != null
			|| stampParamObj.paramHeight != undefined || stampParamObj.paramHeight != 0)) {
		paramSpan.css({
			'width': stampParamObj.paramWidth * scale * stampScale,
			'height': stampParamObj.paramHeight * scale * stampScale
		});
	}
	// CPNVP 07/27/2015 PR 485 - END

	setFontProperties($(paramSpan)); //PR 994

	paramSpan.appendTo(divElement);
}

// =====================STAMP HANDLER - END

// =====================UTILITY FUNCTIONS - START

function showCloseDialogMessage() {
	// alert("Please close this dialog and try again ");
}

function getPageMargin(newScale) {
	scale = newScale || scale;
	marginLeft = Math
		.floor(leftToolbar
		);
	if (marginLeft < leftToolbar) {
		marginLeft = leftToolbar;
	}
	return (marginLeft) + 'px';
}

function changeCords(event) {
	evnt = event || window.event;
	var pnt = getCordinatePoint(evnt);
	/*$('#x').html(Math.round((pnt.x - marginLeft) / scale));
	$('#y').html(Math.round((pnt.y - marginTop) / scale)); */  //Commented By CPNVP 08/13/2015 PR 485

	//CPNVP 08/13/2015 PR 485 - START
	var pageNo = PDFView.page;
	currentScrollTop = $("#pageContainerMain" + (pageNo)).scrollTop();
	$('#x').html(Math.round((pnt.x - marginLeft - popUpDivLeft - 35) / scale));
	$('#y').html(Math.round((pnt.y + currentScrollTop - popUpDivTop - 42) / scale));
	//CPNVP 08/13/2015 PR 485 - END
}

function moveStamp(event) {

	var evnt = event || window.event;
	if (isIpad) {
		evnt.preventDefault();
	}
	var image = null;
	if (currentStampImage != null) {
		image = currentStampImage;
		changeCords(evnt);
	} else if (blotterImage != null) {
		image = blotterImage;
		changeCords(evnt);
	} else if (signatureImage != null) {
		image = signatureImage;
		changeCords(evnt);
	} else {
		return;
	}
	var pnt = getCordinatePoint(evnt);
	//Commented By CPNVP 08/13/2015 PR 485 - START
	/*image.css({
		'left' : pnt.x,
		'top' : pnt.y
	});*/
	//Commented By CPNVP 08/13/2015 PR 485 - END
	//CPNVP 08/13/2015 PR 485 - START
	var x = pnt.x - popUpDivLeft;
	var y = pnt.y - popUpDivTop - 5;
	if (blotterImage != null) {
		x = pnt.x;
		y = pnt.y;
		image.css({
			'left': x,
			'top': y
		});
	}
	else {


		image.css({

			'left': pnt.x - popUpDivLeft,
			'top': pnt.y - popUpDivTop
		});

	}
	//CPNVP 08/13/2015 PR 485 - END




	if (image.css('left') != undefined)
		/*
		 * if((Number(image.css('left').replace('px','')) >
		 * (Number($('.textLayer').width())+ marginLeft -10)) ||
		 * (Number(image.css('left').replace('px','')) < 42) ||
		 * (Number(image.css('top').replace('px','')) < 32)){
		 */
		/*Commented By CPNVP 08/13/2015 PR 485 - START*/
		/*if ((Number(image.css('left').replace('px', '')) < marginLeft) // For
																		// Set
																		// Left
																		// cordinate
																		// while
																		// put
																		// stamp
				|| (Number(image.css('left').replace('px', '')) > (Number($(
						'.textLayer').width()) + marginLeft))
				|| (Number(image.css('left').replace('px', '')) < 42)
				|| (Number(image.css('top').replace('px', '')) < 32)) {
			image.css('display', 'none');
		} else {
			image.css('display', 'block');
		}

		if ((Number(image.css('left').replace('px', '')) < (Number(marginLeft)))
			|| (Number(image.css('left').replace('px', '')) > (Number($(
					'.textLayer').width()) + marginLeft))) {
		hideMousePosition();
		} else {
		showMousePosition();
		}*/
		/*Commented By CPNVP 08/13/2015 PR 485 - END*/
		/*CPNVP 08/13/2015 PR 485 - START*/
		var pdfWidth = 0;

	pdfWidth = $('.pdfpage').width();
	if ((Number(image.css('left').replace('px', '')) - 42 - 120) > pdfWidth) {
		image.css('display', 'none');
	} else {
		image.css('display', 'block');
	}
	if ((Number(image.css('left').replace('px', '')) - 42 - 120) > pdfWidth) {
		hideMousePosition();
	} else {
		showMousePosition();
	}
	/*CPNVP 08/13/2015 PR 485 - END*/
	evnt.stopPropagation();

}

function hideMousePosition() {
	$('#mousePoistion').attr('hidden', 'hidden');
}

function showMousePosition() {
	if ($('#mousePoistion').attr('hidden'))
		$('#mousePoistion').removeAttr('hidden');
}

function showDialog() {
	if (!isDialogShowing) {
		$('#mainParamContainer').css({
			'display': 'block'
		});
		$('#param-overlay').css({
			'display': 'block'
		});
		isDialogShowing = true;
	} else {
		isDialogShowing = true;
		showCloseDialogMessage();
	}
}

function closeDialogBoxClick(event) {
	cancel(event);
}

// $('#closeDialogBoxForSign').on('click', function (event) {
// 	cancelDialogForSign(event);
// });

function cancel(event) {
	try {
		$('.pickedColorDiv').css({
			'background-color': fontColor
		});
		$('.sampleText').css({
			'color': fontColor
		});
	} catch (error) {
		console.log(error.message);
	}

	$('#mainParamContainer').html(
		'<h1 id="paramTitle"><span id= "closeDialogBox" style="color:white" title="Close" class="toolbarButton paramButtonImage"></span></h1>');/* children('div').detach(); */ /*CPNVP 08/13/2015 PR 485*/
	$('#mainParamContainer').css({
		'display': 'none',
		'width': '455px'
	});
	$('#param-overlay').css('display', 'none');
	isDialogShowing = false;
	try {
		removeOtherHandlers();
	} catch (err) {
		console.log(err.message);
	}

}

function cancelDialogForSign(event) {
	var val = document.getElementById('uniqueId').value;

	// $.ajax({
	// 	url: reqUrl + '/webiinkit/CallerServlet',
	// 	data: {
	// 		requestFor: 'removeSignOnClose',
	// 		uniqueId: val
	// 	},
	// 	success: function (allData) {
	// 		if (allData != null) {
	// 			// CPNVP 03/24/2015 PR 617 - START
	// 			if (Signature.socket != null) {
	// 				Signature.socket.close();
	// 			}
	// 			// CPNVP 03/24/2015 PR 617 - END
	// 		}
	// 	},
	// 	error: function (error) {
	// 		/*alert('-------- INSIDE  loadStampData 343434 ---error----+-'
	// 				+ error.toString());*/
	// 		alert('Error occurred while remove the signature.');
	// 	},
	// });
	if (Signature.socket != null) {
		Signature.socket.close();
	}
	try {
		$('.pickedColorDiv').css({
			'background-color': fontColor
		});
		$('.sampleText').css({
			'color': fontColor
		});
	} catch (error) {
		console.log(error.message);
	}
	$('#mainParamContainer').html(
		'<h1 id="paramTitle"><span id= "closeDialogBox" style="color:white" title="Close" class="toolbarButton paramButtonImage"></span></h1>');/* children('div').detach(); */ /*CPNVP 08/13/2015 PR 485*/
	$('#mainParamContainer').css({
		'display': 'none',
		'width': '455px'
	});
	$('#param-overlay').css('display', 'none');
	isDialogShowing = false;
	try {
		removeOtherHandlers();
	} catch (err) {
		console.log(err.message);
	}

}

function checkIfDesktop() {
	if ((navigator.userAgent.match(/(iPhone|iPod|iPad|Android|BlackBerry)/gi) != null)
		&& (navigator.userAgent
			.match(/(iPhone|iPod|iPad|Android|BlackBerry)/gi).length) > 0) {
		isIpad = true;
	} else {
		isIpad = false;
	}
}

function caseNumberBlurHandler(e) {
	e = e || window.event;
	var caseNo = e.target.value;
	var yearLength = 0;
	var typeLength = 0;
	var totalLength = 12;
	if (caseNo != null && caseNo != undefined && caseNo.trim() != '') {
		while ((yearLength < 4) && !isNaN(caseNo.charAt(yearLength))) {
			yearLength++;
		}
		if (yearLength <= 0) {
			return;
		}
		while (typeLength < 3 && isNaN(caseNo.charAt(yearLength + typeLength))) {
			typeLength++;
		}
		if (typeLength <= 0) {
			return;
		}
		var yearString = caseNo.substring(0, yearLength);

		if (yearString.length < 4) {

			if (yearString.length == 2) {
				var year = new Date().getFullYear();
				var isTwenty = (yearString >= 0 && yearString <= year
					.toString().substring(2, 4));
				if (isTwenty) {
					yearString = '20' + yearString;
				} else {
					yearString = '19' + yearString;
				}
			} else {
				return;
			}
		}
		var type = caseNo.substring(yearLength, yearLength + typeLength)
			.toUpperCase();
		var seq = caseNo.substring(yearLength + typeLength).trim();
		type = type == "TXD" ? "TD" : type == "TX " ? "TO" : type;
		var noOfZeroes = totalLength
			- (yearString.length + type.length + seq.length);
		if (noOfZeroes > 0) {
			caseNo = null;
			caseNo = yearString + type;

			for (var i = 0; i < noOfZeroes; i++) {
				caseNo = caseNo + '0';
			}
			caseNo = caseNo + seq;
		} else {
			caseNo = yearString + type + seq;
		}
		e.target.value = caseNo;
	}
}

function dateBlurHandler(e) {
	e = e || window.event;
	var exp = /(0[1-9]|1[012])\/(0?[1-9]|[12][0-9]|3[01])\/(\d\d)/g;
	var value = e.target.value;
	var formattedDate = null;

	if (value.indexOf("/") > 0) {// if value contains slash
		if (value.indexOf("/", 1) > 0 && value.indexOf("/", 1) < 3
			&& value.indexOf("/", 4) > 3) {// check if all slash are there
			formattedDate = value;
		} else if (value.indexOf("/", 1) > 0 && value.indexOf("/", 4) == -1) {// Check
			// if
			// second
			// slash
			// isn't
			// there
			formattedDate = value.substring(0, 3) + value.substring(3, 5) + "/"
				+ value.substring(5);
		} else if (value.indexOf("/", 1) > 3 && value.indexOf("/", 4) > 3) {// Check
			// if
			// first
			// slash
			// isn't
			// there
			formattedDate = value.substring(0, 2) + "/" + value.substring(2, 5)
				+ value.substring(5);
		}
	} else { // No slash;i.e. "12122012" -->> "12/12/2012"
		if (value.length >= 8)
			formattedDate = value.substring(0, 2) + "/" + value.substring(2, 4)
				+ "/" + value.substring(4);
	}

	if (exp.test(formattedDate)) {
		e.target.value = formattedDate;
	}
}

function validateCaseNo() {
	return true;
}

// function validateDate(date, element,array){ //Commented By CPNVP 07/27/2015
// PR 485
function validateDate(date, element, array) { // CPNVP 07/27/2015 PR 485
	var todayDate = new Date();

	var matches = (/^(\d{2})\/(\d{2})\/(\d{4})$/).exec(date);

	if (matches == null) {
		// alert('Please enter valid date'); //Commneted By CPNVP 07/27/2015 PR
		// 485
		showStampErrMsg('Please enter valid date for' + ' '
			+ getParameterName(array.paramName) + '.'); // CPNVP 07/27/2015
		// PR 485
	} else {
		var day = 31;
		var month = 12;
		var b_date = date.split("/");
		if (b_date[1] <= day) {
			if (b_date[0] <= month) {
				if (b_date[2] <= todayDate.getFullYear()) {

				} else {
					// element.val(''); //Commented By CPNVP 07/27/2015 PR 485
					element.focus();
					// alert('Please enter valid Year'); //Commented By CPNVP
					// 07/27/2015 PR 485
					showStampErrMsg('Please enter valid Year for' + ' '
						+ getParameterName(array.paramName) + '.'); // CPNVP
					// 07/27/2015
					// PR
					// 485
					return false;
				}
			} else {
				// element.val(''); //Commented By CPNVP 07/27/2015 PR 485
				// alert('Please enter valid Month'); //Commented By CPNVP
				// 07/27/2015 PR 485
				showStampErrMsg('Please enter valid Month for' + ' '
					+ getParameterName(array.paramName) + '.'); // CPNVP
				// 07/27/2015
				// PR 485
				return false;
			}
		} else {
			// element.val(''); //Commented By CPNVP 07/27/2015 PR 485
			// alert('Please enter valid Day'); //Commented By CPNVP 07/27/2015
			// PR 485
			showStampErrMsg('Please enter valid Day for' + ' '
				+ getParameterName(array.paramName) + '.'); // CPNVP
			// 07/27/2015 PR
			// 485
			return false;
		}
		if ((b_date[0] == 2) && b_date[1] > 28) {
			if (new Date(2000, 1, b_date[1]).getMonth() == 1) {
				return true;
			} else {
				// alert('Please Enter Valid Date.(MM/DD/YYYY)');
				showStampErrMsg('Please Enter Valid Date.(MM/DD/YYYY)'); // CPNVP
				// 07/27/2015
				// PR
				// 485
				return false;
			}
		} else {
			try {
				new Date(b_date[2], b_date[0], b_date[1]);
				return true;
			} catch (err) {
				// alert('Error:'+err.message);
				showStampErrMsg('Error:' + err.message); // CPNVP 07/27/2015
				// PR 485
				return false;
			}
		}
	}
}

function updateStampSizeIfVisible() {
	if (currentStampImage != null && currentStampImage != undefined) {
		currentStampImage.css({
			'width': (stampWidth * stampScale * scale) + 'px',
			'height': (stampHeight * stampScale * scale) + 'px'
		});
	}
	if (blotterImage != null && blotterImage != undefined) {
		blotterImage.css({
			'height': (blotterHeight * stampScale * scale) + 'px',
			'width': (blotterWidth * stampScale * scale) + 'px'
		});
	}
	if (signatureImage != null && signatureImage != undefined) {
		signatureImage.css({
			'height': Number(signatureHeight * stampScale * scale) + 'px',
			'width': Number(signatureWidth * stampScale * scale) + 'px'
		});
	}
}

function isConfirm() {
	// var confirmValue = confirm('Selected stamp will be removed. Please
	// confirm ?'); //Commented By CPNVP 07/27/2015 PR 485
	var confirmValue = confirm('Do you want to remove the selected stamp in RED box?'); // CPNVP
	// 07/27/2015
	// PR
	// 485
	if (confirmValue) {
		return true;
	}
	return false;
}

function getCordinatePoint(e) {
	var point = {};
	if (e) {
		if (!isIpad) {
			point.x = e.pageX;
			point.y = e.pageY;

		} else {

			if (e == '[object MouseEvent]') {
				point.x = e.pageX;
				point.y = e.pageY;
				return point;
			}
			point.x = e.originalEvent.pageX;
			point.y = e.originalEvent.pageY;
		}
	}
	return point;
}

function getTouchEndCordinatePoints(e) {
	var point = {};
	if (e) {
		if (!isIpad) {
			// Added by CPNVP PR 617 - signatures were coming on the 0,0
			// position every time as e.pageX was Nan - START
			var ua = window.navigator.userAgent;
			var msie = ua.indexOf("MSIE ");

			if (msie > 0) {
				/*point.x = e.clientX;
				point.y = e.clientY;*/ //Commented By CPNVP 08/13/2015 PR 485
				//CPNVP 08/13/2015 PR 485 - START
				point.x = e.clientX - popUpDivLeft;
				point.y = e.clientY - popUpDivTop;
				//CPNVP 08/13/2015 PR 485 - END
			} else {
				// Added by CPNVP PR 617 - signatures were coming on the 0,0
				// position every time as e.pageX was Nan - END
				/*point.x = e.pageX;
				point.y = e.pageY; */ //Commented By CPNVP 08/13/2015 PR 485
				//CPNVP 08/13/2015 PR 485 - START
				point.x = e.pageX - popUpDivLeft;
				point.y = e.pageY - popUpDivTop;
				//CPNVP 08/13/2015 PR 485 - START
			}// Added by CPNVP PR 617 - signatures were coming on the 0,0
			// position every time as e.pageX was Nan
		} else {
			if (e == '[object MouseEvent]') {
				/*point.x = e.pageX;
				point.y = e.pageY;*/ //Commented By CPNVP 08/13/2015 PR 485
				//CPNVP 08/13/2015 PR 485 - START
				point.x = e.pageX - popUpDivLeft;
				point.y = e.pageY - popUpDivTop;
				//CPNVP 08/13/2015 PR 485 - END
				return point;
			}
			/*	point.x = e.originalEvent.changedTouches[0].pageX;
			point.y = e.originalEvent.changedTouches[0].pageY;*/ //Commented By CPNVP 08/13/2015 PR 485
			//CPNVP 08/13/2015 PR 485 0 START
			point.x = e.originalEvent.changedTouches[0].pageX - popUpDivLeft;
			point.y = e.originalEvent.changedTouches[0].pageY - popUpDivTop;
			//CPNVP 08/13/2015 PR 485 - END
		}
	}
	return point;
}

function checkViewArea(clientX, clientY) {
	// clientX = clientX +85; //Resolve Signature alignement issue on left edge
	// of pdf page
	var divLeft = clientX - marginLeft;
	//var divTop = clientY - marginTop;
	if (divLeft < 0) {
		return false;
	}
	return true;
}

// HIDE STAMPS ON TOOLBAR MOUSE OVER
$('.verticalToolbarContainer').mouseover(function (e) {
	var evnt = e || window.event;
	var image = null;
	if (currentStampImage != null) {
		image = currentStampImage;
	} else if (blotterImage != null) {
		image = blotterImage;
	} else {
		return;
	}

	if (image.css('left') != undefined)
		if ((evnt.pageX < marginLeft)) {
			image.css('display', 'none');
		} else {
			image.css('display', 'block');
		}
	evnt.stopPropagation();
});

$('.verticalDefaultToolbarContainer').mouseover(function (e) {
	var evnt = e || window.event;
	var image = null;
	if (currentStampImage != null) {
		image = currentStampImage;
	} else if (blotterImage != null) {
		image = blotterImage;
	} else {
		return;
	}

	if (image.css('left') != undefined)
		if ((evnt.pageX < marginLeft)) {
			image.css('display', 'none');
		} else {
			image.css('display', 'block');
		}
	evnt.stopPropagation();
});

// HIDE STAMPS ON TOOLBAR MOUSE OVER
$('#toolbarViewer').mouseover(function (e) {
	var evnt = e || window.event;
	var image = null;
	if (currentStampImage != null) {
		image = currentStampImage;
	} else if (blotterImage != null) {
		image = blotterImage;
	} else {
		return;
	}
	if (image.css('left') != undefined)
		if ((evnt.pageY < 32)) {
			image.css('display', 'none');
		} else {
			image.css('display', 'block');
		}
	evnt.stopPropagation();
});

function showProgressBar() {

	document.getElementById('loadingImage').style.display = "block";
}

function hideProgressBar() {
	document.getElementById('loadingImage').style.display = "none";
}

// REMOVE OTHER HANDLERS
function removeOtherHandlers() {
	
	function init() {
		if (!isDialogShowing) {
			removeDeleteHandlers();
			removeBlotterHandlers();
			removeStampHandlers();
			removeFreeTextHandlers();
			removeSignatureHandlers();
			hideMousePosition();
		}
	}

	function removeDeleteHandlers() {
		try {
			var ua = window.navigator.userAgent;
			var msie = ua.indexOf("MSIE ");
			var upperLayer;
			if (msie > 0) {
				upperLayer = $('.canvasWrapper');
			} else {
				upperLayer = $('.pdfpage');
			}
			upperLayer.css('z-index', '250'); // PR 9329
			$('#deleteStamp').removeClass('selectedStamp');
			$('*').off('.deleteFunction');
		} catch (err) {
			console.log(err.message);
		}
	}

	function removeBlotterHandlers() {
		try {
			$('#blotter-icon').removeClass('selectedStamp');
			$('.blotter-icon').removeClass('selected');

			if (blotterImage != null) {
				blotterImage.css('display', 'none');
				if (!isIpad) {
					blotterImage.unbind('mousemove', moveStamp);
				} else {
					blotterImage.off('touchstart', moveStamp);
					blotterImage.off('touchmove', moveStamp);
					blotterImage.off('click', moveStamp);
					blotterImage.off('touchend', moveStamp);
				}
			}
			blotterImage = null;
			$('#viewer').unbind('mousemove', moveStamp);
			$('#viewer').off('touchstart', moveStamp);
			$('#viewer').off('touchmove', moveStamp);
			$('#viewer').off('touchend', addBlotter);

		} catch (err) {
			console.log(err.message);
		}
	}

	function removeStampHandlers() {
		try {
			if (currentStampImage != null) {
				currentStampImage.css('display', 'none');
				currentStampImage.unbind('.stampEvent');
				currentStampImage.off('.stampEvent');
				currentStampImage.off('.stampEvent');
			}
			if (currentStampInfoObj != null) {
				currentStampInfoObj = null;
			}
			if (currentStampIcon != null) {
				currentStampIcon.removeClass('selectedStamp');
				currentStampIcon.parent('a').removeClass('selected');
			}
			$('#viewer').unbind('.stampEvent');
			$('#viewer').off('.stampEvent');
			$('#viewer').off('.stampEvent');
			$('.stmapImage').off('.stampEvent');
			currentStampIcon = null;
			currentStampImage = null;
		} catch (err) {
			console.log(err.message);
		}
	}

	function removeFreeTextHandlers() {
		try {
			$('#freeText').children('img').removeClass('selectedStamp');
			$('#freeText').removeClass('select-font-style');
			$('.pdfpage').off('.freeTextDraw');
			$('.pdfpage').off('.freeTextDraw');
			$('.stampDiv').css('border-color', 'transparent');
			$('body').css('cursor', 'default');
			//$('.freeTextField').off('blur', blurHandler4FreeText); //Commented for PR 994
			$('.freeTextField').off('blur'); //PR 994
			$('.pdfpage').css('cursor', 'default');
			$('.pdfpage').off('mousedown.freeTextDraw'); //PR 994
		} catch (err) {
			console.log(err.message);
		}
	}
	function removeSignatureHandlers() {
		try {
			$('.signature').children('img').removeClass('selectedStamp');
			$('#viewer').off('.signature');
			$('.sign-pen').off('.signature');
			$('.sign-pen').off('.signature');
			$('body').css('cursor', 'default');
			$('.sign-pen').remove();
			signatureImage = null;
			signatureX = null;
			signatureY = null;
		} catch (err) {
			console.log(err.message);
		}
	}
	init();
}

// =====================UTILITY FUNCTIONS -END

// =====================DELETE FUNCTION -START
// $('#deleteStamp').click(function(){// Commented by CPNVP PR 617 - delete not
// working
function setDelete(event) {// CPNVP PR 617 - delete not working
	removeOtherHandlers();
	var ua = window.navigator.userAgent;
	var msie = ua.indexOf("MSIE ");
	var upperLayer;
	if (msie > 0) {
		upperLayer = $('.canvasWrapper');
	} else {
		upperLayer = $('.pdfpage');
	}
	upperLayer.css('z-index', '0'); // PR 9329
	$(this).addClass('selectedStamp');
	$('.stampDivImg')
		.on(
			{
				'touchstart.deleteFunction': function (event) {
					$(this).css('border', 'rgb(255,0,0) solid 1px');
				},
				'mouseover.deleteFunction': function (event) {
					$(this).css('border', 'rgb(255,0,0) solid 1px');
					$(this).prop('onclick',null).off('click');
					$(this)
						.on(
							'click.stampDivImg',
							function (event) {
								if (isConfirm()) {
									currentStampedImage = $(this);
									if ((currentStampedImage != null || currentStampedImage != undefined)) {
										console
											.log('before remove***');
										removeStampFromArray();
										console
											.log('after remove***');
										currentStampedImage
											.parent()
											.detach();
										currentStampedImage = null;
										currentStampImage = null;
										if (currentStampIcon != null)
											currentStampIcon
												.removeClass('selectStamp');
										currentStampIcon = null;
									}
								}
							});
				},
				'mouseout.deleteFunction': function (event) {
					$(this).css('border', 'blue solid 1px');
					$(this).off('.stampDiv');
				}
			});

	$('.blotterAdded').on({
		'touchstart.deleteFunction': function (event) {
			$(this).css('border', 'rgb(255,0,0) solid 1px');
		},
		'mouseover.deleteFunction': function (event) {
			$(this).css('border', 'rgb(255,0,0) solid 1px');
			$(this).on('click.blotterAdded', function (event) {
				if (isConfirm()) {
					blottedImage = $(this);
					if (blottedImage != null || blottedImage != undefined) {
						removeBlotterFromArray(blottedImage);
						blottedImage.detach();
						$('#viewer').unbind('mousemove');
						blottedImage.unbind('mousemove');
						blottedImage = null;
					}
				}
			});
		},
		'mouseout.deleteFunction': function (event) {
			$(this).css('border', 'transparent solid 1px');
			$(this).off('.blotterAdded');
		}
	});

	$('.freeTextField')
		.on(
			{
				'touchstart.deleteFunction': function (event) {
					$(this).css('border', 'rgb(255,0,0) solid 1px');
				},
				'mouseover.deleteFunction': function (event) {
					$(this).css('border', 'rgb(255,0,0) solid 1px');
					$(this)
						.on(
							'click.freeTextField',
							function (event) {

								if (isConfirm()) {
									/*$('.freeTextField')
										.off('blur',
											blurHandler4FreeText);*/
									$('.freeTextField').off('blur');
									var currentFreeTextField = $(this);
									if (currentFreeTextField != null
										|| currentFreeTextField != undefined) {
										setTimeout(
											function () {
												removeFreeTextFieldFromArray(currentFreeTextField);
												currentFreeTextField
													.detach();
												currentFreeTextField = null;
											}, 150);
									}
								}
							});
				},
				'mouseout.deleteFunction': function (event) {
					$(this).css('border', 'transparent solid 1px');
					$(this).off('.freeTextField');
				}
			});

	$('.signature-image-added')
		.on(
			{
				'touchstart.deleteFunction': function (event) {
					$(this).css('border', 'rgb(255,0,0) solid 1px');
				},
				'mouseover.deleteFunction': function (event) {
					$(this).css('border', 'rgb(255,0,0) solid 1px');
					$(this)
						.on(
							'click.signatureDelete',
							function (event) {
								if (isConfirm()) {
									signatureImage = $(this);
									if ((signatureImage != null || signatureImage != undefined)) {
										removeSignatureFromArray();
										signatureImage.detach();
										signatureImage = null;
									}
								}
							});
				},
				'mouseout.deleteFunction': function (event) {
					$(this).css('border', 'transparent solid 1px');
					$(this).off('.signatureDelete');
				}
			});

}

function removeStampFromArray() {
	var stampPath = currentStampedImage.attr('src');
	var style = currentStampedImage.parent().attr('style');
	var xCord = style.substring(style.indexOf('left: '));
	xCord = xCord.substring(0, (xCord.indexOf('px;')));
	xCord = xCord.substring(xCord.lastIndexOf(':'));
	xCord = xCord.replace(': ', '');
	xCord = xCord.replace(';', '');
	var yCord = style.substring(style.indexOf('top: '));
	yCord = yCord.substring(0, (yCord.indexOf('px;')));
	yCord = yCord.substring(yCord.lastIndexOf(':'));
	yCord = yCord.replace(': ', '');
	yCord = yCord.replace(';', '');
	var stampName = stampPath.substring(stampPath.lastIndexOf(separator) + 1,
		stampPath.lastIndexOf('_'));
	//var stamp = new Stamp(stampName, 0, 0, 0, 0, true, null, 0, 0, null, 0);
	var pgNo = 0;
	if (document.getElementById("enteredPageNo").value != null
		&& document.getElementById("enteredPageNo").value != ''
		&& document.getElementById("enteredPageNo").value != 0)
		pgNo = document.getElementById("enteredPageNo").value;
	else
		pgNo = PDFView.page;
	for (var i = 0; i < stampsOnCanvas.length; i++) {
		if (stampsOnCanvas[i].pageNo == pgNo)
			if (stampsOnCanvas[i].src == stampPath)
				/*Commented By CPNVP 09/22/2015 PR 485 - START*/
				/*if ((xCord / scale) == stampsOnCanvas[i].xCord)
					if ((yCord / scale) == stampsOnCanvas[i].yCord) {
				*/
				/*Commented By CPNVP 09/22/2015 PR 485 - END*/
				/*CPNVP 09/22/2015 PR 485 - START*/
				if (Math.round(xCord / scale) == Math.round(stampsOnCanvas[i].xCord))
					if (Math.round(yCord / scale) == Math.round(stampsOnCanvas[i].yCord)) {
						/*CPNVP 09/22/2015 PR 485 - END*/
						stampsOnCanvas.splice(i, 1);
						return false;
					}
	}

  // Resize the signature start
  var id = currentStampedImage.parent().parent().attr('id')
  for (var i = 0; i < stampsOnCanvas.length; i++) {
    if (stampsOnCanvas[i].pageNo == pgNo && stampsOnCanvas[i].id == id){
      stampsOnCanvas.splice(i, 1); 
      console.log(stampsOnCanvas);
    }
  }
  // Resize the signature end


}

function removeBlotterFromArray(blotter) {
	for (var i = 0; i < blotterOnCanvas.length; i++) {
		if (blotterOnCanvas[i].pageNo == PDFView.page) {
			if (blotterOnCanvas[i].stampName == blotter.attr('id')) {
				blotterOnCanvas.splice(i, 1);
				break;
			}
		}
	}
}

function removeFreeTextFieldFromArray(currentFreeTextField) {
	
	for (var i = 0; i < freeTextOnCanvas.length; i++) {
		if (freeTextOnCanvas[i].value.id == currentFreeTextField.attr('id')) {
			freeTextOnCanvas.splice(i, 1);
		}
	}
}

function removeSignatureFromArray() {
	for (var i = 0; i < signatureOnCanvas.length; i++) {
		if (signatureOnCanvas[i].stampName == signatureImage.attr('id')) {
			signatureOnCanvas.splice(i, 1);
		}
	}
}

// ========================================== SCALING HANDLER - START
function changeScaleValue() {
	$('.selected-fonts2').html(Math.round(Number(scale * 100)) + '%');
	stampScale = ($('#stampZoom option[value=\'70\']').val() / 100);
	$('#stampZoom option[value=\'70\']').attr('selected', 'selected');
	updateStampSizeIfVisible();
}

function scaleDocument(value) {
	changeStampFlag();
	setScaleInViewer(value);
	PDFView.setScale(value);
	addStampOnPageChange(0);
	changeScaleValue();

}

function addStampOnPageChange(pageNo) {
	
	$('#stampZoom option[value=\'70\']').attr({
		'selected': 'selected'
	});
	$(".selected-fonts3").text($('#stampZoom option:selected').text());
	// Commented By CPNVP 07/27/2015 PR 485 - START
	/*
	 * if(pageNo == 0){ pageNo = PDFView.page; }
	 */
	// Commented By CPNVP 07/27/2015 PR 485 - END
	// CPNVP 07/27/2015 PR 485 - START
	if (document.getElementById("enteredPageNo").value != null
		&& document.getElementById("enteredPageNo").value != ''
		&& document.getElementById("enteredPageNo").value != 0) {
		pageNo = document.getElementById("enteredPageNo").value;
	} else {
		pageNo = PDFView.page;
	}
	// CPNVP 07/27/2015 PR 485 - END
	if ((stampsOnCanvas == undefined || stampsOnCanvas == null)
		&& (freeTextOnCanvas == undefined || freeTextOnCanvas == null)
		&& (blotterOnCanvas == undefined || blotterOnCanvas == null)) {
		return false;
	}
	var i;
	if (stampsOnCanvas != null && stampsOnCanvas != undefined) {
		for (i = 0; i < stampsOnCanvas.length; i++) {
			if (stampsOnCanvas[i].pageNo == pageNo) {
				if (!stampsOnCanvas[i].isAdded) {

					var divTop = Number(stampsOnCanvas[i].yCord)
						* Number(scale);
					var divLeft = Number(stampsOnCanvas[i].xCord)
						* Number(scale);
					// var pageNo = stampsOnCanvas[i].pageNo; //Commented By
					// CPNVP 07/27/2015 PR 485
					var src = stampsOnCanvas[i].src;
					var height = stampsOnCanvas[i].height
						* stampsOnCanvas[i].stampScale * scale;
					var width = stampsOnCanvas[i].width * scale * stampScale;
					var divElement = document.createElement('div');
					// Commented By CPNVP 07/27/2015 PR 485 - START
					/*
					 * for(var j=0; j<stampsOnCanvas[i].parameters.length;
					 * j++){ if(stampsOnCanvas[i] == null || stampsOnCanvas[i] ==
					 * undefined){ continue; }
					 * if(stampsOnCanvas[i].parameters[j] == null ||
					 * stampsOnCanvas[i].parameters[j] == undefined){ continue; }
					 * if(stampsOnCanvas[i].parameters[j].showToUser == null ||
					 * stampsOnCanvas[i].parameters[j].showToUser == undefined){
					 * continue; }
					 * if(stampsOnCanvas[i].parameters[j].showToUser.toLowerCase() ==
					 * 'y'){
					 * 
					 * addParamWithStyle(divElement,
					 * stampsOnCanvas[i].parameters[j]); }
					 *  }
					 */
					// Commented By CPNVP 07/27/2015 PR 485 - END
					// CPNVP 07/27/2015 PR 485 - START
					if (stampsOnCanvas[i].parameters != null
						&& stampsOnCanvas[i].parameters != undefined
						&& stampsOnCanvas[i].parameters != '') {
						for (var j = 0; j < stampsOnCanvas[i].parameters.length; j++) {

							if (stampsOnCanvas[i].parameters[j] == null
								|| stampsOnCanvas[i].parameters[j] == undefined) {
								continue;
							}
							if (stampsOnCanvas[i].parameters[j].showToUser == null
								|| stampsOnCanvas[i].parameters[j].showToUser == undefined) {
								continue;
							}
							// if(stampsOnCanvas[i].parameters[j].showToUser.toLowerCase()
							// == 'y'){
							addParamWithStyle(divElement,
								stampsOnCanvas[i].parameters[j],
								stampsOnCanvas[i].stampScale);
							// }
						}
					}
					// CPNVP 07/27/2015 PR 485 - START
					var currentPageNumberForStamp = pageNo;
					if (document.getElementById("enteredPageNo").value != null
						&& document.getElementById("enteredPageNo").value != ''
						&& document.getElementById("enteredPageNo").value != 0) {
						currentPageNumberForStamp = PDFView.page;
					}

					// CPNVP 07/27/2015 PR 485 - END
					addStamp2TextLayer(divElement, divTop, divLeft, src,
						currentPageNumberForStamp, height, width);
					stampsOnCanvas[i].isAdded = true;
				}
			}
		}
	}
	if (blotterOnCanvas != null && blotterOnCanvas != undefined) {
		// CPNVP 07/27/2015 PR 485 - START
		if (document.getElementById("enteredPageNo").value != null
			&& document.getElementById("enteredPageNo").value != ''
			&& document.getElementById("enteredPageNo").value != 0) {
			pageNo = document.getElementById("enteredPageNo").value;
		} else {
			pageNo = PDFView.page;
		}
		// CPNVP 07/27/2015 PR 485 - END
		for (i = 0; i < blotterOnCanvas.length; i++) {

			if (blotterOnCanvas[i].pageNo == pageNo) {
				if (!blotterOnCanvas[i].isAdded) {
					var top = Number(blotterOnCanvas[i].yCord) * Number(scale);
					var left = Number(blotterOnCanvas[i].xCord) * Number(scale);
					// var pageNo = blotterOnCanvas[i].pageNo; //Commented By
					// CPNVP 07/27/2015 PR 485
					var stampScale = blotterOnCanvas[i].stampScale;
					// CPNVP 07/27/2015 PR 485 - START
					var currentPageNumberForBlotter = pageNo;
					if (document.getElementById("enteredPageNo").value != null
						&& document.getElementById("enteredPageNo").value != ''
						&& document.getElementById("enteredPageNo").value != 0) {
						currentPageNumberForBlotter = PDFView.page;
					}
					// CPNVP 07/27/2015 PR 485 - END
					// addBlotterWithStyle(pageNo, left, top, stampScale);
					// //Commented By CPNVP 07/27/2015 PR 485
					addBlotterWithStyle(currentPageNumberForBlotter, left, top,
						stampScale);
					blotterOnCanvas[i].isAdded = true;
				}
			}
		}
	}
	if (freeTextOnCanvas != null && freeTextOnCanvas != undefined) {
		// CPNVP 07/27/2015 PR 485 - START
		if (document.getElementById("enteredPageNo").value != null
			&& document.getElementById("enteredPageNo").value != ''
			&& document.getElementById("enteredPageNo").value != 0) {
			pageNo = document.getElementById("enteredPageNo").value;
		} else {
			pageNo = PDFView.page;
		}
		// CPNVP 07/27/2015 PR 485 - END
		for (i = 0; i < freeTextOnCanvas.length; i++) {
			if (freeTextOnCanvas[i].value.page == pageNo) {
				if (!freeTextOnCanvas[i].value.isAdded) {
					var top = Number(freeTextOnCanvas[i].value.y) * Number(scale);
					var left = Number(freeTextOnCanvas[i].value.x) * Number(scale);

					// var page = freeTextOnCanvas[i].page;//Commented By CPNVP
					// 07/27/2015 PR 485
					var h = freeTextOnCanvas[i].value.height * scale;
					var w = freeTextOnCanvas[i].value.width * scale;
					var text = freeTextOnCanvas[i].value.text;
					var id = freeTextOnCanvas[i].value.id;
					var param = freeTextOnCanvas[i].value.parameter;
					// CPNVP 07/27/2015 PR 485 - START
					var currentPageNumberForFreeText = pageNo;
					if (document.getElementById("enteredPageNo").value != null
						&& document.getElementById("enteredPageNo").value != ''
						&& document.getElementById("enteredPageNo").value != 0) {
						currentPageNumberForFreeText = PDFView.page;
					}
					// CPNVP 07/27/2015 PR 485 - END
					// addFreeTextWithStyle(id, text, left, top, w, h, page,
					// param);//Commented By CPNVP 07/27/2015 PR 485
					addFreeTextWithStyle(id, text, left, top, w, h,
						currentPageNumberForFreeText, param);
					freeTextOnCanvas[i].value.isAdded = true;
				}
			}
		}
	}
	if (signatureOnCanvas != null && signatureOnCanvas != undefined) {
		for (i = 0; i < signatureOnCanvas.length; i++) {
			if (signatureOnCanvas[i].pageNo == pageNo) {
				if (!signatureOnCanvas[i].isAdded) {
					var divTop = Number(signatureOnCanvas[i].yCord)
						* Number(scale);
					var divLeft = Number(signatureOnCanvas[i].xCord)
						* Number(scale);
					var pageNo = signatureOnCanvas[i].pageNo;
					var src = signatureOnCanvas[i].src;
					var height = Number(signatureOnCanvas[i].height
						* signatureOnCanvas[i].stampScale * scale);
					var width = Number(signatureOnCanvas[i].width * scale
						* signatureOnCanvas[i].stampScale);
					addSignatureWithStyle(divTop, divLeft, src, pageNo, height,
						width);
					signatureOnCanvas[i].isAdded = true;
				}
			}
		}
	}
}
function changeStampFlag() {
	var i = 0;
	try {
		for (i = 0; i < stampsOnCanvas.length; i++) {
			stampsOnCanvas[i].isAdded = false;
		}
	} catch (err) {
		console.log(err.message);
	}
	try {
		for (i = 0; i < blotterOnCanvas.length; i++) {
			blotterOnCanvas[i].isAdded = false;
		}
	} catch (err) {
		console.log(err.message);
	}
	try {
		for (i = 0; i < freeTextOnCanvas.length; i++) {
			freeTextOnCanvas[i].value.isAdded = false;
		}
	} catch (err) {
		console.log(err.message);
	}
	try {
		for (i = 0; i < signatureOnCanvas.length; i++) {
			signatureOnCanvas[i].isAdded = false;
		}
	} catch (err) {
		console.log(err.message);
	}
}

// ========================================== SCALING HANDLER - END

// ========================================== SAVE TOOLBAR HANDLER -START
function formatFormFieldsValue() {
	for (var i = 0; i < formFieldArray.length; i++) {
		try {
			if (($('.textLayer').children('.pdfpage').children('[name= \''
				+ formFieldArray[i].fieldName + '\']'))[0].type == 'checkbox') {

				formFieldArray[i].fieldValue = ($('.textLayer').children(
					'.pdfpage').children('[name= \''
						+ formFieldArray[i].fieldName + '\']'))[0].checked;
				continue;
			}
			if (($('.textLayer').children('.pdfpage').children('[name= \''
				+ formFieldArray[i].fieldName + '\']'))[0].type == 'radio') {
					
					const rbs = ($('.textLayer').children(
						'.pdfpage').children('[name= \''
							+ formFieldArray[i].fieldName + '\']'));
					

					let selectedValue;
						for (const rb of rbs) {
							if (rb.checked) {
								selectedValue = rb.value;
								break;
							}
						}

						

				formFieldArray[i].fieldValue = selectedValue;
				formFieldArray[i].isRadio = true;
				continue;
			}
			if (($('.textLayer').children('.pdfpage').children('[name= \''
				+ formFieldArray[i].fieldName + '\']'))[0].value != null
				|| ($('.textLayer').children('.pdfpage')
					.children('[name= \'' + formFieldArray[i].fieldName
						+ '\']'))[0].value != undefined)
				formFieldArray[i].fieldValue = ($('.textLayer').children(
					'.pdfpage').children('[name= \''
						+ formFieldArray[i].fieldName + '\']'))[0].value;
		} catch (err) {
			console.log('Message : ' + err.message);
		}
	}
}

function generateFinalString() {

	
	formatFormFieldsValue();
	var finalData = new Array();
	finalData[0] = stampsOnCanvas;
	finalData[1] = formFieldArray;
	finalData[2] = blotterOnCanvas;
	finalData[3] = flatPdf;

	//PR 957 - START
	var freeTextCanvasValArray = new Array();
	for (let freeTextElement of freeTextOnCanvas) {
		freeTextCanvasValArray.push(freeTextElement["value"]);
	}
	//PR 957 - END

	// finalData[4] = freeTextOnCanvas; //Commented for PR 957
	finalData[4] = freeTextCanvasValArray; //PR 957
	finalData[5] = signatureOnCanvas;
	return JSON.stringify(finalData, null, 2);
}
//CPNVP 08/10/2016 PR 8210 - START
function closeWindowNoPrompt() {

	window.open('', '_self', '').close();

}//CPNVP 08/10/2016 PR 8210 - END
function submitPdf(stampId, requestId, requestFor, formName, passFieldId) {
	var pageNo = document.getElementById("pageNumber").value;
	var uniqueID = document.getElementById('uniqueId').value;
	//CPNVP 11/19/2015 PR 661 - START
	/*var fileDate = '';
	var transactionID = '';
	if(document.getElementById('fileDate') != ' ' &&  document.getElementById('fileDate') != null && document.getElementById('fileDate') != undefined)
		fileDate = document.getElementById('fileDate').value; 
	if(document.getElementById('transactionID') != ' ' && document.getElementById('transactionID') != null && document.getElementById('transactionID') != undefined)
		transactionID = document.getElementById('transactionID').value; */
	//CPNVP 11/19/2015 PR 661 - END
	//CPNVP 12/02/2015 PR 661 - START

	var transactionID = '';
	if (document.getElementById('fileDateStamp') != ' ' && document.getElementById('fileDateStamp') != null && document.getElementById('fileDateStamp') != undefined
		&& document.getElementById('fileDateStamp').value != null && document.getElementById('fileDateStamp').value.length > 0) {

		var fileDate = document.getElementById('fileDateStamp').value;
		if (document.getElementById('fileDate') != null) {

			document.getElementById('fileDate').value = fileDate;
		}
	}
	if (document.getElementById('transactionID') != ' ' && document.getElementById('transactionID') != null && document.getElementById('transactionID') != undefined) {
		transactionID = document.getElementById('transactionID').value;
	}
	//CPNVP 12/02/2015 PR 661 - END
	
	var finalJsonString = generateFinalString();
	
	var passField = null;
	if (passFieldId != null)
		if (kwd != null || kwd != undefined || kwd != '') {
			passField = kwd;
		}
	if (requestFor == 'savePdf') {
		//CPNVP 08/10/2016 PR 8210 - START
		var tranURL = document.URL;
		var isIInkitForDUCS = false;
		if (tranURL != null && tranURL != "" && tranURL.indexOf("IInkit.jsp") != -1) {
			isIInkitForDUCS = true;
		}
	
		//CPNVP 08/10/2016 PR 8210 - END
		$.ajax({
			//url : reqUrl + '/webiinkit/CallerServlet',//Commented By CPNVP 08/10/2016 PR 8210
			//CPNVP 08/10/2016 PR 8210
			// url: reqUrl + '/webiinkit/CallerServlet?isIInkitForDUCS=' + isIInkitForDUCS,
			url: updateEndpoint,
			type: 'POST',
			//dataType: 'JSON',
			data: {
				requestFor: requestFor,
				uniqueId: uniqueID,
				pageNo: pageNo,
				passFieldId: passField,
				//CPNVP 11/19/2015 PR 661 - START
				fileDate: fileDate,
				transactionID: transactionID,
				//CPNVP 11/19/2015 PR 661 - END
				pdfStamps: finalJsonString
			},
			beforeSend: function (request) {
				if (localStorage.getItem('accessToken')) {
					request.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('accessToken'));
				}
			},
			success: function (allData) {
				if (allData == 'Error') {
					alert('Error occurred while save the document11.');
					return;
				} else {
					//window.location.reload(); //Commented for PR 994

					//PR 994 - START
					$('#savePdfModal').modal('hide');
					var currentLoc = window.location.href;
					currentLoc = JSON.stringify(currentLoc);
					if(currentLoc.includes('sign')){
						window.location = window.location.origin + "/guestUser/";
						$("#fileSaveMsg").show();
					} else {
						window.location.reload();
					}
					//PR 994 - END
					return;
				}
				if (allData != null) {

				}

				hideProgressBar();
				//CPNVP 08/10/2016 PR 8210 - START
				// if (isIInkitForDUCS) {
				alert('Succesfully save stamp document.');
				closeWindowNoPrompt();
				/* $('#popUpDivContainer').css({
					display: 'none'
				});
				$('.iinkitModalBg').css({
					visibility: 'hidden'
				}); */
				return;

				// }//CPNVP 08/10/2016 PR 8210 - END

				if (window.dialogArguments) {
					var open = window.dialogArguments || opener || parent
						|| top;
					open.uniqueID = uniqueID;
				} else {
					// window.opener.returnCalltoUpdateSignedDoc(uniqueID);
					// //Commented By CPNVP 07/27/2015 PR 485
					// CPNVP 07/27/2015 PR 485 - START
					try {
						stampedFlag = "true";
						/*window.opener.returnCalltoUpdateSignedDoc(uniqueID,
								stampedFlag);*/  //Commented By CPNVP 08/13/2015 PR 485
						returnCalltoUpdateSignedDoc(uniqueID,
							stampedFlag); //CPNVP 08/13/2015 PR 485
					} catch (err) {
						console.log(err.message);
					}
					// CPNVP 07/27/2015 PR 485 - END
				}
				//window.close(); //Commented By CPNVP 08/13/2015 PR 485
			},
			error: function (error) {
				//CPNVP 08/10/2016 PR 8210 - START
				if (tranURL != null && tranURL != "" && tranURL.indexOf("IInkit.jsp") != -1) {
					alert(error.responseText);
					hideProgressBar();
					return;
				}//CPNVP 08/10/2016 PR 8210 - END
				hideProgressBar();
				// alert('-------- INSIDE loadStampData
				// ---error----+-'+error.Message);
				//alert('Error occurred while save the document.'); //Commented by CPNVP 10/27/2017 PR 781
				//CPNVP 10/27/2017 PR 781 - START
				if (error.responseText != null && error.responseText != "" && error.responseText.indexOf("Utility") != -1) {
					alert(error.responseText);
				} else {
					alert('Error occurred while save the document22.');
				}
				//CPNVP 10/27/2017 PR 781 - END
			},
		});
	}
	//CPNVP 08/13/2015 PR 485 - START
	else if (requestFor == 'repairPdf') {

		$.ajax({
			url: repairEndpoint,
			type: 'POST',
			//dataType: 'JSON',
			data: {
				requestFor: requestFor,
				uniqueId: uniqueID,
				pageNo: pageNo,
				passFieldId: passField,
				pdfStamps: finalJsonString
			},
			beforeSend: function (request) {
				if (localStorage.getItem('accessToken')) {
					request.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('accessToken'));
				}
				showProgressBar();
			},
			success: function (allData) {
				if (allData == 'Error') {
					alert('Error occurred while repair the document.');
					return;
				}
				if (allData != null) {
				}
				hideProgressBar();
				generateDialogForRepairPdf();
				showDialog();
			},
			error: function (error) {
				hideProgressBar();
				alert('Error occurred while repair the document.');
			},
		});
	}
	else if (requestFor == 'replacePdf') {
		$.ajax({
			url: replaceRepairedEndpoint,
			type: 'POST',
			// dataType: 'JSON',
			data: {
				requestFor: requestFor,
				uniqueId: uniqueID,
				pageNo: pageNo,
				passFieldId: passField,
				pdfStamps: finalJsonString
			},
			beforeSend: function (request) {
				if (localStorage.getItem('accessToken')) {
					request.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('accessToken'));
				}
				showProgressBar();
			},
			success: function (allData) {
				if (allData == 'Error') {
					alert('Error occurred while replace the document.');
					return;
				}
				if (allData != null) {
					window.location.reload();
				}
				hideProgressBar();
				if (window.dialogArguments) {
					var open = window.dialogArguments || opener || parent
						|| top;
					open.uniqueID = uniqueID;
				} else {
					try {
						stampedFlag = "true";
						returnCalltoUpdateSignedDoc(uniqueID,
							stampedFlag);

					} catch (err) {
						console.log(err.message);
					}
				}

			},
			error: function (error) {
				hideProgressBar();
				alert('Error occurred while replace the document');
			},
		});

	}
	//CPNVP 08/13/2015 PR 485 - END
	else {

		let endpoint = '';
		if (requestFor == 'downloadPdf') {
			endpoint = previewEndpoint;
		} else if (requestFor == 'previewRepairedPdf') {
			endpoint = reqUrl + 'ownerDocument/previewRepairedDocument/' + mainRequestId;
		}

		// Below ajax call is only for downloading pdf
		$.ajax({
			url: endpoint,
			type: 'POST',
			data: {
				requestFor: requestFor,
				uniqueId: uniqueID,
				pageNo: pageNo,
				passFieldId: passField,
				fileDate: fileDate,
				transactionID: transactionID,
				pdfStamps: finalJsonString,
			},
			responseType: 'blob',
			beforeSend: function (request) {
				if (localStorage.getItem('accessToken')) {
					request.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('accessToken'));
				}
			},
			success: function (allData) {
				if (allData == 'Error') {
					alert('Error occurred while save the document11.');
					return;
				}
				if (allData != null) {
					const linkSource = `data:application/pdf;base64,${allData}`;
					var link = document.createElement('a');
					link.href = linkSource;
					link.download = "preview_" + new Date().getTime() + ".pdf";
					link.click();
				}

				hideProgressBar();

				closeWindowNoPrompt();
				return;

			},
			error: function (error) {
				if (tranURL != null && tranURL != "" && tranURL.indexOf("IInkit.jsp") != -1) {
					alert(error.responseText);
					hideProgressBar();
					return;
				}
				hideProgressBar();
				if (error.responseText != null && error.responseText != "" && error.responseText.indexOf("Utility") != -1) {
					alert(error.responseText);
				} else {
					alert('Error occurred while save the document22.');
				}
			},
		});

		// $('#' + requestId).val(requestFor);
		// $('#uniqueId').val(uniqueID);
		// $('#pageNo').val(pageNo);
		// $('#' + stampId).val(finalJsonString);
		// if (passFieldId != null)
		// 	if (kwd != null || kwd != undefined || kwd != '') {
		// 		$('#' + passFieldId).val(kwd);
		// 	} else {
		// 		$('#' + passFieldId).val(null);
		// 	}
		// $('#' + formName).submit();
	}
}

function previewPDF() {

	if (isIpad) {

		finalPreviewString = generateFinalString();

		var html = $('<html>');
		html.append($('<head>').append($('<title>').html('Download Pdf')));

		var body = $('<body>');

		var form = $('<form>').attr({
			'action': 'CallerServlet',
			'method': 'post',
			'enctype': 'application/x-www-form-urlencoded',
			'id': 'previewForm'
		});

		var request = $('<input>').attr({
			'id': 'requestFor',
			'class': 'requestFor',
			'type': 'hidden',
			'name': 'requestFor',
			'value': 'downloadPdf'
		});
		var stamps = $('<input>').attr({
			'id': 'pdfStamps',
			'class': 'pdfStamps',
			'type': 'hidden',
			'name': 'pdfStamps',
			'value': finalPreviewString
		});
		var pass = $('<input>').attr({
			'id': 'passwordToolbar',
			'class': 'passwordToolbar',
			'type': 'hidden',
			'name': 'passwordToolbar'
		});

		if (kwd != null || kwd != undefined || kwd != '') {
			pass.val(kwd);
		} else {
			pass.val(null);
		}

		form.append(request);
		form.append(stamps);
		form.append(pass);

		body.append(form);
		html.append(body);

		var previewWindow = window.open('', '_blank',
			'width = 200, height = 200');
		previewWindow.focus();
		previewWindow.document.write('<html>' + html.html() + '</html>');
		previewWindow.document.getElementById('previewForm').submit();

	} else {
		submitPdf('pdfStamps', 'requestFor', 'downloadPdf', 'toolbarForm',
			'passwordToolbar');
	}
}

function savePdf() {

	//Commented for PR 994 - START
	// Commented By CPNVP 07/27/2015 PR 485 - START
	/*
	 * showProgressBar();
	 * submitPdf('pdfStamps','requestFor','savePdf','toolbarForm',
	 * 'passwordToolbar');
	 */
	// Commented By CPNVP 07/27/2015 PR 485 - END
	// CPNVP 07/27/2015 PR 485 - START
	// var confirmValue = confirm('Do you want to save the changes to document?');
	// if (confirmValue) {
	// 	showProgressBar();
	// 	submitPdf('pdfStamps', 'requestFor', 'savePdf', 'toolbarForm',
	// 		'passwordToolbar');
	// } else {
	// 	//CPNVP 08/10/2016 PR 8210 - START
	// 	//Commented By CPNVP 10/05/2016 PR 8210 - START
	// 	/*var tranURL = document.URL;
	// 	if(tranURL!=null && tranURL!="" && tranURL.indexOf("IInkit.jsp")!=-1){
	// 		closeWindowNoPrompt();
	// 	}*/
	// 	//Commented By CPNVP 10/05/2016 PR 8210 - END
	// 	//CPNVP 08/10/2016 PR 8210 - END
	// 	return false;
	// }
	// CPNVP 07/27/2015 PR 485 - END
	//Commented for PR 994 - END

	//PR 994 - START
	console.log(formFieldArray.length);
	if(!formFieldArray  || formFieldArray.length == 0) {
		var confirmValue = confirm('Do you want to save the changes to document?');
		if (confirmValue) {
			showProgressBar();
			submitPdf('pdfStamps', 'requestFor', 'savePdf', 'toolbarForm', 'passwordToolbar');
		} else {
			return false;
		}
	} else {
		$('#savePdfModal').modal('show');
	}
	//PR 994 - END
}

function saveSecurePdf(printing, contentcopying, openpassword, encryptpassword) {
	submitPdf('pdfStampsSecure', 'requestFor', 'securePdf', 'secureDialogForm',
		'passwordSecure');
}

function isValidateAttachment() {
	var action = true;
	if ($('#input0').val() == null || $('#input0').val() == undefined
		|| $('#input0').val() == '') {
		alert('Description is required');
		action = false;
		return;
	}

	if ($('#input1_0').attr("checked") != "checked"
		&& $('#input1_1').attr("checked") != "checked") {
		alert('Please select atleast one radio button');
		action = false;
		return;
	}

	if ($('#input1_0').attr("checked") == "checked") {
		if ($('#input2').val() == "") {
			alert('Text to attach is required');
			action = false;
			return;
		}
	}
	if ($('#input1_1').attr("checked") == "checked") {
		if ($('#input3').val() == "") {
			alert('Please select a file to attach');
			action = false;
			return;
		}
	}
	if (action) {
		$('#password123').val(kwd);
		isDialogShowing = false;
		$('#dialog-mainForm').submit();
	} else {
		alert('Your request has not been processed.');
	}

}

$('#input1_0').on('click', function () {
	$('#dialog-mainForm').removeAttr('enctype');
});

$('#input1_1').on('click', function () {
	$('#dialog-mainForm').attr({
		'enctype': 'multipart/form-data'
	});
});
function submitSecurePdf(event) {

	var action = true;
	var printing = $('#combobox1').val();
	var contentcopying = $('#combobox2').val();
	var openpassword = $('#input2').val();
	var encryptpassword = $('#input4').val();
	if ($('#input2').val() != $('#input3').val()) {
		alert('Password and Confirm Password do not match');
		action = false;
		return action;
	}
	if ($('#input4').val() != $('#input5').val()) {
		alert('Password and Confirm Password do not match');
		action = false;
		return action;
	}
	var confirmation = confirm('Before securing the PDF you must save it.Do you want to save and secure the PDF?');
	if (confirmation) {
		saveSecurePdf(printing, contentcopying, openpassword, encryptpassword);
		isDialogShowing = false;
	} else {
		cancel(event);
	}
	return action;
}

// ========================================== SAVE TOOLBAR HANDLER -END

// ========================= BLOTTER HANDLER -START

function setBlotter(event) {

	evnt = event || window.event;
	removeOtherHandlers();
	$('#blotter-icon').addClass('selectedStamp');
	// $('.blotter-icon').addClass('selected');//Commented by CPNVP 12/29/2014
	// PR 617 - when signtuer was getting picked up signature tool was getting
	// distorted
	var orgImage = null;
	orgImage = $('#blotter_1');
	orgImage.css({
		'display': 'block'
	});

	setTimeout(function () {
		blotterHeight = blotterWidth = Number(170 / 7);
		var pnt = getCordinatePoint(evnt);

		if (blotterImage == null) {
			blotterImage = $('<img>').attr({
				'id': 'blotter-image',
				'src': contextPath + '/webiinkit/images/blotter.gif',
				'class': 'blotter',
				'onclick': 'addBlotter(event)',
				'ontouchend': 'addBlotter(event)'
			}).css({
				'position': 'absolute',
				'height': Math.floor(blotterHeight * stampScale * scale),
				'width': Math.floor(blotterWidth * stampScale * scale),
				'background-color': 'rgba(255,255,255,1)',
				'border': 'rgb(0,0,255) solid 1px',
				//Commented By CPNVP 08/13/2015 PR 485 - START
				//'left': pnt.x - 5,
				//'top': pnt.y - 5,
				//Commented By CPNVP 08/13/2015 PR 485 - END
				//CPNVP 08/13/2015 PR 485 - START
				'left': pnt.x - popUpDivLeft - 5,
				'top': pnt.y - popUpDivTop - 5,
				'z-index': 1000,
				//CPNVP 08/13/2015 PR 485 - END
				'display': 'block'
			});
			if (!isIpad) {
				$('#viewer').bind('mousemove', moveStamp);
				blotterImage.bind('mousemove', moveStamp);
			} else {
				$('#viewer').on('touchstart', moveStamp);
				blotterImage.on('touchstart', moveStamp);

				$('#viewer').on('touchmove', moveStamp);
				blotterImage.on('touchmove', moveStamp);

				$('#viewer').on('touchend', addBlotter);
			}
			$('body').append(blotterImage);
			blotterImage.css({
				'display': 'none',
				'left': '-300px',
				'top': '-300px',
			});
		}

	}, 80);
}

function addBlotter(event) {
	var evnt = event || window.event;
	var pnt = getTouchEndCordinatePoints(evnt);
	//Commented By CPNVP 08/13/2015 PR 485 - START
	/*addBlotterWithStyle(PDFView.page, pnt.x - marginLeft, pnt.y - marginTop,
			stampScale);
	addBlotter2Array('blotter_' + count, pnt.x - marginLeft, pnt.y - marginTop,
			PDFView.page, true);*/
	//Commented By CPNVP 08/13/2015 PR 485 - END
	//CPNVP 08/13/2015 PR 485 - START
	var x = pnt.x - marginLeft - 35;
	var scrollValue = 0;
	//scrollValue = $('.pageMain').scrollTop();
	var pageNo = PDFView.page;
	scrollValue = $("#pageContainerMain" + (pageNo)).scrollTop();
	var y = 0;
	//var popUpDivTop = $('#popUpDivContainer').offset().top;
	if (PDFView.page > 1) {
		y = (parseInt(scrollValue) + parseInt(pnt.y)) - popUpDivTop;
	} 
	//y = (parseInt(scrollValue) + parseInt(pnt.y)) - popUpDivTop - 27; //Commented for PR 994
	y = (parseInt(scrollValue) + parseInt(pnt.y)) - popUpDivTop - 35; //PR 994
	
	//Commented for PR 994 - START
	/* addBlotterWithStyle(PDFView.page, x, y,
		stampScale);
	addBlotter2Array('blotter_' + count, x, y,
		PDFView.page, true); */
	//CPNVP 08/13/2015 PR 485 - END
	//count++;
	//Commented for PR 994 - END

	//PR 994 - START
	restrictBlotterOnStamp = false;
	checkForNearestCoordinateOfStamp(x, y);
	if(!restrictBlotterOnStamp) { 
		addBlotterWithStyle(PDFView.page, x, y,
			stampScale);
		addBlotter2Array('blotter_' + count, x, y,
			PDFView.page, true);
		count++;
	}
	//PR 994 - END
}

function addBlotterWithStyle(pageNo, left, top, stScale) {

	var blot = $('<img>').attr({
		'class': 'blotterAdded',
		'src': contextPath + '/webiinkit/images/blotter.gif',
		'id': 'blotter_' + count
	}).css({
		'position': 'absolute',
		'height': (blotterHeight * scale * stScale) + 'px',
		'width': (blotterWidth * scale * stScale) + 'px',
		'left': left,
		'top': top,
		'z-index': '200',
		'border': 'transparent solid 1px'
	});
	$('#pageContainer' + (Number(pageNo))).children('.textLayer').append(blot);

}

function addBlotter2Array(stampName, xCord, yCord, pageNo, isAdded) {
	var pgNo = 0;
	if (document.getElementById("enteredPageNo").value != null
		&& document.getElementById("enteredPageNo").value != ''
		&& document.getElementById("enteredPageNo").value != 0)
		pgNo = document.getElementById("enteredPageNo").value;
	else
		pgNo = pageNo;
	var blott = new Stamp(stampName, pgNo, xCord, yCord, scale, isAdded,
		contextPath + '/webiinkit/images/blotter.gif', blotterHeight * scale * stampScale,
		blotterWidth * scale * stampScale, null, stampScale);
	if (blotterOnCanvas == null || blotterOnCanvas == undefined
		|| blotterOnCanvas.length == 0) {
		blotterOnCanvas[0] = blott;
	} else {
		blotterOnCanvas.push(blott);
	}
}

// ========================= Blotter Handler -End

// **************************** Upload image/icon - Starts
// function uploadImageOrLogo(event) {
// 	$('#uploadImageOrLogoModal').appendTo('body').modal('show');
// }
// **************************** Upload image/icon - End

// ====================================== ON DOCUMENT LOAD -START
// $(document)
// 	.ready(
function documentLoaded() {
	var isInputField = false;
	$(document)
		.on(
			'keydown',
			function (e) {
				e = e || window.event;
				if (e.keyCode === 27 && !e.shiftKey
					&& !e.ctrlKey) { // ESC
					removeOtherHandlers();
				}
				if (e.keyCode == 32) {
					if ((e.target != '[object HTMLTextAreaElement]')
						&& (e.target != '[object HTMLInputElement]')) {
						e.preventDefault();
					}
				}
			});
	checkIfDesktop();
	$('#mousePoistion').css({
		'left': $(window).width() - 110,
		'top': $(window).height() - 30
	});
	/*$('footer').css({
		'top' : ($(window).height() - footerHeight) + 'px'
	});*/ //Commented By CPNVP 08/13/2015 PR 485
	footerTop = ($(window).height() - footerHeight);
	$('input[type=\'text\']').attr('spellcheck', 'true');
	$('textarea').attr('spellcheck', 'true');
	// $('title').html('IInkIt'); //Commented By CPNVP
	// 07/27/2015 PR 485
	setSelectedPosition();

	function setSelectedPosition() {
		$(".selected-fonts").text(
			$('#font option:selected').text());
		$(".selected-fonts1").text(
			$('#font-size option:selected').text());
		$(".selected-fonts2").text(
			$('#scaleSelect option:selected').text());
		$(".selected-fonts3").text(
			$('#stampZoom option:selected').text());
	}
	if (kwd != null && kwd != '' && kwd != undefined) {
	} else {
		$('#securePdf').on('click', function () {
			if (!isDialogShowing) {
				generateSecurePdfDialog();
				showDialog();
			} else {
				showCloseDialogMessage();
			}
		});
	}
	createFontCombo();
	//CPNVP 11/23/2016 PR 705 - START
	if ($('.manageSignature').css('display') == 'block') {
		$('#stampToolBarUL').removeAttr('style');
		$('#stampToolBarUL').css(
			{
				'width': '106px',
				'margin-top': '55px',
				'z-index': '1000',
				'height': ($(window).height() - marginTop - footerHeight + 4 - 200) + 'px'
			}).attr('class', 'stamp-ul');
		$('.manageSignature').css({
			// 'margin-top': '36px',
			'position': 'absolute',
			'z-index': 110,
			'display': 'block'

		});
	}
	else {
		$('.extraSpaceWithBorder').css({
			'display': 'none'
		});
	}
	//CPNVP 11/23/2016 PR 705 - END
}

function createFontCombo() {
	var d = new Detector();

	for (var i = 0; i < WindowsStandard.length; i++) {
		var option = null;
		if (d.detect(WindowsStandard[i])) {
			option = $('<option>').attr({
				'value': WindowsStandard[i],
				'title': WindowsStandard[i],
				'class': 'fontOption'
			}).html(WindowsStandard[i]);
			if (i == 4) {
				option.attr({
					'disabled': 'disabled',
					'class': 'seperator4Dropdown'
				});
			}
			$('#font').append(option);
		}
	}
}

// ====================================== ON DOCUMENT LOAD -END
// ======================================FREE TEXT HANDLER -Start

function freeTextClick() {
	freeTextOnInit(); //PR 994
	removeOtherHandlers();
	$(this).addClass('select-font-style');

	//Commented for PR 994 - START
	/* $('.freeTextDiv').css({
		'display': 'block'
	}); */
	//Commented for PR 994 - END

	$('#scrollDiv').scrollTop(0);

	//drawOnCanvas(); //Commented for PR 994

	//PR 994 - START
	setTimeout(() => {
		drawOnCanvas();
	}, 200);
	//PR 994 - END
}

//Commented for PR 994 - START
// function drawOnCanvas() {
// 	$(this).removeClass('select-font-style');
// 	var rect = {};
// 	var drag = false;
// 	var scroolTopValue = $('body').scrollTop();
// 	var ua = window.navigator.userAgent;
// 	var msie = ua.indexOf("MSIE ");
// 	var upperLayer;
// 	if (msie > 0) {
// 		upperLayer = $('.canvasWrapper');
// 	} else {
// 		upperLayer = $('.pdfpage');
// 	}
// 	upperLayer.css('z-index', '20000');
// 	if (isIpad)
// 		upperLayer.on('touchstart.freeTextDraw', mouseDown);
// 	else
// 		upperLayer.on('mousedown.freeTextDraw', mouseDown);

// 	if (isIpad)
// 		upperLayer.on('touchend.freeTextDraw', mouseUp);
// 	else
// 		upperLayer.on('mouseup.freeTextDraw', mouseUp);

// 	upperLayer.css({
// 		'cursor': 'crosshair'
// 	});
// 	function mouseDown(e) {
// 		e.preventDefault();
// 		e = e || window.event;
// 		upperLayer.on('mousemove.freeTextDraw', mouseMove);
// 		upperLayer.on('touchmove.freeTextDraw', mouseMove);
// 		var pnt = getCordinatePoint(e);
// 		rect.startX = pnt.x;
// 		if (isIpad)
// 			rect.startY = pnt.y - scroolTopValue;
// 		else
// 			rect.startY = pnt.y;
// 		drag = true;
// 		$(this).removeClass('select-font-style');
// 	}
// 	function mouseUp() {
// 		drag = false;
// 		upperLayer.off('.freeTextDraw');
// 		upperLayer.off('.freeTextDraw');
// 		addFreeTextField();
// 		upperLayer.css({
// 			'cursor': 'default'
// 		});
// 	}
// 	function mouseMove(e) {
// 		e = e || window.event;
// 		if (isIpad)
// 			e.preventDefault();
// 		var pnt = getCordinatePoint(e);
// 		if (drag) {
// 			rect.w = (pnt.x) - rect.startX - 2;
// 			if (isIpad)
// 				rect.h = (pnt.y - scroolTopValue) - rect.startY - 2;
// 			else
// 				rect.h = (pnt.y) - rect.startY - 2;

// 			draw();
// 		}
// 	}
// 	function draw() {
// 		//CPNVP 08/13/2015 PR 485 - START
// 		var x = rect.startX - popUpDivLeft;
// 		var y = rect.startY - popUpDivTop;
// 		//CPNVP 08/13/2015 PR 485 - END
// 		$('.freeTextDiv').css({
// 			//Commented By CPNVP 08/13/2015 PR 485 - START
// 			/*'left' : rect.startX,
// 			'top' : rect.startY,*/
// 			//Commented By CPNVP 08/13/2015 PR 485 - END
// 			//CPNVP 08/13/2015 PR 485 - START
// 			'left': x,
// 			'top': y,
// 			//CPNVP 08/13/2015 PR 485 - END
// 			'height': rect.h,
// 			'width': rect.w,
// 			'border': '#000000 solid 1px'
// 		});
// 	}
// 	function addFreeTextField() {
// 		setTimeout(() => {
// 			$('.freeTextField').on('blur', blurHandler4FreeText);
// 		}, 200);
// 		$('.freeTextField').on('focus', function () {
// 			$(this).css({
// 				'border': 'rgb(255,0,0) solid 1px'
// 			});
// 		});
// 		//CPNVP 08/13/2015 PR 485 - START		
// 		currentScrollTop = 0;
// 		//currentScrollTop = $('.pageMain').scrollTop();
// 		var pageNo = PDFView.page;
// 		currentScrollTop = $("#pageContainerMain" + (pageNo)).scrollTop();
// 		//CPNVP 08/13/2015 PR 485 - END
// 		/*var textTop = rect.startY - marginTop;
// 		if (isIpad) {
// 			textTop = rect.startY - marginTop + scroolTopValue;
// 		}*/
// 		//CPNVP 08/13/2015 PR 485 - START
// 		var x = rect.startX - marginLeft - popUpDivLeft - 35;
// 		//var y = (parseInt(currentScrollTop) + parseInt(rect.startY)) - popUpDivTop - 42;// Commented for PR 994
// 		var y = (parseInt(currentScrollTop) + parseInt(rect.startY)) - popUpDivTop - 54; //PR 994
// 		//CPNVP 08/13/2015 PR 485 - END

// 		var new_offset = { top: 0, left: 0 }; //PR 957

// 		var textField = $('<textarea>').attr({
// 			'id': 'freeTF_' + count,
// 			'class': 'freeTextField',
// 			'spellcheck': 'true'
// 		}).css({
// 			//Commented By CPNVP 08/13/2015 PR 485 - START
// 			/*'left' : (rect.startX - marginLeft),
// 			'top' : textTop,*/
// 			//Commented By CPNVP 08/13/2015 PR 485 - END
// 			//CPNVP 08/13/2015 PR 485 - START
// 			'left': x,
// 			'top': y,
// 			//CPNVP 08/13/2015 PR 485 - END
// 			'padding':3, //PR 994
// 			'height': Math.abs(rect.h),
// 			'width': Math.abs(rect.w),
// 			'resize': 'both'
// 		})//PR 957 - START
// 			.draggable({
// 				cancel: "text",
// 				start: function () {
// 					$('#textarea').focus();
// 				},
// 				stop: function () {
// 					$('#textarea').focus();
// 				}
// 			}).resizable()
// 			.css({
// 				'position': 'absolute',
// 				'border-color': 'black',
// 				'border-width': '1px',
// 				'border-style': 'solid'
// 			})
// 			.offset(new_offset)
// 			.appendTo('body');
// 		$('.pdfpage').droppable({
// 			drop: function (event, ui) {
// 				dropHandler4FreeText(ui.draggable[0]);
// 			}
// 		});
// 		// $('body').on('click', function (event) {
// 		// 	console.log("event 1 ========> " + event);
// 		// 	$(textField).draggable({ cancel: "text" });
// 		// });
// 		// $('body').on('focusin', '#freeTF_' + count, function (event) {
// 		// 	console.log("event 2 ========> " + event);
// 		// 	$(textField).draggable({ disabled: true });
// 		// });

// 		//PR 957 - END
// 		setFontProperties(textField);
// 		$('#pageContainer' + PDFView.page).children('.textLayer').children(
// 			'.pdfpage').append(textField);
// 		$('.freeTextDiv').html('').css({
// 			'display': 'none',
// 			'border': 'transparent solid 2px'
// 		});
// 		count++;
// 		textField.focus();
// 		$('#freeText').removeClass('select-font-style');
// 		currentFreeText = textField;
// 		upperLayer.css('z-index', '250');
// 	}
// }
//Commented for PR 994 - END

function blurHandler4FreeText() {
	console.log("blurHandler4FreeText");
	
	if ($(this) != undefined || $(this) != null) {
		currentFreeText = null;
		var isAdded2Array = false;

		if ($(this).val().trim() == '' || $(this).val().trim() == undefined
			|| $(this).val().trim() == null) {
			
			 $(this).css({
				'border': 'transparent solid 1px'
			});

			$(this).remove(); //PR 994 

			//return;
		} 
		for (var i = 0; i < freeTextOnCanvas.length; i++) {
			if ($(this).attr('id') == freeTextOnCanvas[i].value.id) {
				freeTextOnCanvas[i].value.text = $(this).val();
				isAdded2Array = true;
			}
		}
	
		// if (!isAdded2Array) { //Commented for PR 957 
		add2FreeTextArray($(this));
		// } //Commented for PR 957 
		 $(this).css({
			'border': 'transparent solid 1px'
		}); 
			
		//Commented for PR 957 - START
		//.attr({
		// 'readonly': 'readonly'
		//});
		//Commented for PR 957 - END
	
		//$('.freeTextField').off('focus'); //Commented for PR 994
			
		// $( 'textarea' ).resizable( 'disable' );
		$(this).removeAttr("autofocus");
		freeTextClick(); //PR 994
	}
}

//PR 957 - START
function dropHandler4FreeText(element) {
	console.log("dropHandler4FreeText");
	if ($(element) != undefined || $(element) != null) {
		if ($(element).val().trim() == '' || $(element).val().trim() == undefined
			|| $(element).val().trim() == null) {
			$(element).css({
				'border': 'transparent solid 1px'
			});
			return;
		}
		currentFreeText = null;
		var isAdded2Array = false;
		for (var i = 0; i < freeTextOnCanvas.length; i++) {
			if ($(element).attr('id') == freeTextOnCanvas[i].value.id) {
				freeTextOnCanvas[i].value.text = $(element).val();
				isAdded2Array = true;
			}
		}

		add2FreeTextArray($(element));

		$(element).css({
			'border': 'transparent solid 1px'
		})
		$('.freeTextField').off('focus');
		$(element).removeAttr("autofocus");
	}
}
//PR 957 - END

function add2FreeTextArray(textField) {
	
	var fontWeightValue = null;
	// FOR TEXT VALUE
	if (isNaN(textField.css('font-weight'))) {
		fontWeightValue = textField.css('font-weight').trim().toLowerCase();
	} else {
		// FOR NUMERIC VALUE
		fontWeightValue = textField.css('font-weight');
	}
	if ((fontWeightValue != undefined && (fontWeightValue == 'bold' || fontWeightValue > 500))
		&& ((textField.css('font-style') != undefined && (textField.css(
			'font-style').toLowerCase() == 'italic')))) {
		fontStyleValue = 'bold italic';
	} else if ((fontWeightValue != undefined && (fontWeightValue == 'bold' || Number(fontWeightValue) > 500))) {
		fontStyleValue = 'bold';
	} else if ((textField.css('font-style') != undefined && textField.css(
		'font-style').toLowerCase() == 'italic')) {
		fontStyleValue = 'italic';
	} else if (textField.css('font-style') != undefined
		&& textField.css('font-style').toLowerCase() == 'normal') {
		fontStyleValue = 'regular';
	}
	var param = new ParamInfo('Free Text', textField.attr('id'), textField
		.val(), textField.css('left').replace('px', ''), textField.css(
			'top').replace('px', ''), 0, 0, null, textField.css('color'), Math
				.round((textField.css('font-size').replace('px', '')) / scale),
		fontStyleValue, textField.css('font-family'), 'Y', 'N', null, null,
		null, null, null);
	var freeText = '';
	if (document.getElementById("enteredPageNo").value != null
		&& document.getElementById("enteredPageNo").value != ''
		&& document.getElementById("enteredPageNo").value != 0)
		freeText = new FreeText(textField.attr('id'), textField.val(),
			textField.css('top').replace('px', ''), textField.css('left')
				.replace('px', ''), textField.height(), textField
					.width(), true, document
						.getElementById("enteredPageNo").value, scale, param);
	else
		freeText = new FreeText(textField.attr('id'), textField.val(),
			textField.css('top').replace('px', ''), textField.css('left')
				.replace('px', ''), textField.height(), textField
					.width(), true, PDFView.page, scale, param);

	//PR 957 - START
	var obj = {
		id: textField.attr('id'),
		value: freeText
	};

	if (freeTextOnCanvas != null && freeTextOnCanvas != 'undefined') {

		var temp = freeTextOnCanvas.filter(single => single.id == textField.attr('id'));
		if (temp && temp.length > 0) {
			// id exists
			temp[0].value = freeText;
		} else {
			// id don't exists
			freeTextOnCanvas.push(obj);
		}

	}

	//PR 957 - END

	// freeTextOnCanvas.push(freeText); // Commented for PR 957
}

function addFreeTextWithStyle(id, text, left, top, w, h, page, param) {
	var textField = $('<textarea>').attr({
		'id': id,
		'class': 'freeTextField'
		//'readonly': 'readonly' //Commented for PR 957
	}).css({
		'left': left,
		'top': top,
		'height': h,
		//'width': w //Commented for PR 994
	})//PR 957 - START
		.draggable({
			// cancel: "text",
			start: function () {
				$('#textarea').focus();
			},
			stop: function () {
				$('#textarea').focus();
			}
		}).resizable()
		.css({
			'position': 'absolute',
			'border-color': 'black',
			'border-width': '1px',
			'border-style': 'solid'
		})
		.appendTo('body');
	$('.pdfpage').droppable({
		drop: function (event, ui) {
			dropHandler4FreeText(ui.draggable[0]);
		}
	});

	//PR 957 - END;
	textField.html(text);

	setFontPropertiesWithParam(textField, param);
	$('#pageContainer' + page).children('.textLayer').children('.pdfpage')
		.append(textField);
}

// ======================================FREE TEXT HANDLER -End

// ======================================FOOTER TOOLBAR HANDLER -START
function stampZoomChange(event) {
	stampScale = event.target.value / 100;
	$(".selected-fonts3").text($('#stampZoom option:selected').text());
	updateStampSizeIfVisible();
}

// ======================================FOOTER TOOLBAR HANDLER -END
// ======================================FONT TOOLBAR HANDLER -Start

function fontChange(el) {
	fontType = $(el).val();
	$(".selected-fonts").html($(el).val());
	//PR 957 - START
	$('.freeTextField').on('focus', function () {
		setFontProperties($(this));
	});
	//PR 957 - END
}

function fontSizeChange(el) {
	fontSize = $(el).val();
	$(".selected-fonts1").html($(el).val());
	//PR 957 - START
	$('.freeTextField').on('focus', function () {
		setFontProperties($(this));
	});
	//PR 957 - END
}

function fontStyleClick(event) {

	removeOtherHandlers(); //PR 994

	if ($(event).attr('id').toLowerCase().trim() == 'regular') {
		$('#bold').removeClass('select-font-style');
		$('#italic').removeClass('select-font-style');
		$('#regular').removeClass('select-font-style');
		$(event).addClass('select-font-style');
		fontStyle = "regular";
	} else {
		if ($(event).attr('id').toLowerCase().trim() == 'bold') {
			if (isContainingClass('#bold', 'select-font-style')) {
				$(event).removeClass('select-font-style');
				isBoldSelected = false;
			} else {
				$(event).addClass('select-font-style');
				isBoldSelected = true;
				;
			}
		} else if ($(event).attr('id').toLowerCase().trim() == 'italic') {
			if (isContainingClass('#italic', 'select-font-style')) {
				$(event).removeClass('select-font-style');
				isItalicSelected = false;
			} else {
				$(event).addClass('select-font-style');
				isItalicSelected = true;
			}
		}
		$('#regular').removeClass('select-font-style');
		if (isItalicSelected && isBoldSelected) {
			fontStyle = "bold italic";
		} else if (isItalicSelected) {
			fontStyle = "italic";
		} else if (isBoldSelected) {
			fontStyle = "bold";
		} else {
			$('#regular').addClass('select-font-style');
			fontStyle = "regular";
		}
	}
	function isContainingClass(element, classVal) {
		if ($(element).hasClass(classVal)) {
			return true;
		} else {
			return false;
		}
	}

	//PR 957 - START
	$('.freeTextField').on('focus', function () {
		setFontProperties($(this));
	});
	//PR 957 - END
}

function colorChooserClick() {
	if (!isDialogShowing)
		generateColorChooserDialog();
	else
		showCloseDialogMessage();
}

$('#fontToolbar  *').click(function () {
	if (currentFreeText != null) {
		setFontProperties(currentFreeText);
	}
});

function setFontProperties(textField) {
	// var cursorPosition = $(textField).prop("selectionStart");
	setTimeout(function () {

		textField.css({
			'font-size': Math.round(fontSize * scale),
			'color': fontColor,
			'font-family': fontType
		});

		if (fontStyle.toLowerCase().trim() == 'bold') {
			textField.css({
				'font-weight': 'bold',
				'font-style': 'normal'
			});
		} else if (fontStyle.toLowerCase().trim() == 'regular') {
			textField.css({
				'font-weight': 'normal',
				'font-style': 'normal'
			});
		} else if (fontStyle.toLowerCase().trim() == 'italic') {
			textField.css({
				'font-weight': 'normal',
				'font-style': 'italic'
			});
		} else if (fontStyle.toLowerCase().trim() == 'bold italic') {
			textField.css({
				'font-weight': 'bold',
				'font-style': 'italic'
			});
		}
	}, 0);
}

function setFontPropertiesWithParam(textField, param) {

	if (param.paramFontStyle.toLowerCase().trim() == 'bold') {
		textField.css({
			'font-weight': 'bold',
			'font-style': 'normal'
		});
	} else if (param.paramFontStyle.toLowerCase().trim() == 'regular') {
		textField.css({
			'font-weight': 'normal',
			'font-style': 'normal'
		});
	} else if (param.paramFontStyle.toLowerCase().trim() == 'italic') {
		textField.css({
			'font-weight': 'normal',
			'font-style': 'italic'
		});
	} else if (param.paramFontStyle.toLowerCase().trim() == 'bold italic') {
		textField.css({
			'font-weight': 'bold',
			'font-style': 'italic'
		});
	}
	textField.css({
		'font-size': Math.round(param.paramFontSize * scale),
		'color': param.paramColor,
		'font-family': param.paramFontType
	});
}

function ChangeColors(r, g, b) {
	var tempFontColor = 'rgb(' + r + ',' + g + ',' + b + ')';
	$('.pickedColorDiv').css({
		'background-color': tempFontColor
	});
	$('.sampleText').css({
		'color': tempFontColor
	});
}

function colorOkClick(event) {
	fontColor = $('.sampleText').css('color');
	if (currentFreeText != null || currentFreeText != undefined)
		currentFreeText.css('color', fontColor);
	$('#mainParamContainer').width('455px');
	cancel(event);
}

// ======================================FONT TOOLBAR HANDLER -End
// ==================================== SIGNATURE HANDLER -START

function addSignatureHandler(event) {
	var evnt = event || window.event;
	removeOtherHandlers();
	if (signatureImage == null || signatureImage == undefined) {
		signatureImage = $('<img>');
		signatureImage.attr({
			'id': 'signPen',
			'src': contextPath + '/wbiinkit/images/signature_pen.png',
			'class': 'sign-pen'
		});
		$('#orgImages').append(signatureImage);
		$('.sign-pen').css({
			'height': Number(signatureHeight * scale * stampScale),
			'width': Number(signatureWidth * scale * stampScale)
		});
		$('.sign-pen').on('mousemove.signature', moveSignature);
		$('#viewer').on('mousemove.signature', moveSignature);
		if (isIpad) {
			$('.sign-pen').on('touchmove.signature', moveSignature);
			$('#viewer').on('touchmove.signature', moveSignature);

			$('.sign-pen').on('touchend.signature', moveSignature);
			$('#viewer').on('touchend.signature', moveSignature);
		}
		$('.signature').children('img').addClass('selectedStamp');
		var pnt = getTouchEndCordinatePoints(evnt);
		$('.sign-pen').css({
			'left': pnt.x,
			'top': pnt.y
		});
		$('#viewer').on('touchend.signature', showCanvasForSignature);

		if (isIpad)
			$('.sign-pen').on('touchend.signature', showCanvasForSignature);
		else
			$('.sign-pen').on('click.signature', showCanvasForSignature);
	}
}

function moveSignature(event) {
	var evnt = event || window.event;
	if (isIpad) {
		evnt.preventDefault();
	}
	if (signatureImage != null) {
		image = signatureImage;
		changeCords(evnt);
	} else {
		return;
	}
	var pnt = getCordinatePoint(evnt);
	if (isIpad) {
		var ipadScrollValue = $('body').scrollTop();
		pnt.y = pnt.y - ipadScrollValue;
	}
	image.css({
		//Commented By CPNVP 08/13/2015 PR 485 - START
		/*'left' : pnt.x,
		'top' : pnt.y*/
		//Commented By CPNVP 08/13/2015 PR 485 - END
		//CPNVP 08/13/2015 PR 485 - START
		'left': pnt.x - popUpDivLeft,
		'top': pnt.y - popUpDivTop
		//CPNVP 08/13/2015 PR 485 - END
	});
	if (image.css('left') != undefined)

		if ((Number(image.css('left').replace('px', '')) > (Number($(
			'.textLayer').width())
			+ marginLeft - 10))
			|| (Number(image.css('left').replace('px', '')) < 42)
			|| (Number(image.css('top').replace('px', '')) < 32)) {

			image.css('display', 'none');
		} else {
			image.css('display', 'block');
		}
	if ((Number(image.css('left').replace('px', '')) < (Number(marginLeft)))
		|| (Number(image.css('left').replace('px', '')) > (Number($(
			'.textLayer').width()) + marginLeft))) {
		hideMousePosition();
	} else {
		showMousePosition();
	}
	evnt.stopPropagation();
}

function showCanvasForSignature() {
	signatureX = $('.sign-pen').css('left').replace('px', '');
	signatureY = $('.sign-pen').css('top').replace('px', '');
	if (isIpad) {
		var ipadScrollValue = $('body').scrollTop();
		signatureX = $('.sign-pen').css('left').replace('px', '');
		signatureY = Number($('.sign-pen').css('top').replace('px', ''))
			+ Number(ipadScrollValue);
		$('.sign-pen').off('.signature');
		$('.sign-pen').off('.signature');
		$('#viewer').off('.signature');
	}
	$('.signature').children('img').removeClass('selectedStamp');
	$('#viewer').off('.signature');
	showSignaturePad();
}

function addSignatureToArray(top, left, src) {
	if (src != null || src != undefined) {
		var signature = new Stamp('sign_' + count, PDFView.page, left, top,
			scale, true, src, Number(signatureHeight * scale * stampScale),
			Number(signatureWidth * scale * stampScale), null, stampScale);
		signatureOnCanvas.push(signature);
	}
}

// ============================== SIGNATURE HANDLER -END

// ============ Header & Footer position Handler- Start
//Commented By CPNVP 08/13/2015 PR 485 - START
/*window.onresize = function() {
	$('footer').css({
		'top' : ($(window).height() - footerHeight) + 'px'
	});
	$('header').css({
		'top' : '0'
	});
};*/
//Commented By CPNVP 08/13/2015 PR 485 - END
// ============ Header & Footer position Handler- End

// ============ Page scrollbar Handler - Start

var mousewheelevt = (/Firefox/i.test(navigator.userAgent)) ? "DOMMouseScroll"
	: "mousewheel"; // FF doesn't recognize mousewheel as of FF3.x
var wheelMoveCount = 0;
if (window.attachEvent) // if IE (and Opera depending on user setting)
	window.attachEvent("on" + mousewheelevt, function (e) {
		var evt = window.event || e; // equalize event object
		var delta = evt.detail ? evt.detail * (-120) : evt.wheelDelta;
		if (delta > 0) {
			$('#scrollDiv').scrollTop(
				Number($('#scrollDiv').scrollTop()) + Number(-40));
		} else {
			$('#scrollDiv').scrollTop(
				Number($('#scrollDiv').scrollTop()) + Number(40));
		}
	});
else if (window.addEventListener) // WC3 browsers
	window.addEventListener(mousewheelevt, function (e) {
		var evt = e || window.event; // equalize event object
		var delta = evt.detail ? evt.detail * (-120) : evt.wheelDelta;
		if (!evt.ctrlKey) {
			if (delta > 0) {
				$('#scrollDiv').scrollTop(
					Number($('#scrollDiv').scrollTop()) + Number(-40));

			} else {
				$('#scrollDiv').scrollTop(
					Number($('#scrollDiv').scrollTop()) + Number(40));
			}
		} else {
			evt.preventDefault();
		}
	}, false);

$('#scrollDiv').scroll(
	function (e) {

		/*$('#viewerContainer').css('top',
				'-' + Number($('#scrollDiv').scrollTop()) + 'px');*/ //Commented By CPNVP 08/13/2015 PR 485

		currentScrollTop = (Number($('#viewerContainer').css('top')
			.replace('px', '')));
		// marginTop = pageMarginTop+ currentScrollTop;//Commented by CPNVP
		// PR 617 - signature was not coming on right position
		marginTop = 33 + pageMarginTop + currentScrollTop;// Added by
		// CPNVP PR 617
		// - signature
		// was not
		// coming on
		// right
		// position
	});

// ============ Page scrollbar Handler - End
// ========================= HANDLE I-PAD ORIENTATION -START
document.addEventListener("orientationchange", handleOrientation);

function handleOrientation(e) {
	var orientation = window.orientation;
	getPageMargin(scale);
	if (orientation != 90 && orientation != -90) {
		$('.page').css('margin-left', marginLeft + 'px');
		$('.stamp-ul').css('height',
			($(window).height() - marginTop - footerHeight + 4) + 'px');
	} else {
		$('.page').css('margin-left', marginLeft + 'px');
		$('.stamp-ul').css('height',
			($(window).height() - marginTop - footerHeight + 4) + 'px');
	}

}

function loadStampData(val) {
	//var val = document.getElementById('uniqueId').value; //Commented By CPNVP 08/13/2015 PR 485
	var headers = {};
	if (localStorage.getItem('accessToken')) {
		headers = {
			Authorization: 'Bearer ' + localStorage.getItem('accessToken')
		};
	}
	$.ajax({
		// url: reqUrl + '/webiinkit/CallerServlet',
		url: reqUrl + 'ownerDocument/getStamps',
		data: {
			requestFor: 'getStamps',
			uniqueId: val
		},
		headers: headers,
		//dataType: 'JSON',
		success: function (allData) {
			if (allData && allData != null) {
				createStampInfoArray(JSON.parse(allData));
			} else {
				createStampInfoArray([]);
			}
		},
		error: function (error) {
			/*alert('-------- INSIDE  loadStampData ---error----+-'
					+ error.toString());*/
			alert('Error occurred while load the stamps.');
		},

	});
}
function createStampInfoArray(tempStampsInfoArray) {
	stampsInfoarray = [];
	for (var i = 0; i < tempStampsInfoArray.length; i++) {

		if (tempStampsInfoArray[i].imageId == null
			|| tempStampsInfoArray[i].imageId == undefined) {
			tempStampsInfoArray[i].imageId = "";
		}

		if (tempStampsInfoArray[i].imageData == null
			|| tempStampsInfoArray[i].imageData == undefined) {
			tempStampsInfoArray[i].imageData = "";
		}

		stampsInfoarray.push(tempStampsInfoArray[i]);
	}
	addStampToolbar();
}

function closeImage(id, img) {
	if (confirm('This signature will be deleted permanently. Are you sure you want to delete this signature?')) {
		var val = document.getElementById('uniqueId').value;
		console.log('stampinfoarray');
		console.log(stampsInfoarray);
		console.log(id);
		if (stampsInfoarray) {
			stampsInfoarray = stampsInfoarray.filter(stamp => {
				return stamp.imageId !== id
			});
		}
		var imageId = ('IInkIt_' + id + '_div').replace('%20', '_')
			.replace(' ', '_').replace(/ /g, '_');// CPNVP
		$('#' + imageId).remove();
		refreshStampToolbar();
		$.ajax({
			url: reqUrl + 'ownerDocument/removeSignature/' + mainRequestId + '/' + id,
			success: function (allData) {
				// if (allData != null) {
				// var imageId =
				// ('IInkIt_'+id+'_div').replace('%20','_').replace(' ','_');
				// //Commented By CPNVP 07/27/2015 PR 485
				// var imageId = ('IInkIt_' + id + '_div').replace('%20', '_')
				//	.replace(' ', '_').replace(/ /g, '_');// CPNVP
				// 07/27/2015 PR
				// 485
				//var imgDiv = document.getElementById(imageId);
				// imgDiv.remove();//Commented by CPNVP 03/24/2015 PR 617
				// $('#' + imageId).remove();// CPNVP 03/24/2015 PR 617
				// }
			},
			error: function (error) {
				/*alert('-------- INSIDE  loadStampData ---error----+-'
						+ error.toString());*/
				alert('Error occurred while remove the signature.');
			},
		});
	}
}
function signatureFileClicked() {
	PDFView.pdfDocument = false;
}

function signatureFileChange(event) {
	PDFView.pdfDocument = false;

	var file = document.getElementById('signatureFile');
	var fileVal = file.value.split(".").pop().toLowerCase();
	document.getElementById('sign2').value = '';// CPNVP
	// 03/24/2015 PR
	// 617
	if (fileVal == 'jpg' || fileVal == 'png'
		|| fileVal == 'gif') {
		// var formData = new FormData($("#imageForm")[0]);
		// $.ajax({
		// 	url: reqUrl + 'ownerDocument/addSignature/' + requestId,
		// 	type: 'POST',
		// 	data: formData,
		// 	processData: false,
		// 	contentType: false,
		// 	success: function (allData) {
		// 		if (allData != null) {
		// 			createImageInfoArray(allData);
		// 			/* CPNVP 03/23/2015 PR 617 - START */
		// 			document
		// 				.getElementById('signatureFile').value = '';
		// 			document.getElementById('div').style.display = 'none';
		// 			/* CPNVP 03/23/2015 PR 617 - END */
		// 		}
		// 	},
		// 	error: function (error) {
		// 		/*alert('-------- INSIDE  loadStampData ---error----+-'
		// 				+ error.toString());*/
		// 		alert('Error occurred while upload the signature.');
		// 	},

		// });
		const fileReader = new FileReader();
		let base64Img = null;
		fileReader.readAsDataURL(file.files[0]);
		fileReader.onloadend = (e) => {
			base64Img = fileReader.result;
			document.getElementById('sign2').value = base64Img;
			uploadImage();
		};
		// }else{//Commented by CPNVP 03/24/2015 PR 617
	} else if (fileVal != '') {// CPNVP 03/24/2015 PR 617
		alert('Please select only jpg / png / gif file.');
		document.getElementById('signatureFile').value = '';// CPNVP
		// 03/23/2015
		// PR
		// 617
	}
	PDFView.pdfDocument = true;
	return false;

}

function uploadImage() {

	var formData = new FormData($("#imageForm")[0]);
	$.ajax({
		// url: reqUrl + '/webiinkit/CallerServlet',
		url: reqUrl + 'ownerDocument/addSignature/' + mainRequestId,
		type: 'POST',
		data: formData,
		processData: false,
		contentType: false,
		success: function (allData) {
			if (allData != null) {
				createImageInfoArray(allData);
				/* CPNVP 03/23/2015 PR 617 - START */
				document.getElementById('div').style.display = 'block';
				/* CPNVP 03/23/2015 PR 617 - END */
			}
		},
		error: function (error) {
			/*alert('-------- INSIDE  loadStampData ---error----+-'
					+ error.toString());*/
			alert('Error occurred while upload the signature.');
		},
	});
}

function saveToSession() {
	var uniqueID = document.getElementById('uniqueId').value;
	$('#imageForm.requestFor').val('saveSignToMap');

	$("#imageForm input[name=uniqueId]").val(uniqueID);

	$('#imageForm').submit();
}

$("form#imageForm").submit(function (event) {

	// disable the default form submission
	event.preventDefault();
	// grab all form data

});
function createImageInfoArray(tempStampsInfoArray) {
	var imageArray = new Array();
	imageArray.push(tempStampsInfoArray[0]);

	var imageId = imageArray[0].imageId;

	var img = document.createElement("img");
	// img.id=('IInkIt_'+imageArray[0].imageId+'_icon').replace('%20','_').replace('
	// ','_');//Commented By CPNVP 07/27/2015 PR 485
	// img.src = imageArray[0].imageData.replace('%20','_').replace(' ','_');
	// //Commented By CPNVP 07/27/2015 PR 485
	img.id = ('IInkIt_' + imageArray[0].imageId + '_icon').replace('%20', '_')
		.replace(' ', '_').replace(/ /g, '_'); // CPNVP 07/27/2015 PR 485
	img.src = imageArray[0].imageData.replace('%20', '_').replace(' ', '_')
		.replace(/ /g, '_'); // CPNVP 07/27/2015 PR 485
	img.setAttribute("height", "30px");
	img.setAttribute("width", "30px");
	img.className = "otherImg";

	var closeImg = document.createElement("img");
	// closeImg.id=('IInkIt_'+imageArray[0].imageId+'_icon').replace('%20','_').replace('
	// ','_'); //Commented By CPNVP 07/27/2015 PR 485
	closeImg.id = ('IInkIt_' + imageArray[0].imageId + '_icon').replace('%20',
		'_').replace(' ', '_').replace(/ /g, '_'); // CPNVP 07/27/2015 PR
	// 485
	closeImg.src = contextPath + "/webiinkit/images/red-cross-icon.png";
	closeImg.setAttribute("height", "15px");
	closeImg.className = "closeImg";

	var div = document.createElement("div");
	// div.id=('IInkIt_'+imageArray[0].imageId+'_div').replace('%20','_').replace('
	// ','_');//Commented By CPNVP 07/27/2015 PR 485
	div.id = ('IInkIt_' + imageArray[0].imageId + '_div').replace('%20', '_')
		.replace(' ', '_').replace(/ /g, '_'); // CPNVP 07/27/2015 PR 485
	div.className = "imgForPopUpDiv";
	div.appendChild(img);
	div.appendChild(closeImg);

	document.getElementById('mainDiv').appendChild(div);

	closeImg.addEventListener("click", function () {
		closeImage(imageId, div);
	});
}

function saveImage(event, isForFuture) {

	var val = document.getElementById('uniqueId').value;
	$.ajax({
		// url: reqUrl + '/webiinkit/CallerServlet',
		url: reqUrl + 'ownerDocument/saveSignature/' + mainRequestId + '?isForFuture=' + isForFuture,
		data: {
			requestFor: 'saveSignature',
			uniqueId: val
		},
		beforeSend: function (request) {
			if (localStorage.getItem('accessToken')) {
				request.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('accessToken'));
			}
		},
		success: function (allData) {

			if (allData != null && allData == 'Error') {
				alert('Error occurred while save the signature.');
			} else {
				// CPNVP 03/24/2015 PR 617 - START
				if (Signature.socket != null) {
					Signature.socket.close();
				}
				// CPNVP 03/24/2015 PR 617 - END
				if (allData != null && allData != '' && allData != 'Error') {
					createSaveImageInfoArray(allData);
					cancel(event);
					// addStampToolbar();/*Commented by CPNVP 12/29/2014 PR
					// 617*/
					refreshStampToolbar();/* CPNVP 12/29/2014 PR 617 - START */
				} else if (allData != null && allData == '') {
					createSaveImageInfoArray(allData);
					cancel(event);
					refreshStampToolbar();
				}
			}
		},
		error: function (error) {
			alert('Error occurred while save the signature+-'
				+ error.toString());
		},
	});
}

function createSaveImageInfoArray(tempStampsInfoArray) {
	// stampsInfoarray.length = 0;
	for (var i = 0; i < tempStampsInfoArray.length; i++) {
		stampsInfoarray.push(tempStampsInfoArray[i]);
	}
}

function saveForFuture(event) {
	var val = document.getElementById('uniqueId').value;
	$.ajax({
		url: reqUrl + '/webiinkit/CallerServlet',
		data: {
			requestFor: 'saveForFuture',
			uniqueId: val
		},
		dataType: 'JSON',
		success: function (allData) {
			if (allData == 'Error') {
				alert('Error occurred while save the signature');
			} else {
				// CPNVP 03/24/2015 PR 617 - START
				if (Signature.socket != null) {
					Signature.socket.close();
				}
				// CPNVP 03/24/2015 PR 617 - END
				if (allData != null && allData != '' && allData != 'Error') {
					createSaveImageInfoArray(allData);
					cancel(event);
					// addStampToolbar();/*Commented by CPNVP 12/29/2014 PR
					// 617*/
					refreshStampToolbar();/* CPNVP 12/29/2014 PR 617 - START */
				} else if (allData != null && allData == '') {
					createSaveImageInfoArray(allData);
					cancel(event);
					refreshStampToolbar();
				}
			}
		},
		error: function (error) {
			alert('Error occurred while save the signature -+-'
				+ error.toString());
		},
	});
}
// CPNVP 07/27/2015 PR 485 - START
function reloadPDF() {
	var confirmValue = confirm('Do you want to discard all the changes and reload the original PDF file?');
	if (confirmValue) {
		PDFView.page = 1;
		stampsOnCanvas.length = 0;
		freeTextOnCanvas.length = 0;
		blotterOnCanvas.length = 0;
		$('.stampDiv').css('display', 'none');
		$('.freeTextField').css('display', 'none');
		$('.blotterAdded').css('display', 'none');
	} else {
		return false;
	}
}
// CPNVP 07/27/2015 PR 485 - END
//CPNVP 08/13/2015 PR 485 - START
function repairPdf() {
	showProgressBar();
	submitPdf('pdfStamps', 'requestFor', 'repairPdf', 'toolbarForm', 'passwordToolbar');
}

function generateDialogForRepairPdf() {

	var mainContainer = $('#mainParamContainer');

	var text = $('<label>').attr({
		'id': 'text',
		'name': 'text'
	});
	text.html('PDF has been repaired.' + '<br/>');
	text.css({
		'margin-left': '5px',
		'font-size': '14px',
		'line-height': '28px'
	});

	var text1 = $('<label>').attr({
		'id': 'text1',
		'name': 'text1'
	});

	var text1_1 = $('<label>').attr({
		'id': 'text1_1',
		'name': 'text1_1'
	});
	text1.html('It is recommended that the pdf be previewed using the Preview Button, before replacing the ');
	text1_1.html('original pdf.' + '<br/>');
	text1.css({
		'margin-left': '5px',
		'font-size': '14px'
	});

	text1_1.css({
		'margin-left': '5px',
		'font-size': '14px'
	});

	var text2 = $('<label>').attr({
		'id': 'text2',
		'name': 'text2',
		'line-height': '5px'
	});
	text2.html('Replace button will overwrite the original PDF with repaired PDF.');
	text2.css({
		'margin-left': '5px',
		'font-size': '14px',
		'line-height': '28px'
	});

	mainContainer.append(text);
	mainContainer.append(text1);
	mainContainer.append(text1_1);
	mainContainer.append(text2);

	var buttonDiv = $('<div>').css({
		'background-color': '#eeeeee',
		'margin-top': '20px',
		'padding': '5px 0px 5px 200px',
		'border-radius': '0px 0px 4px 4px',
		'-moz-border-radius': '0px 0px 4px 4px',
		'-webkit-border-radius': '0px 0px 4px 4px'
	});
	var replace = $('<input>').addClass('param-button').attr({
		'id': 'replace',
		'type': 'button',
		'value': 'Replace',
		'onclick': 'javascript : replacePdf();'
	});
	buttonDiv.append(replace);

	var preview = $('<input>').addClass('param-button').attr({
		'id': 'Preview',
		'type': 'button',
		'value': 'Preview',
		'onclick': 'javascript : previewRepairedPdf();'
	});
	buttonDiv.append(preview);

	var cancel = $('<input>').addClass('param-button').attr({
		'id': 'cancelForRepair',
		'type': 'button',
		'value': 'Cancel',
		'onclick': 'javascript : cancel(event);'
	});
	buttonDiv.append(cancel);

	mainContainer.append(buttonDiv);
	mainContainer.children('h1').prepend('I-Ink-It');
	mainContainer.css({
		'width': '620px',
		'margin-top': '-' + mainContainer.height() / 2 + 'px',
		'margin-left': '-' + mainContainer.width() / 2 + 'px'

	});

}
function previewRepairedPdf() {
	submitPdf('pdfStamps', 'requestFor', 'previewRepairedPdf', 'toolbarForm', 'passwordToolbar');
}
function replacePdf() {
	submitPdf('pdfStamps', 'requestFor', 'replacePdf', 'toolbarForm', 'passwordToolbar');
}

function generateDialogOnClosePopup() {
	if (document.getElementById('mainParamContainer').style.display == 'block') {
		alert('Please close already opened dialog.');
	} else {
		$('#mainParamContainer').css({
			'display': 'none'
		});
		var mainContainer = $('#mainParamContainer');

		var text = $('<label>').attr({
			'id': 'text',
			'name': 'text'
		});

		text.html('Do you want to save the changes to document?');
		text.css({
			'margin-top': '12px',
			'margin-left': '15px',
			'font-size': '16px',
			'display': 'flex' /*CPNVP 08/13/2015 PR 485*/
		});

		mainContainer.append(text);

		var buttonDiv = $('<div>').css({
			'background-color': '#eeeeee',
			'margin-top': '12px',
			'padding': '5px 0px 5px 165px',
			'border-radius': '0px 0px 4px 4px',
			'-moz-border-radius': '0px 0px 4px 4px',
			'-webkit-border-radius': '0px 0px 4px 4px'
		});
		var yes = $('<input>').addClass('param-button').attr({
			'id': 'yes',
			'type': 'button',
			'value': 'Yes',
			'onclick': 'javascript : savePDfOnPopUpClose();'
		});
		buttonDiv.append(yes);

		var no = $('<input>').addClass('param-button').attr({
			'id': 'no',
			'type': 'button',
			'value': 'No',
			'onclick': 'javascript : closePopup();'
		});
		buttonDiv.append(no);

		var cancel = $('<input>').addClass('param-button').attr({
			'id': 'cancelButton',
			'type': 'button',
			'value': 'Cancel',
			'onclick': 'javascript : cancel(event);'
		});
		buttonDiv.append(cancel);

		mainContainer.append(buttonDiv);
		mainContainer.children('h1').prepend('I-Ink-It');
		mainContainer.css({
			'display': 'block',
			'width': '550px',
			'top': '50%',
			'margin-top': '-' + mainContainer.height() / 2 + 'px',
			'margin-left': '-' + mainContainer.width() / 2 + 'px'
		});
	}
}
function closePopup() {
	//CPNVP 08/10/2016 PR 8210 - START
	var tranURL = document.URL;
	if (tranURL != null && tranURL != "" && tranURL.indexOf("IInkit.jsp") != -1) {
		closeWindowNoPrompt();
	}
	//CPNVP 08/10/2016 PR 8210 - END
	$('#popUpDivContainer').html(null);
	$('#popUpDivContainer').css({
		'display': 'none'
	});
	$('.param-overlay').css({
		'display': 'none'
	});
}
function savePDfOnPopUpClose() {
	showProgressBar();
	submitPdf('pdfStamps', 'requestFor', 'savePdf', 'toolbarForm', 'passwordToolbar');
}
//CPNVP 08/13/2015 PR 485 - END

//PR 994 - START
function freeTextOnInit() {
	console.log($(this));
	if ($(".freeTextField") != undefined || $(".freeTextField") != null) {
		console.log($(".freeTextField"));
		$(".freeTextField").css({
			'border': 'transparent solid 1px'
		});

		$(".freeTextField").removeAttr("autofocus");

	} 

}

function drawOnCanvas() {
	$(this).removeClass('select-font-style');
	var rect = {};
	var drag = false;
	var scroolTopValue = $('body').scrollTop();
	var ua = window.navigator.userAgent;
	var msie = ua.indexOf("MSIE ");
	var upperLayer;

	if (msie > 0) {
		upperLayer = $('.canvasWrapper');
	} else {
		upperLayer = $('.pdfpage');
	}
	upperLayer.css('z-index', '20000');
	if (isIpad)
		upperLayer.on('touchstart.freeTextDraw', mouseDown);
	else
		upperLayer.on('mousedown.freeTextDraw', mouseDown);

	if (isIpad)
		upperLayer.on('touchend.freeTextDraw', mouseUp);
	else
		upperLayer.on('mouseup.freeTextDraw', mouseUp);

	upperLayer.css({
		/* 'cursor': 'crosshair' */ //Commented for PR 994 
		'cursor': 'text' //PR 994
	});
	function mouseDown(e) {
		e.preventDefault();
		e = e || window.event;
		
		var pnt = getCordinatePoint(e);
		rect.startX = pnt.x;
		if (isIpad)
			rect.startY = pnt.y - scroolTopValue;
		else
			rect.startY = pnt.y;
		drag = true;
		$(this).removeClass('select-font-style');
	}
	function mouseUp() {
		drag = false;
		upperLayer.off('.freeTextDraw');
		upperLayer.off('.freeTextDraw');
		addFreeTextField();
		upperLayer.css({
			/* 'cursor': 'crosshair' */ //Commented for PR 994 
			'cursor': 'text' //PR 994
		});
	}
	
	function textAreaAdjustWidth(element) {
		 
		element.css('width','');

		if(element.val().length == 0) {
			element.css('width','0px');
			removeFreeTextFieldFromArray(element);
		}
	 	
		if (!element.attr('savecols')) {
			element.attr('savecols',0);
		}
		
		var maxLen = 0;
		var splittedText = element.val().split("\n"); 

		splittedText.forEach(single => {
			let len = single.length;
			if (len > maxLen){
				maxLen = len;
			} 
		});

		element.attr('cols', Math.max(maxLen, element.attr('savecols')));
		
	}

	function textAreaAdjustHeight(element) {
		
		element.css('height','');

		if(element.val().length == 0) {
			element.css('height', Math.round(fontSize * scale) + 5);
		}
	 	
		if (!element.attr('saverows')) {
			element.attr('saverows', 1);
		}

		var maxRows = element.val().split("\n").length; 

		element.attr('rows', Math.max(maxRows, element.attr('saverows')));
		
	}

	function addFreeTextField() {
		setTimeout(() => {
			$('.freeTextField').on('blur', blurHandler4FreeText);
		}, 200);
		$('.freeTextField').on('focus', function () {
			$(this).css({
				'border': 'rgb(255,0,0) solid 1px'
			});
		});
			
		currentScrollTop = 0;
		
		var pageNo = PDFView.page;
		currentScrollTop = $("#pageContainerMain" + (pageNo)).scrollTop();
		
		var x = rect.startX - marginLeft - popUpDivLeft - 36;
		
		var y = (parseInt(currentScrollTop) + parseInt(rect.startY)) - popUpDivTop - 61; 

		var h = Math.round(fontSize * scale) + 5;
		var w = Math.round(fontSize * scale);
		
		var new_offset = { top: 0, left: 0 }; 

		var textField = $('<textarea>').attr({
			'id': 'freeTF_' + count,
			'class': 'freeTextField',
			'spellcheck': 'true',
			'cols': '1',
			'rows': '1'
		}).css({
			
			'left': x,
			'top': y,
			//'border': '#000000 solid 1px', //Commented for PR 994
			'border': 'transparent solid 1px', //PR 994
			'padding':3, 
			'height': h, 
			'width': w,
			'line-height': 1
		}).draggable({
				cancel: "text",
				start: function () {
					$('#textarea').focus();
				},
				stop: function () {
					$('#textarea').focus();
				}
			}).resizable()
			.css({
				'position': 'absolute',
				//'border-color': 'black', //Commented for PR 994
				//PR 994 - START
				'border-color': 'transparent',
				//PR 994 - END
				'border-width': '1px',
				'border-style': 'solid'
			})
			.offset(new_offset)
			.appendTo('body');
		$('.pdfpage').droppable({
			drop: function (event, ui) {
				dropHandler4FreeText(ui.draggable[0]);
			}
		});
		
		setFontProperties(textField);
		$('#pageContainer' + PDFView.page).children('.textLayer').children(
			'.pdfpage').append(textField);
		$('.freeTextDiv').html('').css({
			'display': 'none',
			'border': 'transparent solid 2px'
		});
		count++;
		textField.focus();
		$('#freeText').removeClass('select-font-style');
		currentFreeText = textField;
		upperLayer.css('z-index', '250');

		$('.freeTextField').on('keyup', function (e) {
			if (e.keyCode === 13) {
				textAreaAdjustHeight($(this));
			} else if (e.keyCode === 8 || e.keyCode === 46) {
				textAreaAdjustHeight($(this));
				textAreaAdjustWidth($(this));
			} else {
				textAreaAdjustWidth($(this), false);
			}
		});

		$('.freeTextField').on('click', function (e) {
			if ($(this).val() != null && $(this).val().trim() != '' && $(this).val().trim() != undefined) {
				$('.freeTextField').each(function(i, obj) {
					if($(this).val() == null || $(this).val() == '') {
						$(this).remove();
						$(this).css({
							'border': 'transparent solid 1px'
						});
					}
				});
			}
		});
		textField = null;
	}
}
function saveAsFlatPdf() {
	flatPdf = true;
	submitPdf('pdfStamps', 'requestFor', 'savePdf', 'toolbarForm','passwordToolbar');
}

function checkForNearestCoordinateOfStamp(blotterX, blotterY){

	$('.stampDiv').each(function(i, obj) {

		var divXPosition = parseInt(obj.style.left);
		var divYPosition = parseInt(obj.style.top);
		var divWidth = $(obj).width();
		var divHeight = $(obj).height();
		var divXAreaCovered = parseInt(divXPosition) + parseInt(divWidth);
		var divYAreaCovered = parseInt(divYPosition) + parseInt(divHeight);
		/* alert(blotterX + " - " + blotterY);
		alert(divXPosition + " - " + divXAreaCovered);
		alert(divYPosition + " - " + divYAreaCovered) */
		if((blotterX >= divXPosition && blotterX <= divXAreaCovered) &&
			(blotterY >= divYPosition && blotterY <= divYAreaCovered)) {
				restrictBlotterOnStamp = true;
				return;
		} else {
			restrictBlotterOnStamp = false;
		}
	});
}
//PR 994 - END

// Resize the signature Start

function resizableListener() {
  
	$("#stampDiv_" + count).on({
	  resize: function (event) {      
		 for (var i = 0; i < stampsOnCanvas.length; i++) {   
		  if(stampsOnCanvas[i].id == event.currentTarget.id){      
			stampsOnCanvas[i].height = parseInt(event.currentTarget.clientHeight -2 );
			stampsOnCanvas[i].width = parseInt(event.currentTarget.clientWidth -2 );
			generateFinalString();
		  }
		}
	  }    
	});
  
	$("#stampDiv_" + count).on({
	  drag: function (event) {
		for (var i = 0; i < stampsOnCanvas.length; i++) {            
		  if(stampsOnCanvas[i].id == event.currentTarget.id){
			
			stampsOnCanvas[i].xCord = parseInt(event.currentTarget.offsetLeft);
			stampsOnCanvas[i].yCord = parseInt(event.currentTarget.offsetTop);
		  }       
		}
	  }    
	});
	
  }
  
  // Resize the signature End