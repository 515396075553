var CommonstandardFonts = new Array('Helvetica','Lucida�Grande','Lucida�Sans','Tahoma','Verdana','Geneva','Monaco',
		'Microsoft�Sans�Serif','Times�New�Roman','Trebuchet�MS','Courier�New','Courier','Lucida�Bright',
		'Lucida�Sans�Typewriter','Comic�Sans�MS','Lucida�Sans�Unicode','Palatino�Linotype',
		'Times','Lucida�Console','Franklin�Gothic�Medium','Helvetica�Neue','Impact','URW�Chancery�L','Arial','Century�Schoolbook�L',
		'URW�Gothic�L','Nimbus�Sans�L','URW�Bookman�L','URW�Palladio�L','Times�New�Roman','Nimbus�Mono�L',
		'Nimbus�Roman�No9�L','Sylfaen','MV�Boli','Trebuchet�MS','DejaVu�Sans','DejaVu�Sans�Mono','DejaVu�Serif','Tunga','Estrangelo�Edessa','Gautami','Mangal','Raavi','Latha',
		'Shruti','Gill�Sans','Arial�Narrow','Arial�Black','Courier�New','Arial�Rounded�MT�Bold','Andale�Mono','Futura','DejaVu�Sans�Light','Marker�Felt','Baskerville','Skia',
		'Comic�Sans�MS','American�Typewriter','Apple�Chancery','Optima','Didot','Hoefler�Text','Zapfino','Big�Caslon','DejaVu�Sans�Condensed','DejaVu�Serif�Condensed','Copperplate','Kartika','Palatino Linotype','Trebuchet MS',
		'Vrinda','Brush�Script�MT','Herculanum','Hiragino�Kaku�Gothic�ProN','Hiragino�Mincho�ProN','Bitstream�Charter','Liberation�Serif','Liberation�Mono','Calibri','Liberation�Sans','Candara',
		'Cambria�Math','Corbel','Consolas','Constantia','Hiragino�Maru�Gothic�ProN','Hiragino�Kaku�Gothic�StdN','Palatino','Century�Gothic',	'FreeSans','Book�Antiqua','Bookman�Old�Style',
		'FreeMono','FreeSerif','Courier�10�Pitch','Segoe�UI','Kokonor','Kailasa','Monotype�Corsiva','MS�Mincho','MS�Reference�Sans�Serif','Lucida�Sans�Typewriter','Hiragino�Sans�GB','PCMyungjo','PilGi',
		'Heiti�SC','Heiti�TC','HeadLineA','Menlo','Meiryo�UI','Arial�Unicode�MS','Tempus�Sans�ITC','Kristen�ITC','Chalkduster','Mistral','Juice�ITC','Malgun�Gothic','Microsoft�Himalaya','Microsoft�JhengHei','Microsoft�YaHei',
		'MingLiU-ExtB','MingLiU_HKSCS','MingLiU_HKSCS-ExtB','PMingLiU-ExtB','SimSun-ExtB','GungSeo','Freestyle�Script','Papyrus','Microsoft�Uighur','MoolBoran','Gisha','Leelawadee','DFKai-SB','Jokerman','French�Script�MT','Bradley�Hand�ITC','KaiTi',
		'FangSong','Vivaldi','UnDotum','Georgia','Times�New�Roman','Courier�New','Trebuchet�MS','Batang','Lohit�Bengali','Lohit�Tamil','Comic�Sans�MS','Lucida�Handwriting','Lohit�Gujarati','Waree','Haettenschweiler',
		'SimSun','Lohit�Punjabi','Pristina','Franklin�Gothic�Book','Copperplate�Gothic�Light','Copperplate�Gothic�Bold','Maiandra�GD','Eras�Demi�ITC','Franklin�Gothic�Demi','OCR�A�Extended','Perpetua','Eras�Light�ITC','Felix�Titling',
		'Rockwell','Edwardian�Script�ITC','Franklin�Gothic�Medium�Cond','MS�PGothic','Rockwell�Extra�Bold','Engravers�MT','MS�Gothic','MS�Mincho','Andale�Mono','Franklin�Gothic�Heavy','Curlz�MT','Franklin�Gothic�Demi�Cond','Blackadder�ITC','MS�PMincho','Segoe�Script','Casual','Segoe�UI�Light',
		'PMingLiU','Segoe�Print','MS�Gothic','Segoe�UI�Semibold','Lucida�Calligraphy','Harrington','Iskoola�Pota','MS�UI�Gothic','Viner�Hand�ITC','Cooper�Black','Onyx','Mongolian�Baiti','GulimChe','Old�English�Text�MT','Kalinga','Baskerville�Old�Face','Bauhaus�93',
		'Britannic�Bold','MS�PGothic','Wide�Latin','Euphemia','Gulim','Microsoft�Yi�Baiti','Nyala','Algerian','BatangChe','Gungsuh','GungsuhChe','Snap�ITC','DaunPenh','Broadway','Dotum','DotumChe','Footlight�MT�Light','Matura�MT�Script�Capitals','UnBatang',
		'DokChampa','Kunstler�Script','MS�PMincho','MingLiU','Vladimir�Script','Arabic�Typesetting','Bernard�MT�Condensed','Harlow�Solid�Italic','Chiller','NSimSun','Informal�Roman','Stencil','ell�MT','Colonna�MT','Ravie','Gabriola','Berlin�Sans�FB','Centaur',
		'High�Tower�Text','Ubuntu','Californian�FB','Mukti�Narrow','Purisa','Niagara�Engraved','Niagara�Solid','Showcard�Gothic','Lohit�Hindi','Sawasdee','SimHei','Garuda','Loma','Norasi','Tlwg�Typist','TlwgTypewriter','Brush�Script�MT','Kinnari','TlwgMono','Umpush',
		'utkal','Bodoni�MT�Poster�Compressed','Century�Gothic','Mallige','Vemana2000','KacstOne','Berlin�Sans�FB�Demi','Phetsarath�OT','Tlwg�Typo','Magneto','Aparajita','Garamond','Rachana','Lucida�Fax','Book�Antiqua','Arial�Rounded�MT�Bold','Bookman�Old�Style',
		'Gill�Sans�MT','Century�Schoolbook','Monotype�Corsiva','Century�Schoolbook','Calisto�MT','Copperplate�Gothic�Bold','Goudy�Old�Style','Script�MT�Bold','Gill�Sans�MT�Condensed','Gill�Sans�Ultra�Bold','Goudy�Old�Style','Imprint�MT�Shadow','Braggadocio',
		'Gill�Sans�MT�Ext�Condensed�Bold','Palace�Script�MT','Eras�Bold�ITC','Eras�Medium�ITC','Goudy�Stout','News�Gothic�MT','Playbill','Rockwell�Condensed','Castellar','Forte','Agency�FB','Rage�Italic','Gill�Sans�Ultra�Bold�Condensed','Tw�Cen�MT','Colonna�MT','Tw�Cen�MT�Condensed',
		'Britannic�Bold','Calisto�MT','Desdemona','Wide�Latin','Gigi','Gloucester�MT�Extra�Condensed','Tw�Cen�MT�Condensed�Extra�Bold','Footlight�MT�Light','Matura�MT�Script�Capitals','Perpetua�Titling�MT','Abadi�MT�Condensed�Light','Adobe�Ming�Std',
		'Adobe�Myungjo�Std','Kino�MT','Adobe�Song�Std','Bodoni�MT','Bodoni�MT�Black','Bodoni�MT�Condensed','Adobe�Fangsong�Std','Adobe�Heiti�Std','Adobe�Kaiti�Std','MS�Reference�Sans�Serif','Ubuntu�Light','Webdings','Wingdings','Times New Roman','Charcoal','Courier',
		'Gadget','Lucida Grande','New York','Zapf Dingbats');

/*var WindowsStandard = new Array( 'Times New Roman','Agency FB','Calibri','Cambria','------------------------------------------','Agency FB', 'Agency FB Bold', 'Algerian', 'Arial', 'Arial Black', 'Arial Bold', 'Arial Bold Italic', 'Arial Italic', 'Arial Narrow', 'Arial Narrow Bold', 'Arial Narrow Bold Italic', 
		'Arial Narrow Italic', 'Arial Rounded MT Bold', 'Arial Unicode MS', 'Baskerville Old Face', 'Bauhaus 93', 'Bell MT', 'Bell MT Bold', 'Bell MT Italic', 'Berlin Sans FB', 'Berlin Sans FB Bold', 'Berlin Sans FB Demi Bold', 'Bernard MT Condensed', 'Bitstream Vera Sans Mono', 'Bitstream Vera Sans Mono Bold', 
		'Bitstream Vera Sans Mono Bold Oblique', 'Bitstream Vera Sans Mono Oblique', 'Blackadder ITC', 'Bodoni MT', 'Bodoni MT Black', 'Bodoni MT Black Italic', 'Bodoni MT Bold', 'Bodoni MT Bold Italic', 'Bodoni MT Condensed', 'Bodoni MT Condensed Bold', 'Bodoni MT Condensed Bold Italic', 'Bodoni MT Condensed Italic', 
		'Bodoni MT Italic', 'Bodoni MT Poster Compressed', 'Book Antiqua', 'Book Antiqua Bold', 'Book Antiqua Bold Italic', 'Book Antiqua Italic', 'Bookman Old Style', 'Bookman Old Style Bold', 'Bookman Old Style Bold Italic', 'Bookman Old Style Italic', 'Bookshelf Symbol 7', 'Bradley Hand ITC', 
		'Britannic Bold', 'Broadway', 'Brush Script MT Italic', 'Calibri', 'Calibri Bold', 'Calibri Bold Italic', 'Calibri Italic', 'Californian FB', 'Californian FB Bold', 'Californian FB Italic', 'Calisto MT', 'Calisto MT Bold', 'Calisto MT Bold Italic', 'Calisto MT Italic', 'Cambria', 'Cambria Bold', 'Cambria Bold Italic', 
		'Cambria Italic', 'Cambria Math', 'Candara', 'Candara Bold', 'Candara Bold Italic', 'Candara Italic', 'Castellar', 'Centaur', 'Century', 'Century Gothic', 'Century Gothic Bold', 'Century Gothic Bold Italic', 'Century Gothic Italic', 'Century Schoolbook', 'Century Schoolbook Bold', 'Century Schoolbook Bold Italic', 'Century Schoolbook Italic', 
		'Chiller', 'Colonna MT', 'Comic Sans MS', 'Comic Sans MS Bold', 'Consolas', 'Consolas Bold', 'Consolas Bold Italic', 'Consolas Italic', 'Constantia', 'Constantia Bold', 'Constantia Bold Italic', 'Constantia Italic', 'Cooper Black', 'Copperplate Gothic Bold', 'Copperplate Gothic Light', 'Corbel', 'Corbel Bold', 'Corbel Bold Italic', 
		'Corbel Italic', 'Courier New', 'Courier New Bold', 'Courier New Bold Italic', 'Courier New Italic', 'Curlz MT', 'Dialog.bold', 'Dialog.bolditalic', 'Dialog.italic', 'Dialog.plain', 'DialogInput.bold', 'DialogInput.bolditalic', 'DialogInput.italic', 'DialogInput.plain', 'Edwardian Script ITC', 'Elephant', 'Elephant Italic', 'Engravers MT', 
		'Eras Bold ITC', 'Eras Demi ITC', 'Eras Light ITC', 'Eras Medium ITC', 'Estrangelo Edessa', 'Felix Titling', 'Footlight MT Light', 'Forte', 'Franklin Gothic Book', 'Franklin Gothic Book Italic', 'Franklin Gothic Demi', 'Franklin Gothic Demi Cond', 'Franklin Gothic Demi Italic', 'Franklin Gothic Heavy', 'Franklin Gothic Heavy Italic', 'Franklin Gothic Medium', 'Franklin Gothic Medium Cond', 'Franklin Gothic Medium Italic', 'Freestyle Script', 'French Script MT', 'Gabriola', 'Garamond', 
		'Garamond Bold', 'Garamond Italic', 'Gautami', 'Georgia', 'Georgia Bold', 'Georgia Bold Italic', 'Georgia Italic', 'Gigi', 'Gill Sans MT', 'Gill Sans MT Bold', 'Gill Sans MT Bold Italic', 'Gill Sans MT Condensed', 'Gill Sans MT Ext Condensed Bold', 'Gill Sans MT Italic', 'Gill Sans Ultra Bold', 'Gill Sans Ultra Bold Condensed', 'Gloucester MT Extra Condensed', 'Goudy Old Style', 'Goudy Old Style Bold', 'Goudy Old Style Italic', 'Goudy Stout', 'Haettenschweiler', 'Harlow Solid Italic', 
		'Harrington', 'High Tower Text', 'High Tower Text Italic', 'Impact', 'Imprint MT Shadow', 'Informal Roman', 'Jokerman', 'Juice ITC', 'Kristen ITC', 'Kunstler Script', 'Latha', 'Lucida Bright', 'Lucida Bright Demibold', 'Lucida Bright Demibold Italic', 'Lucida Bright Italic', 'Lucida Calligraphy Italic', 'Lucida Console', 'Lucida Fax Demibold', 'Lucida Fax Demibold Italic', 
		'Lucida Fax Italic', 'Lucida Fax Regular', 'Lucida Handwriting Italic', 'Lucida Sans Regular', 'Lucida Sans Demibold Roman', 'Lucida Sans Italic', 'Lucida Sans Regular', 'Lucida Sans Typewriter Bold', 'Lucida Sans Typewriter Bold Oblique', 'Lucida Sans Typewriter Oblique', 'Lucida Sans Typewriter Regular', 'Lucida Sans Unicode', 
		'MS Outlook', 'MS Reference Sans Serif', 'MS Reference Specialty', 'MT Extra', 'MV Boli', 'Magneto Bold', 'Maiandra GD', 'Mangal', 'Marlett', 'Matura MT Script Capitals', 'Meiryo', 'Meiryo Bold', 'Meiryo Bold Italic', 'Meiryo Italic', 'Meiryo UI', 'Meiryo UI Bold', 'Meiryo UI Bold Italic', 'Meiryo UI Italic', 'Microsoft Sans Serif', 'Mistral', 'Modern No. 20', 'Monospaced.bold', 
		'Monospaced.bolditalic', 'Monospaced.italic', 'Monospaced.plain', 'Monotype Corsiva', 'Niagara Engraved', 'Niagara Solid', 'Nina', 'Nina Bold', 'OCR A Extended', 'Old English Text MT', 'Onyx', 'Palace Script MT', 'Palatino Linotype', 'Palatino Linotype Bold', 'Palatino Linotype Bold Italic', 'Palatino Linotype Italic', 'Papyrus', 'Parchment', 'Perpetua', 'Perpetua Bold', 'Perpetua Bold Italic', 
		'Perpetua Italic', 'Perpetua Titling MT Bold', 'Perpetua Titling MT Light', 'Playbill', 'Poor Richard', 'Pristina', 'Raavi', 'Rage Italic', 'Ravie', 'Reveal Line Draw', 'Rockwell', 'Rockwell Bold', 'Rockwell Bold Italic', 'Rockwell Condensed', 'Rockwell Condensed Bold', 'Rockwell Extra Bold', 'Rockwell Italic', 'SansSerif.bold', 'SansSerif.bolditalic', 
		'SansSerif.italic', 'SansSerif.plain', 'Script MT Bold', 'Segoe Condensed', 'Segoe Condensed Bold', 'Segoe UI', 'Segoe UI Bold', 'Segoe UI Bold Italic', 'Segoe UI Italic', 'Serif.bold', 'Serif.bolditalic', 'Serif.italic', 'Serif.plain', 'Showcard Gothic', 'Shruti', 'Snap ITC', 'Stencil', 'Sylfaen', 'Symbol', 'Tahoma', 'Tahoma Bold', 'Tempus Sans ITC', 'Times New Roman', 'Times New Roman Bold', 
		'Times New Roman Bold Italic', 'Times New Roman Italic', 'Trebuchet MS', 'Trebuchet MS Bold', 'Trebuchet MS Bold Italic', 'Trebuchet MS Italic', 'Tunga', 'Tw Cen MT', 'Tw Cen MT Bold', 'Tw Cen MT Bold Italic', 'Tw Cen MT Condensed', 'Tw Cen MT Condensed Bold', 'Tw Cen MT Condensed Extra Bold', 'Tw Cen MT Italic', 'Verdana', 'Verdana Bold', 'Verdana Bold Italic', 'Verdana Italic', 
		'Viner Hand ITC', 'Vivaldi Italic', 'Vladimir Script', 'Webdings', 'Wide Latin', 'Wingdings', 'Wingdings 2', 'Wingdings 3');*/ //Commented By CPNVP 07/27/2015 PR 485

var WindowsStandard = new Array('Times New Roman', 'Courier', 'Helvetica' ); //CPNVP 07/27/2015 PR 485 - END

var Detector = function() {
    // a font will be compared against all the three default fonts.
    // and if it doesn't match all 3 then that font is not available.
    var baseFonts = ['monospace', 'sans-serif', 'serif'];

    //we use m or w because these two characters take up the maximum width.
    // And we use a LLi so that the same matching fonts can get separated
    var testString = "mmmmmmmmmmlli";

    //we test using 72px font size, we may use any size. I guess larger the better.
    var testSize = '72px';

    var h = document.getElementsByTagName("body")[0];

    // create a SPAN in the document to get the width of the text we use to test
    var s = document.createElement("span");
    s.style.fontSize = testSize;
    s.innerHTML = testString;
    var defaultWidth = {};
    var defaultHeight = {};
    for (var index in baseFonts) {
        //get the default width for the three base fonts
        s.style.fontFamily = baseFonts[index];
        h.appendChild(s);
        defaultWidth[baseFonts[index]] = s.offsetWidth; //width for the default font
        defaultHeight[baseFonts[index]] = s.offsetHeight; //height for the defualt font
        h.removeChild(s);
    }

    function detect(font) {
        var detected = false;
        for (var index in baseFonts) {
            s.style.fontFamily = font + ',' + baseFonts[index]; // name of the font along with the base font for fallback.
            h.appendChild(s);
            var matched = (s.offsetWidth != defaultWidth[baseFonts[index]] || s.offsetHeight != defaultHeight[baseFonts[index]]);
            h.removeChild(s);
            detected = detected || matched;
        }
        return detected;
    }

    this.detect = detect;
};