//Object for sending stamp data	server Side
	//var Stamp = function(stampName, pageNo, xCord, yCord, scale, isAdded, src, height, width, parameters, stampScale){
var Stamp = function(stampName, pageNo, xCord, yCord, scale, isAdded,src, height, width, parameters, stampScale,imageName, imageBytes){
			this.stampName = stampName;	
			this.pageNo = pageNo;
			this.xCord = (xCord)/scale;//For getting xCord at 100% 
			this.yCord = (yCord)/scale;
			
			this.scale = scale;
			this.isAdded = isAdded;
			this.src= src;
			this.imageName = imageName;
			this.imageBytes = imageBytes;
			this.height = (height/(stampScale*scale));//at 100%
			this.width = (width/(stampScale*scale));//at 100%
			this.parameters = parameters;
			this.stampScale =stampScale;
	};
//Objects For Getting Data From Server
	//var StampInfo = function(stampName, stampToolTip, stampCaption, stampIcon, stampImage, stampSeqNo, isApproved, stampParameters,stampImgName){	 //Commented By CPNVP 07/27/2015 PR 485
	//CPNVP 07/27/2015 PR 485 - START
	var StampInfo = function(imageId, imageData, imageIconData, stampName, stampToolTip, stampCaption, stampIcon, stampImage, stampSeqNo, isApproved, stampParameters,stampImgName){
		this.imageId = imageId;
		this.imageData = imageData; 
		this.imageIconData = imageIconData;
		//CPNVP 07/27/2015 PR 485 - END
		this.stampName = stampName;
		this.stampToolTip = stampToolTip;
		this.stampCaption = stampCaption;
		this.stampIconPath = stampIcon;
		this.stampImagePath = stampImage;
		this.stampSeqNo = stampSeqNo ;
		this.isApproved  = isApproved;
		this.stampParameters = stampParameters;
		this.stampImgName = stampImgName;
	};
//Object For Stamp Parameter 
	var ParamInfo = function(stampName, paramName, paramValue, paramX, paramY, paramWidth, paramHeight, paramDataType, paramColor,
				paramFontSize, paramFontStyle, paramFontType, isReadOnly, isLabelRequired, paramLabel, paramType, paramSeqNo, isRequired, showToUser, stampScale){
		this.stampName = stampName;
		this.paramName = paramName;
		this.paramValue = paramValue;
		this.paramX = paramX;
		this.paramY = paramY;
		this.paramWidth = paramWidth;
		this.paramHeight = paramHeight;
		this.paramDataType = paramDataType;
		this.paramColor = paramColor;
		this.paramFontSize = paramFontSize;
		this.paramFontStyle = paramFontStyle;
		this.paramFontType = paramFontType;
		this.isReadOnly = isReadOnly;
		this.isLabelRequired = isLabelRequired;
		this.paramLabel = paramLabel;
		this.paramType = paramType;
		this.paramSeqNo = paramSeqNo;
		this.isRequired = isRequired; 
		this.showToUser = showToUser; 
		this.stampScale = stampScale;
	};

   var FreeText = function(id, text, top, left, height, width, isAdded, page, scale, parameter){
	    this.id=id,
	    this.text = text,
	    this.x = left/scale,
	    this.y = top/scale,
	    this.height = height/scale,//at 100%
	    this.width = width/scale,
	    this.isAdded = isAdded,
	    this.page = page,
	    this.scale = scale,
	    this.parameter = parameter
   };
   
	//var SignInfo = function(imageId, imageData){ //Commented By CPNVP 07/27/2015 PR 485
   	var SignInfo = function(imageId, imageData, imageIconData){ // CPNVP 07/27/2015 PR 485
		this.imageId = imageId;
		this.imageData = imageData;
		this.imageIconData = imageIconData; //CPNVP 07/27/2015 PR 485 - END
	};
