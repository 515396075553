/* -*- Mode: Java; tab-width: 2; indent-tabs-mode: nil; c-basic-offset: 2 -*- */
/* vim: set shiftwidth=2 tabstop=2 autoindent cindent expandtab: */

//
// Basic AcroForms input controls rendering
//

'use strict';

var formFields = {};
var formFieldArray = new Array();
//var FormField = (function FormFieldClosure(){
function FormField(fieldName, fieldValue, fieldType) {
	this.fieldName = fieldName;
	this.fieldValue = fieldValue;
	this.fieldType = fieldType;

}
/*FormField.prototype= {
		
};*/
//})();

function addField2Array(fieldName, fieldValue, fieldType) {
	
	if (fieldValue == undefined || fieldValue == null) {
		fieldValue = '';
	}

	var field = new FormField(fieldName, fieldValue, fieldType);
	if (formFieldArray.indexOf(field) == -1) {
		formFieldArray.push(field);
	} else {
		//alert(" ");
	}

}

function setupForm(div, content, viewport) {
	function bindInputItem(input, item) {
	
		if (input.name in formFields) {
			var value = formFields[input.name];
			if (input.type == 'checkbox')
				input.checked = value;
			else if (!input.type || input.type == 'text')
				input.value = value;
		}
		input.onchange = function pageViewSetupInputOnBlur() {
			if (input.type == 'checkbox')
				formFields[input.name] = input.checked;
			else if (!input.type || input.type == 'text')
				formFields[input.name] = input.value;
		};
	}
	//CPNVP - START
	var Util = PDFJS.Util = (function UtilClosure() {
		function Util() { }

		var rgbBuf = ['rgb(', 0, ',', 0, ',', 0, ')'];

		// makeCssRgb() can be called thousands of times. Using |rgbBuf| avoids
		// creating many intermediate strings.
		Util.makeCssRgb = function Util_makeCssRgb(rgb) {
			rgbBuf[1] = rgb[0];
			rgbBuf[3] = rgb[1];
			rgbBuf[5] = rgb[2];
			return rgbBuf.join('');
		};

		// Concatenates two transformation matrices together and returns the result.
		Util.transform = function Util_transform(m1, m2) {
			return [
				m1[0] * m2[0] + m1[2] * m2[1],
				m1[1] * m2[0] + m1[3] * m2[1],
				m1[0] * m2[2] + m1[2] * m2[3],
				m1[1] * m2[2] + m1[3] * m2[3],
				m1[0] * m2[4] + m1[2] * m2[5] + m1[4],
				m1[1] * m2[4] + m1[3] * m2[5] + m1[5]
			];
		};

		// For 2d affine transforms
		Util.applyTransform = function Util_applyTransform(p, m) {
			var xt = p[0] * m[0] + p[1] * m[2] + m[4];
			var yt = p[0] * m[1] + p[1] * m[3] + m[5];
			return [xt, yt];
		};

		Util.applyInverseTransform = function Util_applyInverseTransform(p, m) {
			var d = m[0] * m[3] - m[1] * m[2];
			var xt = (p[0] * m[3] - p[1] * m[2] + m[2] * m[5] - m[4] * m[3]) / d;
			var yt = (-p[0] * m[1] + p[1] * m[0] + m[4] * m[1] - m[5] * m[0]) / d;
			return [xt, yt];
		};

		// Applies the transform to the rectangle and finds the minimum axially
		// aligned bounding box.
		Util.getAxialAlignedBoundingBox =
			function Util_getAxialAlignedBoundingBox(r, m) {

				var p1 = Util.applyTransform(r, m);
				var p2 = Util.applyTransform(r.slice(2, 4), m);
				var p3 = Util.applyTransform([r[0], r[3]], m);
				var p4 = Util.applyTransform([r[2], r[1]], m);
				return [
					Math.min(p1[0], p2[0], p3[0], p4[0]),
					Math.min(p1[1], p2[1], p3[1], p4[1]),
					Math.max(p1[0], p2[0], p3[0], p4[0]),
					Math.max(p1[1], p2[1], p3[1], p4[1])
				];
			};

		Util.inverseTransform = function Util_inverseTransform(m) {
			var d = m[0] * m[3] - m[1] * m[2];
			return [m[3] / d, -m[1] / d, -m[2] / d, m[0] / d,
			(m[2] * m[5] - m[4] * m[3]) / d, (m[4] * m[1] - m[5] * m[0]) / d];
		};

		// Apply a generic 3d matrix M on a 3-vector v:
		//   | a b c |   | X |
		//   | d e f | x | Y |
		//   | g h i |   | Z |
		// M is assumed to be serialized as [a,b,c,d,e,f,g,h,i],
		// with v as [X,Y,Z]
		Util.apply3dTransform = function Util_apply3dTransform(m, v) {
			return [
				m[0] * v[0] + m[1] * v[1] + m[2] * v[2],
				m[3] * v[0] + m[4] * v[1] + m[5] * v[2],
				m[6] * v[0] + m[7] * v[1] + m[8] * v[2]
			];
		};

		// This calculation uses Singular Value Decomposition.
		// The SVD can be represented with formula A = USV. We are interested in the
		// matrix S here because it represents the scale values.
		Util.singularValueDecompose2dScale =
			function Util_singularValueDecompose2dScale(m) {

				var transpose = [m[0], m[2], m[1], m[3]];

				// Multiply matrix m with its transpose.
				var a = m[0] * transpose[0] + m[1] * transpose[2];
				var b = m[0] * transpose[1] + m[1] * transpose[3];
				var c = m[2] * transpose[0] + m[3] * transpose[2];
				var d = m[2] * transpose[1] + m[3] * transpose[3];

				// Solve the second degree polynomial to get roots.
				var first = (a + d) / 2;
				var second = Math.sqrt((a + d) * (a + d) - 4 * (a * d - c * b)) / 2;
				var sx = first + second || 1;
				var sy = first - second || 1;

				// Scale values are the square roots of the eigenvalues.
				return [Math.sqrt(sx), Math.sqrt(sy)];
			};

		// Normalize rectangle rect=[x1, y1, x2, y2] so that (x1,y1) < (x2,y2)
		// For coordinate systems whose origin lies in the bottom-left, this
		// means normalization to (BL,TR) ordering. For systems with origin in the
		// top-left, this means (TL,BR) ordering.
		Util.normalizeRect = function Util_normalizeRect(rect) {
			var r = rect.slice(0); // clone rect
			if (rect[0] > rect[2]) {
				r[0] = rect[2];
				r[2] = rect[0];
			}
			if (rect[1] > rect[3]) {
				r[1] = rect[3];
				r[3] = rect[1];
			}
			return r;
		};

		// Returns a rectangle [x1, y1, x2, y2] corresponding to the
		// intersection of rect1 and rect2. If no intersection, returns 'false'
		// The rectangle coordinates of rect1, rect2 should be [x1, y1, x2, y2]
		Util.intersect = function Util_intersect(rect1, rect2) {
			function compare(a, b) {
				return a - b;
			}

			// Order points along the axes
			var orderedX = [rect1[0], rect1[2], rect2[0], rect2[2]].sort(compare),
				orderedY = [rect1[1], rect1[3], rect2[1], rect2[3]].sort(compare),
				result = [];

			rect1 = Util.normalizeRect(rect1);
			rect2 = Util.normalizeRect(rect2);

			// X: first and second points belong to different rectangles?
			if ((orderedX[0] === rect1[0] && orderedX[1] === rect2[0]) ||
				(orderedX[0] === rect2[0] && orderedX[1] === rect1[0])) {
				// Intersection must be between second and third points
				result[0] = orderedX[1];
				result[2] = orderedX[2];
			} else {
				return false;
			}

			// Y: first and second points belong to different rectangles?
			if ((orderedY[0] === rect1[1] && orderedY[1] === rect2[1]) ||
				(orderedY[0] === rect2[1] && orderedY[1] === rect1[1])) {
				// Intersection must be between second and third points
				result[1] = orderedY[1];
				result[3] = orderedY[2];
			} else {
				return false;
			}

			return result;
		};

		Util.sign = function Util_sign(num) {
			return num < 0 ? -1 : 1;
		};

		Util.appendToArray = function Util_appendToArray(arr1, arr2) {
			Array.prototype.push.apply(arr1, arr2);
		};

		Util.prependToArray = function Util_prependToArray(arr1, arr2) {
			Array.prototype.unshift.apply(arr1, arr2);
		};

		Util.extendObj = function extendObj(obj1, obj2) {
			for (var key in obj2) {
				obj1[key] = obj2[key];
			}
		};

		Util.getInheritableProperty = function Util_getInheritableProperty(dict,
			name) {
			while (dict && !dict.has(name)) {
				dict = dict.get('Parent');
			}
			if (!dict) {
				return null;
			}
			return dict.get(name);
		};

		Util.inherit = function Util_inherit(sub, base, prototype) {
			sub.prototype = Object.create(base.prototype);
			sub.prototype.constructor = sub;
			for (var prop in prototype) {
				sub.prototype[prop] = prototype[prop];
			}
		};

		Util.loadScript = function Util_loadScript(src, callback) {
			var script = document.createElement('script');
			var loaded = false;
			script.setAttribute('src', src);
			if (callback) {
				script.onload = function () {
					if (!loaded) {
						callback();
					}
					loaded = true;
				};
			}
			document.getElementsByTagName('head')[0].appendChild(script);
		};

		return Util;
	})();

	//CPNVP - END

	function createElementWithStyle(tagName, item) {
		var element = document.createElement(tagName);
		var rect = Util.normalizeRect(
			viewport.convertToViewportRectangle(item.rect));
		/* for(var key in item){
				 console.log("Key:::::::" + key  + ":: Value::" + item[key]);
			}*/
		element.style.left = Math.floor(rect[0]) + 'px';
		element.style.top = Math.floor(rect[1]) + 'px';
		element.style.width = Math.ceil(rect[2] - rect[0]) + 'px';
		element.style.height = Math.ceil(rect[3] - rect[1]) + 'px';
		//Modified By CPNPV - START

		if (item.radioButton) {
			if (item.buttonValue != undefined && item.buttonValue != null && item.buttonValue != '') {
				element.value = item.buttonValue;
			}

		} else {
			if (item.fieldValue != undefined && item.fieldValue != null && item.fieldValue != '') {
				element.value = item.fieldValue;
			}
			else
				element.value = '';
			
		}
		
		//Modified By CPNPV - END



		//Added By CPNPV - START	
		if (item.fieldMask != undefined && item.fieldMask != null && item.fieldMask != '') {
			if (item.fieldMask == '1') { //If Field is Readonly
				//element.setAttribute('disabled', true); //Commented for PR 994
				element.setAttribute('disabled', false); //PR 994
				element.style.backgroundColor = '#F1F1F1';
				element.style.color = '#464646';
				$('.inputHint').css({ 'background-color': '#F1F1F1' });
				isFlat = true;
			}
		}
		//Added By CPNPV - END

		return element;
	}
	//PR 994 -START
	function createElementWithStyleForDropDown(tagName, item) {
		var element = document.createElement(tagName);
		var rect = Util.normalizeRect(
			viewport.convertToViewportRectangle(item.rect));
		
		element.style.left = Math.floor(rect[0]) + 'px';
		element.style.top = Math.floor(rect[1]) + 'px';
		element.style.width = Math.ceil(rect[2] - rect[0]) + 'px';
		element.style.height = Math.ceil(rect[3] - rect[1]) + 'px';
		
		if (item.fieldValue != undefined && item.fieldValue != null) {
		
			let array = item.fieldValue;
			element.value = array[0];
			
		}
		else
			element.value = '';
		



			
		if (item.fieldMask != undefined && item.fieldMask != null && item.fieldMask != '') {
			if (item.fieldMask == '1') { 
				
				element.setAttribute('disabled', false); 
				element.style.backgroundColor = '#F1F1F1';
				element.style.color = '#464646';
				$('.inputHint').css({ 'background-color': '#F1F1F1' });
				isFlat = true;
			}
		}
			
					

			
			var array = item.options;
	
			for (var i = 0; i < array.length; i++) {
				var option = document.createElement("option");
				option.value = array[i].exportValue;
				option.text = array[i].displayValue;
				if(array[i].exportValue==item.fieldValue[0])
				{
					option.selected = array[i].exportValue;
				}
				element.appendChild(option);
			}
		

		return element;
	}
	//PR 994 - END



	function assignFontStyle(element, item) {
		var fontStyles = ''
		//console.log('item.fontSize::::'+item.fontSize);
		if (item.fontSize == null || item.fontSize == undefined) { //Added By CPNVP
			item.fontSize = 12;
		}
		if ('fontSize' in item) {
			fontStyles += 'font-size: ' + Math.round(item.fontSize *
				viewport.fontScale) + 'px;';
		}
		switch (item.textAlignment) {
			case 0:
				fontStyles += 'text-align: left;';
				break;
			case 1:
				fontStyles += 'text-align: center;';
				break;
			case 2:
				fontStyles += 'text-align: right;';
				break;
		}
		element.setAttribute('style', element.getAttribute('style') + fontStyles);
	}

	content.getAnnotations().then(function (items) {
		for (var i = 0; i < items.length; i++) {
			var item = items[i];
		
			switch (item.subtype) {
				case 'Widget':

					if (item.fieldType != 'Tx' && item.fieldType != 'Btn' &&
						item.fieldType != 'Ch') {
						//break; //Commented by CPNVP
					}
					var inputDiv = createElementWithStyle('div', item);
					inputDiv.className = 'inputHint';
					div.appendChild(inputDiv);
					var input;
					if (item.fieldType == 'Tx') {
						input = createElementWithStyle('input', item);
					}
					if (item.fieldType == 'Btn') {
						input = createElementWithStyle('input', item);
						if (item.radioButton) {
							input.type = 'radio';
							
							try {
								
								if (item.fieldValue == item.buttonValue) {
									input.checked = true;
								}
							} catch (err) {
								console.log(err.message);
							}
							
						} else if (item.fieldFlags & 65536) {
							input.type = 'button';
							// pushbutton is not supported
						} else {
							input.type = 'checkbox';
							//Added By CPNPV - START
							
							// Commented for PR 994 - Start
							//try {
							//	if (item.content == '1') {
							//		input.checked = true;
							//	}
							//	if (item.content == 'true') {
							//		input.checked = true;
							//	}
							//	//if (item.content.toLowerCase() == 'on') { //Commented for PR 994
							//	if (item.content != null && item.content != 'undefined' &&
							//		item.content.toLowerCase() == 'on') { //PR 994
							//		input.checked = true;
							//	}
							//}
							// Commented for PR 994 - end
							//PR 994 -START
							try {
								if (item.fieldValue == '1') {
									input.checked = true;
								}
								if (item.fieldValue == 'true') {
									input.checked = true;
								}
								
								if (item.fieldValue != null && item.fieldValue != 'undefined' &&
								item.fieldValue.toLowerCase() == 'yes') { 
									input.checked = true;
								}
							}
							//PR 994 - END

							catch (err) {
								console.log(err.message);
							}
							//ADDED BY CPNVP - END
						}
					}
					if (item.fieldType == 'Ch') {
						
						input = createElementWithStyleForDropDown('select', item);
						
					}
					if (input != null && input != undefined) {
						
						input.className = 'inputControl';
						//input.disabled="disabled" ;//CPNVP 08/10/2016 PR 8210 //PR 994
						
						input.name = item.fieldName;
						input.title = item.alternativeText;
						input.spellcheck = "true";
						input.style.paddingLeft = '5px';
						assignFontStyle(input, item);
						bindInputItem(input, item);
						div.appendChild(input);

						//addField2Array(input.name, item.content, item.fieldType); //Commented for PR 994 
						addField2Array(input.name, item.fieldValue, item.fieldType);

					}

					break;


				case 'Text':
					var inputDiv = createElementWithStyle('div', item);
					inputDiv.className = 'inputHint';
					div.appendChild(inputDiv);
					var input;
					input = createElementWithStyle('input', item);
					input.className = 'inputControl';
					input.name = item.fullName;
					input.title = item.alternativeText;
					//input.value = item.fullName;
					input.spellcheck = "true";
					assignFontStyle(input, item);
					bindInputItem(input, item);
					div.appendChild(input);
					addField2Array(input.name, item.fieldValue, 'Text');
					break;


			}
		}
	});
}



function renderPage(div, pdf, pageNumber, callback) {
	pdf.getPage(pageNumber).then(function (page) {
		var scale = 1.5;
		var viewport = page.getViewport(scale);

		var pageDisplayWidth = viewport.width;
		var pageDisplayHeight = viewport.height;

		var pageDivHolder = document.createElement('div');
		pageDivHolder.className = 'pdfpage';
		pageDivHolder.style.width = pageDisplayWidth + 'px';
		pageDivHolder.style.height = pageDisplayHeight + 'px';
		div.appendChild(pageDivHolder);

		// Prepare canvas using PDF page dimensions
		var canvas = document.createElement('canvas');
		var context = canvas.getContext('2d');
		canvas.width = pageDisplayWidth;
		canvas.height = pageDisplayHeight;
		pageDivHolder.appendChild(canvas);


		// Render PDF page into canvas context
		var renderContext = {
			canvasContext: context,
			viewport: viewport
		};
		page.render(renderContext).then(callback);

		// Prepare and populate form elements layer
		var formDiv = document.createElement('div');
		pageDivHolder.appendChild(formDiv);

		setupForm(formDiv, page, viewport);
	});
}
/*
// Fetch the PDF document from the URL using promices
PDFJS.getDocument(pdfWithFormsPath).then(function getPdfForm(pdf) {
  // Rendering all pages starting from first
  var viewer = document.getElementById('viewer');
  var pageNumber = 1;
  renderPage(viewer, pdf, pageNumber++, function pageRenderingComplete() {
    if (pageNumber > pdf.numPages)
      return; // All pages rendered
    // Continue rendering of the next page
    renderPage(viewer, pdf, pageNumber++, pageRenderingComplete);
  });
});*/

