var kwd = '';
var dialog = this;
/*CPNVP 08/13/2015 PR 485 - START*/
var contextPath = '';
if (document.getElementById("contextPath") != null && document.getElementById("contextPath").value != null) {
	contextPath = document.getElementById("contextPath").value;
}
/*CPNVP 08/13/2015 PR 485 - END*/

function generateDialog(paramArray, buttonArray, header) {
	var mainForm = $('<form>').attr({
		'id': 'dialog-mainForm',
		'action': 'util',
		'method': 'post'
	});
	$('#mainParamContainer').children('h1').prepend(header);
	for (var i = 0; i < paramArray.length; i++) {
		var paramObj = paramArray[i];
		var paramContainer = $('<div>').addClass('param');
		var required = $('<span>').html('*').css({
			'color': 'rgb(255,0,0)'
		});
		if (paramObj.isRequired.toLowerCase() == 'n') {
			required = '';
		}
		var paramName = $('<span>').addClass('paramName').attr('id', 'inputName' + i).html(paramObj.paramName.toUpperCase()).append(required).append(':');
		if (paramObj.paramValue == null || paramObj.paramValue == undefined) {
			paramObj.paramValue = '';
		}
		paramContainer.append(paramName);

		console.log('paramObj.value in generate Dialog ' + paramObj.value);

		var paramValue = null;
		if (paramObj.inputType == 'textarea') {
			paramValue = $('<textarea>').addClass('paramValue').attr({
				'id': 'input' + i,
				'name': 'input' + i,
				'value': paramObj.paramValue,
				'rows': paramObj.rowsIfTextArea,
				'spellcheck': 'true'
			});
			paramContainer.append(paramValue);
		} else {
			var fileToAttach = new DialogParam('File To Attach', '', 'N', 'file', 0, 'Y');
			if ((typeof (paramObj.paramValue)).toString() == 'object') {
				console.log('Inside Else Loop of attach dialog');
				console.log('paramObj.inputType --------------  >>' + paramObj.inputType);
				for (var k = 0; k < paramObj.paramValue.length; k++) {
					paramValue = $('<input>').addClass('paramValue').attr({
						'type': paramObj.inputType,
						'id': 'input' + i + '_' + k,
						'value': paramObj.paramValue[k],
						'name': paramObj.paramName,
						'onchange': 'javascript:handleAttachment(this);'
					});
					paramContainer.append(paramValue).append(paramObj.paramValue[k].toUpperCase());
				}
			} else {
				console.log('else else else');
				paramValue = $('<input>').addClass('paramValue').attr({
					'type': paramObj.inputType,
					'name': 'input' + i,
					'id': 'input' + i,
					'value': paramObj.paramValue,
					'spellcheck': 'true'
				});
				if (paramObj.isDisabled.toLowerCase() == 'y') {
					paramValue.attr({
						'disabled': 'disabled'
					});
					paramValue.css('color', '#000000');
					if (paramObj.paramName.toLowerCase() == 'transactionid') {
						paramValue.attr('value', transactionId);
					}
				} else {
					paramValue.attr({
						'value': ''
					});
				}
				paramContainer.append(paramValue);
			}
		}
		mainForm.append(paramContainer);
	}

	var buttonDiv = $('<div>').attr({
		'class': 'paramButton'
	});
	if (buttonArray != null) {
		for (var m = 0; m < buttonArray.length; m++) {
			var buttonObj = buttonArray[m];
			if (buttonObj.paramName.toLowerCase() == 'ok') {
				typeOfButton = 'submit';
			} else {
				typeOfButton = 'button';
			}
			var button = $('<input>').addClass('param-button').attr({
				'id': buttonObj.paramName,
				'type': 'button',
				'value': buttonObj.paramName.toUpperCase(),
				'onclick': buttonObj.paramValue
			});
			if (buttonObj.isDisabled.toLowerCase() == 'y') {
				button.attr({
					'disabled': 'disabled'
				});
			}
			buttonDiv.append(button);
		}
		mainForm.append(buttonDiv);
	}
	$('#mainParamContainer').append(mainForm);
}

var DialogParam = function (paramName, paramValue, isDisabled, inputType, rowsIfTextArea, isRequired) {
	this.paramName = paramName;
	this.paramValue = paramValue;//Value =  Object / Array || if(button) then onclick event
	this.isDisabled = isDisabled;//Value= 'Y'/'N'
	this.inputType = inputType;
	this.rowsIfTextArea = rowsIfTextArea; // Value '0' If not
	this.isRequired = isRequired; // Value = 'Y'/'N'
};

function generateAttachmentDialog() {
	console.log('------- generateAttachmentDialog -------- 1-------');
	if (isDialogShowing) {
		showCloseDialogMessage();
		return;
	}
	console.log('------- generateAttachmentDialog --------2-------');
	var paramArray = new Array();
	var description = new DialogParam('Description', '', 'N', 'text', 0, 'Y');
	var radio = new Array();
	radio.push('text');
	radio.push('file');
	console.log('------- generateAttachmentDialog -------- 31-------');
	var attachmentType = new DialogParam('Attachment Type', radio, 'N', 'radio', 0, 'Y');
	var textToAttach = new DialogParam('Text To Attach', '', 'N', 'textarea', 5, 'Y');
	var fileToAttach = new DialogParam('File To Attach', '', 'N', 'file', 0, 'Y');
	paramArray.push(description);
	paramArray.push(attachmentType);
	paramArray.push(textToAttach);
	paramArray.push(fileToAttach);
	console.log('------- generateAttachmentDialog -------- 4-------');
	var buttonArray = new Array();
	var okButton = new DialogParam('Ok', 'javascript:isValidateAttachment()', 'N', 'button', 0, 'Y');
	var cancelButton = new DialogParam('Cancel', 'javascript: cancel(event);', 'N', 'button', 0, 'Y');
	buttonArray.push(okButton);
	buttonArray.push(cancelButton);
	generateDialog(paramArray, buttonArray, 'Attachment');
	console.log('------- generateAttachmentDialog -------- 6-------');
	showDialog();
}
function handleAttachment(element) {
	if (element.value.toLowerCase() == 'text') {
		$('#input3').attr({
			'disabled': 'disabled'
		});
		$('#input2').removeAttr('disabled');
	}
	if (element.value.toLowerCase() == 'file') {
		$('#input2').val('');
		$('#input2').attr({
			'disabled': 'disabled'
		});
		$('#input3').removeAttr('disabled');
	}
}

function generateColorChooserDialog() {
	var container = $('#mainParamContainer');
	$('#colorChooserDiv').css('display', 'block');
	container.width('auto');
	container.children('h1').prepend('Color Chooser');
	var wrapperDiv4Table = $('<div>').attr({ 'id': 'wrapper' });
	var colorTable = $('#colorChooserDiv').children('table');
	wrapperDiv4Table.append(colorTable.clone());
	var wrapperdiv4fieldSet = $('<div>').attr({ 'id': 'wrapper4fieldSet' });
	wrapperdiv4fieldSet.append($('#colorChooserDiv').children('fieldset').clone());
	container.append(wrapperDiv4Table);
	container.append(wrapperdiv4fieldSet);

	var okButton = $('<input>').attr({
		'type': 'button',
		'id': 'colorOk',
		'value': 'Ok',
		'class': 'param-button',
		'onClick': 'colorOkClick(event)'
	});
	var cancelButton = $('<input>').attr({
		'type': 'button',
		'id': 'colorCancel',
		'value': 'Cancel',
		'class': 'param-button',
		'onclick': 'cancel();'
	});
	var buttonDiv = $('<div>').attr({
		'class': 'paramButton',
	}).css({
		'padding-left': '380px'
	});
	buttonDiv.append(okButton);
	buttonDiv.append(cancelButton);
	container.append(buttonDiv);
	$('#colorChooserDiv').css('display', 'none');
	$('#mainParamContainer').css({
		'margin-top': '-' + container.height() / 2 + 'px',
		'margin-left': '-' + container.width() / 2 + 'px',
	});
	showDialog();
}
function savePassword(url, scale, ele) {
	kwd = $('#passwordField').val();
	var password = kwd;
	PDFView.open(url, scale, password);
	cancel();
	$('#securePdf').die('click');
}

//================================================================================
//						Password Dialog -Start
//================================================================================

function generatePasswordDialog(url, scale) {
	var mainContainer = $('#mainParamContainer');
	var passwordLabel = $('<label>').addClass('labelpassword').attr({
		'id': 'passwordLabel',
		'name': 'passwordLabel'
	});
	passwordLabel.html('Please Enter Password :');
	var passwordValue = $('<input>').addClass('password').attr({
		'type': 'password',
		'id': 'passwordField',
		'spellcheck': 'true'
	});

	passwordValue.css({
		'color': '#000000',
		'margin': '10px 0 10px 0'
	});
	var paramContainer = $('<div>');
	paramContainer.append(passwordLabel);
	paramContainer.append(passwordValue);
	mainContainer.append(paramContainer);
	$('#mainParamContainer').children('h1').prepend('Enter Password');
	var buttonDiv = $('<div>').attr({
		'class': 'paramButton',
	});
	mainContainer.append('<br/><br/>');
	var save = $('<input>').addClass('param-button').attr({
		'id': 'passwordOk',
		'type': 'button',
		'value': 'OK',
		'onclick': 'javascript:savePassword(\'' + url + '\',' + scale + ',this);'
	});
	buttonDiv.append(save);
	var cancel = $('<input>').addClass('param-button').attr({
		'id': 'passwordCancel',
		'type': 'button',
		'value': 'CANCEL',
		'onclick': 'javascript: cancel();'
	});
	buttonDiv.append(cancel);
	mainContainer.append(buttonDiv);
	showDialog();

}


//================================================================================
//							Signature Dialog - Start
//================================================================================
//250 x 150 canvas image is converted To 140 x 60 image and attached  


function showSignaturePad() {
	var container = $('#mainParamContainer');
	var signCanvas = document.createElement('canvas');
	var context = signCanvas.getContext("2d");
	var isDraw = false;
	var lineParam = {};
	var marginLeft = ($(window).width() * 0.25) + 100;//marginLeft For dialog box+ marginLeft for canvas
	var marginTopCanvas = ($(window).height() * 0.15) + 40 + 20;//marginTop For dialog box+ margin top for canvas
	var ipadScrollValue = $('body').scrollTop();

	function init() {
		context.lineJoin = "round";
		context.lineWidth = 10;
		context.strokeStyle = "#000000";
		signCanvas.width = 250;
		signCanvas.height = 150;
		$(signCanvas).addClass('signCanvas');
		signCanvas.id = 'signCanvas';

		$(signCanvas).on('mousedown', startDraw);
		if (isIpad) {
			$(signCanvas).on('touchstart', startDraw);
		}
		context.fillStyle = 'rgba(0, 0, 0, 1)';
		//Set Signature Dialog 
		container.children('h1').prepend('Signature');
		var clearDiv = $('<div>');
		var clearLink = $('<a>').attr({
			'onclick': 'javascript: clearCanvas();',
			'class': 'clear-canvas'
		}).html('[Clear]');

		clearDiv.append(clearLink);
		container.append(clearDiv);
		var canvasDiv = document.createElement('div');
		canvasDiv.setAttribute('id', 'canvasDiv');
		canvasDiv.appendChild(signCanvas);
		var btnSave = $('<input>').attr({
			'id': 'saveSignature', 'type': 'button',
			'class': 'param-button', 'value': 'Save',
			'onclick': 'javascript : saveSignature(event);'
		});
		var btnCancel = $('<input>').attr({
			'id': 'cancelSignature', 'type': 'button',
			'class': 'param-button', 'value': 'Cancel',
			'onclick': 'javascript : cancelSignature(event);'
		});
		var btnDiv = $('<div>').attr({
			'class': 'paramButton',
		});
		btnDiv.append(btnSave);
		btnDiv.append(btnCancel);

		container.append(canvasDiv);
		container.append(btnDiv);
		showDialog();
	}

	//onMouseDown , onTouchStart
	var startDraw = function (e) {
		$(signCanvas).on('mousemove', drawOnCanvas);
		$(signCanvas).on('mouseup', stopDraw);
		if (isIpad) {
			$(signCanvas).on('touchmove', drawOnCanvas);
			$(signCanvas).on('touchend', stopDraw);
			e.preventDefault();
		}
		var pnt = getCordinatePoint(e);
		if (isIpad) {
			pnt.y = pnt.y - ipadScrollValue;
		}
		lineParam.x = pnt.x;
		lineParam.y = pnt.y;
		context.beginPath();
		context.arc(lineParam.x - marginLeft, lineParam.y - marginTopCanvas, 0.75, 0, 2 * Math.PI, false);
		context.stroke();
		context.closePath();
		isDraw = true;
	};

	//onMouseUp, onTouchEnd
	var stopDraw = function (e) {
		$(signCanvas).off('mouseup');
		$(signCanvas).off('mousemove');
		if (isIpad) {
			$(signCanvas).off('touchmove', drawOnCanvas);
			$(signCanvas).off('touchend', stopDraw);
			e.preventDefault();
		}
		isDraw = false;
	};

	//onMouseMove, onTouchMove
	var drawOnCanvas = function (e) {
		if (isDraw) {
			context.beginPath();
			context.moveTo(lineParam.x - marginLeft, lineParam.y - marginTopCanvas);
			var pnt = getCordinatePoint(e);
			if (isIpad) {
				pnt.y = pnt.y - ipadScrollValue;
			}
			lineParam.x = pnt.x;
			lineParam.y = pnt.y;
			if (isIpad) {
				e.preventDefault();
			}
			context.lineTo(lineParam.x - marginLeft, lineParam.y - marginTopCanvas);
			context.stroke();
			context.closePath();
		}
	};
	init();
}
function cancelSignature(e) {
	cancel(e);
}

function saveSignature(event) {

	var canvas = document.getElementById('signCanvas');

	var imgData = canvas.toDataURL('image/png');

	addSignatureWithStyle(signatureY - marginTop, signatureX - marginLeft, imgData, PDFView.page, Number(signatureHeight * scale * stampScale), Number(signatureWidth * scale * stampScale));
	addSignatureToArray((signatureY - marginTop), (signatureX - marginLeft), imgData);
	cancel(event);
}

function addSignatureWithStyle(top, left, src, pageNo, height, width) {
	var img = $('<img>');
	img.attr({
		'id': 'sign_' + count,
		'src': src,
		'class': 'signature-image-added'
	});
	img.css({
		'left': left + 'px',
		'top': top + 'px',
		'height': height + 'px',
		'width': width + 'px'
	});
	$('#pageContainer' + pageNo).children('.textLayer').children('.pdfpage').append(img);
}

function clearCanvas() {
	var w = $('#signCanvas').width();
	var h = $('#signCanvas').height();

	var canvas = document.getElementById('signCanvas');
	var context = canvas.getContext('2d');
	context.clearRect(0, 0, w, h);
}

function generateSignatureDialog() {
	if (document.getElementById('mainParamContainer').style.display == 'block') {
		alert('Please close already opened dialog.');
	} else {
		viewerUtility.showProgressBar();
		Signature.initialize();
	}
}

function generateSignDialogWithSessionId() {
	viewerUtility.hideProgressBar();
	var form = $('<form>').attr({
		'method': 'post',
		'id': 'imageForm',
		'name': 'imageForm',
		'action': 'CallerServlet',
	});

	if (isDialogShowing) {
		showCloseDialogMessage();
		return;
	}
	var mainContainerFoSignature = $('#mainParamContainer');
	var stamInfoArray = viewerUtility.stampsInfoarray;

	var closedialogImg = document.getElementById('closeDialogBox');
	if (closedialogImg != null) {
		closedialogImg.style.display = 'none';
	}
	mainContainerFoSignature.children('h1').prepend('Manage Signature' + '<a id= "closeDialogBoxForSign" onClick="cancelDialogForSign();" style="color:white" title="Close" class="toolbarButton paramButtonImage">'); /*CPNVP 08/13/2015 PR 485*/
	mainContainerFoSignature.width('650px');
	var mainDiv = $('<div>').attr({
		'class': 'mainDiv',
		'id': 'mainDiv',
	});

	/*CPNVP 03/23/2015 PR 617( For indication of online signature upload message - START*/

	var div = $('<div>').addClass('divClass').attr({
		'id': 'div'
	});

	var ul = $('<ul>').addClass('messageDisplayFormat').attr({
		'id': 'ul'
	});

	var li = $('<li>').addClass('fatalMessage').attr({
		'id': 'li'
	});
	li.html('Your signature is now added in available signature list');
	ul.append(li);
	div.append(ul);
	form.append(div);

	/*CPNVP 03/23/2015 PR 617 - END*/
	for (var i = 0; i < stamInfoArray.length; i++) {
		var imageId = stamInfoArray[i].imageId;

		var imgDiv = $('<div>').attr({
			'class': 'imgDiv',
			'id': ('IInkIt_' + stampsInfoarray[i].imageId + '_div').replace('%20', '_').replace(' ', '_'),
		});
		var img = $('<img>').attr({
			'id': ('IInkIt_' + stampsInfoarray[i].imageId + '_icon').replace('%20', '_').replace(' ', '_'),
			'src': stampsInfoarray[i].imageData.replace('%20', '_').replace(' ', '_'),
			'width': 55,
			'height': 55
		}).css({
			'display': 'block',
			'background-color': '#ffffff'
		});

		var closeImg = $('<img>').attr({
			'id': ('IInkIt_' + stampsInfoarray[i].imageId + '_icon').replace('%20', '_').replace(' ', '_'),
			'src': contextPath + '/webiinkit/images/red-cross-icon.png',
			'onclick': 'javascript : closeImage(\'' + imageId + '\' , \'' + imgDiv + '\');',
		}).css({
			'display': 'block',
			'float': 'right',
			'position': 'relative',
			'top': '-60px',
			'right': '-10px',
			'height': '15px',
		});

		imgDiv.append(img);
		imgDiv.append(closeImg);
		mainDiv.append(imgDiv);
	}

	form.append(mainDiv);

	var imageDesc = $('<label>').addClass('textForimg').attr({
		'id': 'imageDescId',
		'name': 'imageDescName'
	});
	/*imageDesc.html('If you have touchscreen device (android phone, iPhone, iPad or tablet) please open http://test.mysignature.net  or scan the QR barcode below using your touch screen device and supply signature.This signature is for one time user only. We will not store this signature in our system.');*/ //Commented By CPNVP 03/23/2015 PR 617
	imageDesc.html('If you have touchscreen device (android phone, iPhone, iPad or tablet) please open https://www.mysignature.net/  or scan the QR barcode below using your touch screen device and supply signature.Please press Save to use your signature one time only and we will not save it for future use. If you prefer to save your signature for future use then press "Save For Future".'); /*//CPNVP 03/23/2015 PR 617*/
	form.append(imageDesc);
	var sessionIdVal = document.getElementById('sessionID').value;
	if (sessionIdVal != '') {

		var sessionDiv = $('<div>').attr({
			'class': 'sessionDiv',
		});

		var sessionId = $('<label>').addClass('labelimageDesc').attr({
			'name': 'sessionIdName'
		}).css({
			'color': '#424343',
		});

		console.log('sessionId is ------------->>' + document.getElementById('sessionID'));
		var id = document.getElementById('sessionID').value;
		console.log('sessionId in dialog.js ====== >>>' + id);
		sessionId.html('Session Id :' + id);

		sessionDiv.append(sessionId);
		form.append(sessionDiv);

		var barcodeImage = document.getElementById('qrCodeImg');

		form.append(sign2);

		var barcodeImg = $('<img>').attr({
			'id': 'imageId',
			'src': barcodeImage.src,
			'alt': 'Loading...'
		}).css({
			'display': 'block',
			'width': '80px',
			'height': '80px',
			'margin-left': '15px',
		});

		form.append(barcodeImg);
	}

	var browseButtonDesc = $('<label>').addClass('browseButtonText').attr({
		'id': 'browseButtonDescID',
		'name': 'browseButtonDescName',
		'margin-left': '15px',
	});

	if (sessionIdVal != '') {
		browseButtonDesc.html('As an alternative, ');
	}

	browseButtonDesc.html('if you have image stored in your computer, you can directly upload through below button');

	form.append(browseButtonDesc);

	var fileDiv = '';
	if (sessionIdVal != '') {
		fileDiv = $('<div>').attr({
			'class': 'fileDiv',
		});
	} else {
		fileDiv = $('<div>').attr({
			'class': 'fileDivFor',
		});
	}
	var file = $('<input>').addClass('paramValue').attr({
		'type': 'file',
		'id': 'signatureFile',
		'name': 'signatureFile',
		'spellcheck': 'true',
		'onClick': 'signatureFileClicked()',
		'onChange': 'signatureFileChange()'
	}).css({
		'margin-left': '10px',
		'margin-top': '10px',
		'height': '30px',
	});

	fileDiv.append(file);
	form.append(fileDiv);

	var btnSaveForFuture = $('<input>').attr({
		'id': 'saveForFutureSignature', 'type': 'button',
		'class': 'button-manage-signature', 'value': 'Save For Future',
		'onclick': 'javascript:saveImage(this, true);'
	});
	var btnSave = $('<input>').attr({
		'id': 'cancelSignature', 'type': 'button',
		'class': 'param-button', 'value': 'Save',
		'onclick': 'javascript:saveImage(this, false);'
	});
	var btnDiv = $('<div>').attr({
		'class': 'paramButtonFoSign',
	});

	if (localStorage.getItem('accessToken')) {
		btnDiv.append(btnSaveForFuture);
	}
	btnDiv.append(btnSave);
	form.append(btnDiv);

	var request = $('<input>').attr({
		'id': 'requestFor',
		'class': 'requestFor',
		'type': 'hidden',
		'name': 'requestFor',
		'value': 'addSignature'
	});

	form.append(request);

	var uniqueID = document.getElementById('uniqueId').value;
	var uniqueId = $('<input>').attr({
		'id': 'uniqueId',
		'class': 'requestFor',
		'type': 'hidden',
		'name': 'uniqueId',
		'value': uniqueID
	});

	form.append(uniqueId);

	var getSignByte = document.getElementById('sign2');
	if (getSignByte != null) {
	}
	var sign2 = $('<input>').attr({
		'id': 'sign2',
		'class': 'requestFor',
		'type': 'hidden',
		'name': 'sign2',
		'value': getSignByte.value,
	}).css({
		'display': 'block',
		'width': '80px',
		'height': '80px',
		'margin-left': '15px',
	});

	form.append(sign2);

	mainContainerFoSignature.append(form);
	$('#mainParamContainer').css({
		'margin-top': '-' + mainContainerFoSignature.height() / 2 + 'px',
		'margin-left': '-' + mainContainerFoSignature.width() / 2 + 'px',
	});
	showDialog();
}
/*Commented By CPNVP 09/21/2015 PR 485 - START*/
/*CPNVP 08/13/2015 PR 485 - START */
/*var uniqueIdForDiv;
var urlForDiv;
var urlString;
	
function openPopUpInDiv(url , uniqueId , fileSizeExceeded , subString){
		var mainContainer = $('#popUpDivContainer');
		
		urlForDiv = subString;
		urlString = url;
		
		
		var bodyScrollTop = $(window).scrollTop();
		if(bodyScrollTop > 0){
			$(window).scrollTop('0px');
		}
		
		uniqueIdForDiv = uniqueId;
		var uniqueId = $('<input>').attr({
			'id':'uniqueIdFromURL',
			'class':'requestFor',
			'type':'hidden',
			'name':'uniqueId',
			'value' : uniqueIdForDiv
		});
		 
		mainContainer.append(uniqueId);
		mainContainer.css({
			'width' : '620px'
		});
		var popupHeight = $(window).height() - 50;
		var popupwidth = $(window).width() - 100;
		var left =  ( $(window).width() - popupwidth) / 2;
		var top =  ( $(window).height() - popupHeight) / 2 - 10; 
		$('#popUpDivContainer').css({
			'display' : 'none',
			'background-color': 'white',
			'left': left,
			'position': 'absolute',
			'top': top,
			'width': popupwidth,
			'height':popupHeight,
			'z-index':'1000'
		});
		$('.param-overlay').css({
			'display' : 'block'
		});
			
		console.log('document.readyState in dialog.js'+document.readyState);
		mainContainer.load(urlString);
}*/
/*CPNVP 08/13/2015 PR 485 - END */
/*Commented By CPNVP 09/21/2015 PR 485 - END*/
/*CPNVP 09/21/2015 PR 485 - START*/
var uniqueIdForDiv;
var urlForDiv;
var urlString;
var currentPageNumberForDiv;
//function initalizeIInkIt(url , uniqueId , fileSizeExceeded , subString, currentPageNumberDiv){//Commented By CPNVP 08/10/2016 PR 8210
function initalizeIInkIt(url, uniqueId, fileSizeExceeded, subString, currentPageNumberDiv, isFullScreenModeSupportForDUCS) {//CPNVP 08/10/2016 PR 8210
	var mainContainer = $('#popUpDivContainer');

	urlForDiv = subString;
	urlString = url;
	currentPageNumberForDiv = currentPageNumberDiv;

	var bodyScrollTop = $(window).scrollTop();
	if (bodyScrollTop > 0) {
		$(window).scrollTop('0px');
	}

	uniqueIdForDiv = uniqueId;
	var uniqueId = $('<input>').attr({
		'id': 'uniqueIdFromURL',
		'class': 'requestFor',
		'type': 'hidden',
		'name': 'uniqueId',
		'value': uniqueIdForDiv
	});

	mainContainer.append(uniqueId);
	mainContainer.css({
		'width': '620px'
	});
	var popupHeight = $(window).height() - 50;
	var popupwidth = $(window).width() - 100;
	var left = ($(window).width() - popupwidth) / 2;
	var top = ($(window).height() - popupHeight) / 2 - 10;
	//CPNVP 08/10/2016 PR 8210 - START
	if (isFullScreenModeSupportForDUCS) {
		popupHeight = $(window).height() - 10;
		popupwidth = $(window).width() - 10;
		left = 41;
		top = 2;
	}
	//CPNVP 08/10/2016 PR 8210 - END
	$('#popUpDivContainer').css({
		'display': 'none',
		'background-color': 'white',
		'left': left,
		'position': 'absolute',
		'top': top,
		'width': popupwidth,
		'height': popupHeight,
		'z-index': '1000'
	});
	$('.param-overlay').css({
		'display': 'block'
	});

	console.log('document.readyState in dialog.js' + document.readyState);
	mainContainer.load(urlString);
}
function openPopUpInDiv(url, uniqueId, fileSizeExceeded, subString) {

	if (fileSizeExceeded == 'true') {
		//return  promptPageNumber(url , uniqueId , fileSizeExceeded , subString);//Commented By CPNVP 08/10/2016 PR 8210
		return promptPageNumber(url, uniqueId, fileSizeExceeded, subString, false);//CPNVP 08/10/2016 PR 8210
	}
	else {

		//initalizeIInkIt(url , uniqueId , fileSizeExceeded , subString ,1);//Commented By CPNVP 08/10/2016 PR 8210
		initalizeIInkIt(url, uniqueId, fileSizeExceeded, subString, 1, false);//CPNVP 08/10/2016 PR 8210
	}
}

/* function closePopupDiv(){
	viewerUtility.generateDialogOnClosePopup();
} */
//function promptPageNumber(url , uniqueId , fileSizeExceeded , subString){//Commented By CPNVP 08/10/2016 PR 8210
function promptPageNumber(url, uniqueId, fileSizeExceeded, subString, isFullWindow) {//CPNVP 08/10/2016 PR 8210
	var y;
	y = window.prompt("Please enter page number", "1");

	if (y != null && y != '' && y.length > 0 && y != 0) {
		currentPageNumber = y;
		//initalizeIInkIt(url , uniqueId , fileSizeExceeded , subString, currentPageNumber);//Commented By CPNVP 08/10/2016 PR 8210
		initalizeIInkIt(url, uniqueId, fileSizeExceeded, subString, currentPageNumber, isFullWindow);//CPNVP 08/10/2016 PR 8210
	}
	else if (y == null) {
		return false;
	}
	else {
		//promptPageNumber(url , uniqueId , fileSizeExceeded , subString); //Commented By CPNVP 08/10/2016 PR 8210 
		promptPageNumber(url, uniqueId, fileSizeExceeded, subString, isFullWindow); //CPNVP 08/10/2016 PR 8210 
	}
}

/*CPNVP 09/21/2015 PR 485 - END*/
/*CPNVP 08/10/2016 PR 8210 - START*/
function openWebIInkitForDucs(url, uniqueId, fileSizeExceeded, subString) {
	if (fileSizeExceeded == 'true') {
		//return  promptPageNumber(url , uniqueId , fileSizeExceeded , subString, false);//Commented By CPNVP 10/05/2016 PR 8210 
		return promptPageNumberForDucs(url, uniqueId, fileSizeExceeded, subString, false);//CPNVP 10/05/2016 PR 8210 
	}
	else {
		initalizeIInkIt(url, uniqueId, fileSizeExceeded, subString, 1, false, false);
	}
}
/*CPNVP 08/10/2016 PR 8210 - - END*/
/*CPNVP 10/05/2016 PR 8210 - START*/
function promptPageNumberForDucs(url, uniqueId, fileSizeExceeded, subString, isFullWindow) {
	var y;
	y = window.prompt("Please enter page number", "1");

	if (y != null && y != '' && y.length > 0 && y != 0) {
		currentPageNumber = y;
		initalizeIInkIt(url, uniqueId, fileSizeExceeded, subString, currentPageNumber, isFullWindow);
	}
	else if (y == null) {
		window.open('', '_self', '').close();
		return false;
	}
	else {
		promptPageNumber(url, uniqueId, fileSizeExceeded, subString, isFullWindow);
	}
}
	/*CPNVP 10/05/2016 PR 8210 - END*/
